import { Breakpoints, Checkbox, Icon, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import ClientSwitcherSelectors from "src/store/clientSwitcher/ClientSwitcherSelectors";
import FeatureFlagsActions from "src/store/featureFlags/FeatureFlagsActions";
import FeatureFlagsSelectors from "src/store/featureFlags/FeatureFlagsSelectors";
import UserSelectors from "src/store/user/UserSelectors";

import {
    ButtonStyled,
    DdStyled,
    DlRow,
    DlStyled,
    DropdownContentStyled,
    DropdownStyled,
    DtStyled,
    LogoStyled,
    StackWrapperStyled,
    TextStyled,
} from "./DevToolbar.styles";

const DD_MAX_LENGTH = 300;
const DD_LENGHT_WHEN_TOO_LONG = 120;
const LOCAL_STORAGE_KEY = "dev-toolbar-is-visible";

const DevToolbar = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
    const isDevToolbarEnabled = useFeatureFlag(featureFlags.devToolbar);
    const { info } = useSelector(UserSelectors.getUserInfo);
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const featureFlagsState = useSelector(FeatureFlagsSelectors.selectFeatureFlags);
    const isTablet = useHasMaxWidth(Breakpoints.S);
    const menuRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const isVisibleFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (isVisibleFromLocalStorage) {
            setIsVisible(JSON.parse(isVisibleFromLocalStorage));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(isVisible));
    }, [isVisible]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.altKey && event.shiftKey && event.code === "KeyD") {
                setIsVisible((prev) => !prev);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isVisible]);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
                setActiveDropdownId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const handleDropdownButtonClick = (dropdownId: string) => {
        setActiveDropdownId(activeDropdownId === dropdownId ? null : dropdownId);
    };

    const handleFeatureFlagChange = (flagName: string) => {
        dispatch(
            FeatureFlagsActions.setFeatureFlags({
                ...featureFlagsState,
                [flagName]: !featureFlagsState[flagName],
            }),
        );
    };

    const renderDropdown = (dropdownId: string, label: string, content: React.ReactNode) => (
        <div>
            <TextStyled>
                <ButtonStyled onClick={() => handleDropdownButtonClick(dropdownId)}>
                    <Stack gap="XXS" direction="row" alignItems="center">
                        {label}
                        <Icon variant={activeDropdownId === dropdownId ? "ArrowUp" : "ArrowDown"} />
                    </Stack>
                </ButtonStyled>
            </TextStyled>
            {activeDropdownId === dropdownId && <DropdownContentStyled>{content}</DropdownContentStyled>}
        </div>
    );

    const renderUserInfo = () => {
        return (
            <DlStyled>
                {Object.keys(info || {})
                    .filter((key) => key !== "preferences")
                    .map((key) => (
                        <DlRow key={key}>
                            <DtStyled>{key.charAt(0).toUpperCase() + key.slice(1)}:</DtStyled>
                            <DdStyled>
                                {typeof info[key] === "object" && info[key] !== null
                                    ? JSON.stringify(info[key], null, 2)
                                    : typeof info[key] === "boolean"
                                      ? info[key]
                                          ? "Yes"
                                          : "No"
                                      : info[key] || "-"}
                            </DdStyled>
                        </DlRow>
                    ))}
            </DlStyled>
        );
    };

    const renderFeatureFlags = () => {
        return (
            <DlStyled>
                {Object.keys(featureFlagsState).map((flagName) => {
                    const isActive = featureFlagsState[flagName];
                    return (
                        <DlRow key={flagName}>
                            <DtStyled>{flagName}</DtStyled>
                            <DdStyled>
                                <Checkbox id={flagName} checked={isActive} onChange={() => handleFeatureFlagChange(flagName)} />
                            </DdStyled>
                        </DlRow>
                    );
                })}
            </DlStyled>
        );
    };

    const renderUserPreferences = () => {
        return (
            <DlStyled width="580px">
                {Object.keys(info?.preferences || {}).map((key) => (
                    <DlRow key={key}>
                        <DtStyled>{key.charAt(0).toUpperCase() + key.slice(1)}:</DtStyled>
                        <DdStyled
                            title={
                                typeof info.preferences[key] === "string" && info.preferences[key].length > DD_MAX_LENGTH ? info.preferences[key] : undefined
                            }
                        >
                            {typeof info.preferences[key] === "object" && info.preferences[key] !== null
                                ? JSON.stringify(info.preferences[key], null, 2)
                                : typeof info.preferences[key] === "boolean"
                                  ? info.preferences[key]
                                      ? "Yes"
                                      : "No"
                                  : typeof info.preferences[key] === "string" && info.preferences[key].length > DD_MAX_LENGTH
                                    ? `${info.preferences[key].substring(0, DD_LENGHT_WHEN_TOO_LONG)}...`
                                    : info.preferences[key] || "-"}
                        </DdStyled>
                    </DlRow>
                ))}
            </DlStyled>
        );
    };

    const renderSelectedClientDetails = () => {
        return (
            <DlStyled>
                {Object.keys(selectedClient || {}).map((key) => {
                    return (
                        <DlRow key={key}>
                            <DtStyled>{key.charAt(0).toUpperCase() + key.slice(1)}:</DtStyled>
                            <DdStyled>
                                {typeof selectedClient[key] === "object" && selectedClient[key] !== null
                                    ? JSON.stringify(selectedClient[key], null, 2)
                                    : typeof selectedClient[key] === "boolean"
                                      ? selectedClient[key]
                                          ? "Yes"
                                          : "No"
                                      : selectedClient[key] || "-"}
                            </DdStyled>
                        </DlRow>
                    );
                })}
            </DlStyled>
        );
    };

    if (!isDevToolbarEnabled || !isVisible || isTablet) {
        return null;
    }

    return (
        <StackWrapperStyled>
            <Stack gap="L">
                <TextStyled>
                    <LogoStyled>●●●</LogoStyled> Dev Toolbar {/* OneGuarding DevMate Ultimate Toolbar Pro */}
                </TextStyled>
            </Stack>
            <Stack gap="L" ref={menuRef}>
                <Stack gap="M">
                    <Stack alignItems="center" gap="XS">
                        <Icon color="primary" variant="Hide" />
                        <TextStyled>Alt + Shift + D</TextStyled>
                    </Stack>
                    <DropdownStyled>{renderDropdown("user-info", "User info", renderUserInfo())}</DropdownStyled>
                    <DropdownStyled>{renderDropdown("user-preferences", "User preferences", renderUserPreferences())}</DropdownStyled>
                    {selectedClient && <DropdownStyled>{renderDropdown("selected-client", "Selected client", renderSelectedClientDetails())}</DropdownStyled>}
                    <DropdownStyled>{renderDropdown("feature-flags", "Feature flags", renderFeatureFlags())}</DropdownStyled>
                </Stack>
            </Stack>
        </StackWrapperStyled>
    );
};

export default DevToolbar;
