import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import { useHasMobileServices } from "src/store/siteObjects/SiteObjectsHooks";
import TrackingActions from "src/store/tracking/TrackingActions";

import InsightsActions from "../../../store/insights/InsightsActions";
import InsightsSelectors from "../../../store/insights/InsightsSelectors";
import { CALLOUTS_TAB, STATIC_INSIGHTS_TABS } from "./InsightsTabs.constants";
import { SubpageTab } from "./InsightsTabs.types";

export const useInsightsTabs = () => {
    const dispatch = useDispatch();
    const activeTab = useSelector(InsightsSelectors.getSelectedTab);
    const { hasMobileServices } = useHasMobileServices();
    const isCalloutsTabAvailable = useFeatureFlag(featureFlags.calloutsTab);
    const showCalloutsTab = hasMobileServices && isCalloutsTabAvailable;

    const tabs: SubpageTab[] = useMemo(() => {
        // NOTE: Here we can apply any dynamic rules for Insights tab
        // like for un upcoming "Callouts" tab,
        // controlled by access to mobile services

        return [...STATIC_INSIGHTS_TABS, ...(showCalloutsTab ? [CALLOUTS_TAB] : [])];
    }, [showCalloutsTab]);

    const setActiveTab = (tabIndex: number) => dispatch(InsightsActions.setSelectedTab(tabIndex));

    const handleTabView = (index: number, isFirstView = false) => {
        dispatch(
            TrackingActions.trackEvent(MixpanelEvent.InsightsTabView, {
                Page: window.location.pathname,
                "Page Title": "Insights",
                Tab: tabs[index]?.trackingName,
                "Previous Tab": isFirstView ? null : tabs[activeTab]?.trackingName,
            }),
        );
    };

    useEffect(() => {
        handleTabView(activeTab, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        activeTab,
        activeTabLabelKey: tabs?.[activeTab]?.labelKey,
        setActiveTab,
        tabs,
        handleTabView,
    };
};
