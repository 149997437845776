import { SpacingExtendedType } from "@secuis/ccp-react-components";
import { IReportImage } from "src/models/ReportImagesModels";

import { PdfImagesCarousel, PdfImagesSection } from "../../../PdfReports/shared/PdfImageSection";

export const getPdfImagesCarousel = (images: IReportImage[]) => ({
    component: PdfImagesCarousel,
    props: {
        images: images.filter((i) => i.reportFieldName?.startsWith("picture")),
    },
});

export const getPdfImagesSection = (label: string, images: IReportImage[], marginTop?: SpacingExtendedType) => ({
    component: PdfImagesSection,
    props: {
        label,
        images: images.filter((i) => i.reportFieldName.startsWith("picture")),
        marginTop,
    },
});
