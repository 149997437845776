import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DAMAGED_CATEGORIES } from "src/constants/damagedCategories";
import { formatToRawDate } from "src/helpers/date";
import { useFilterableReportTypes } from "src/hooks/ReportTypeHooks";
import { ReportTemplateEnum } from "src/models/ReportModel";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

export const useDamageReportsRedirection = () => {
    const navigate = useNavigate();
    const { defaultInsightsReportTypes } = useFilterableReportTypes();
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const categoriesLevel3 = DAMAGED_CATEGORIES.map((category) => category.categoriesLevel3).flat();

    const baseRedirectionUrl = useMemo(() => {
        const reportTypes = [...defaultInsightsReportTypes, ReportTemplateEnum.callout, ReportTemplateEnum.vehicleInspection];
        return `/reports?selectedStartDate=${formatToRawDate(selectedStartDate)}&selectedEndDate=${formatToRawDate(
            selectedEndDate,
        )}&selectedReportTypes=${reportTypes.toString()}`;
    }, [selectedStartDate, selectedEndDate, defaultInsightsReportTypes]);

    const redirectToAllDamagedReports = useCallback(() => {
        navigate(`${baseRedirectionUrl}&selectedCategoryThree=${categoriesLevel3}&selectedRegions=${selectedRegions.toString()}`);
    }, [selectedRegions, navigate, categoriesLevel3, baseRedirectionUrl]);

    const redirectToLocationDamageReports = useCallback(
        (siteId: string) => {
            navigate(`${baseRedirectionUrl}&selectedCategoryThree=${categoriesLevel3.toString()}&selectedRegions=${siteId}`);
        },
        [navigate, categoriesLevel3, baseRedirectionUrl],
    );

    const redirectToCategory2Location = useCallback(
        (siteId: string, categoryLevel2: string[]) => {
            const categoriesForLevel2 = getCategoriesLevel3(categoryLevel2).toString();
            navigate(`${baseRedirectionUrl}&selectedCategoryThree=${categoriesForLevel2}&selectedRegions=${siteId}`);
        },
        [navigate, baseRedirectionUrl],
    );

    return { redirectToAllDamagedReports, redirectToLocationDamageReports, redirectToCategory2Location };
};

const getCategoriesLevel3 = (categories2: string[]) =>
    DAMAGED_CATEGORIES.filter((category) => categories2.includes(category.categoryLevel2))
        .map((category) => category.categoriesLevel3)
        .flat();
