import { MOBILE_GUARDING_TAG } from "../../constants";

export enum UserType {
    Global = "Global",
    SingleSite = "SingleSite",
    MultiSite = "MultiSite",
    Internal = "Internal",
}

export const TrackedSiteTagsLowerCase = [
    "gc-centrallymanaged",
    "gc-operational",
    "globalclients",
    "mysecuritas",
    "onsite",
    "live",
    MOBILE_GUARDING_TAG.toLowerCase(),
];
