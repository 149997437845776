import { RequestStatus } from "../RequestStatus";
import { ICommentRecordState, ICommentState } from "./types";

export const DEFAULT_COMMENTS_ENTRY_STATE: ICommentRecordState = {
    comments: [],
    createState: { status: RequestStatus.undefined },
    fetchStatus: RequestStatus.undefined,
};

export const DEFAULT_COMMENTS_STATE: ICommentState = {
    byReportKey: {},
};
