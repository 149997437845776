import { Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { getSeverityColor } from "src/components/Reports/SharedComponents/Badges";
import { getSeverityLevelTranslationKey } from "src/helpers/SeverityLevelHelper";
import { SeverityLevel as SeverityLevelType } from "src/store/organization/types";

import { SeverityIcon } from "../SeverityIcon";

type Props = {
    severityLevel: SeverityLevelType;
};

export const SeverityLevel = ({ severityLevel }: Props) => {
    const { t } = useTranslation();

    return (
        <Stack gap="XS" alignItems="center">
            <SeverityIcon severityLevel={severityLevel} />
            <Text bold color={getSeverityColor(severityLevel)}>
                {t(getSeverityLevelTranslationKey(severityLevel))}
            </Text>
        </Stack>
    );
};
