import { Breakpoints, Headline, LineChart, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { Switcher } from "../../../../components/shared/Switcher";
import { QaProps, StyleProps } from "../../../../models";
import { Widget } from "../shared/components";
import { DEVIANT_DATA_TYPE_OPTIONS } from "../shared/constants";
import { DeviantHourTooltipContent } from "./DeviantHourTooltipContent";
import { formatXAxisTick } from "./DeviantHourWidget.helpers";
import { useDeviantHourWidget } from "./DeviantHourWidget.hooks";

type Props = QaProps & StyleProps;

export const DeviantHourWidget = (props: Props) => {
    const { t } = useTranslation();
    const showAllTicks = !useHasMaxWidth(Breakpoints.L);
    const { chartData, topHourLabel, isLoading, isEmpty, xAxisTicks, allowSwitch, tooltip, handleDataTypeChange, dataType } = useDeviantHourWidget();

    return (
        <Widget
            {...props}
            data-testid="deviant-hour-widget"
            tooltip={t(tooltip)}
            title={t("insights.summary.deviantHourWidget.title")}
            loading={isLoading}
            empty={isEmpty}
            headerActions={allowSwitch && <Switcher value={dataType} onChange={handleDataTypeChange} options={DEVIANT_DATA_TYPE_OPTIONS} />}
            subtitle={
                <Headline bold color="secondary">
                    {topHourLabel}
                </Headline>
            }
        >
            <LineChart
                data={chartData}
                TooltipContent={DeviantHourTooltipContent}
                xAxisProps={{
                    ticks: xAxisTicks,
                    tickFormatter: formatXAxisTick,
                    interval: showAllTicks ? 0 : undefined,
                }}
            />
        </Widget>
    );
};
