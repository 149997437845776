import { keyBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type ItemCallback, Layout, Layouts } from "react-grid-layout";
import { compact } from "react-grid-layout/build/utils";

import { notNullGuard } from "../../../../../models";
import { GRID_BREAKPOINTS, GRID_ROW_SIZE, WIDGET_TOOLBAR_HEIGHT, WIDGET_VERSION_SEPARATOR } from "../DraggableGrid.constants";
import { buildLayouts, calculateNewWidgetPosition, getColumnsSetup, prepareResponsiveLayoutConfig, updateRemovedLayout } from "../DraggableGrid.helpers";
import { DraggableGridProps, ScreenWidthVariant } from "../DraggableGrid.types";
import { useHandleIntroducedWidgets } from "./useHandleIntroducedWidgets";

export const useDraggableGrid = ({
    layouts: predefinedLayouts,
    knownWidgetIds,
    defaultLayoutConfig,
    widgets,
    isEditable,
    onLayoutChange,
    heightUnit = GRID_ROW_SIZE,
    ...props
}: DraggableGridProps) => {
    const columns = useMemo(() => getColumnsSetup(props.columns), [props.columns]);
    const highestColumnsCount = useMemo(() => Math.max(...Object.values(columns)) || 0, [columns]);
    const [currentBreakpoint, setCurrentBreakpoint] = useState<ScreenWidthVariant>("lg");

    const wereWidgetsAdded = useHandleIntroducedWidgets({
        predefinedLayouts,
        knownWidgetIds,
        defaultLayoutConfig,
        onLayoutChange,
        widgets,
        columns: highestColumnsCount,
    });

    const layouts = useMemo(
        () => predefinedLayouts ?? prepareResponsiveLayoutConfig(widgets, defaultLayoutConfig),
        [defaultLayoutConfig, widgets, predefinedLayouts],
    );

    const widgetsMap = useMemo(() => keyBy(widgets, "id"), [widgets]);
    const largeBreakpoint: ScreenWidthVariant = "lg";
    const [draggedId, setDraggedId] = useState<null | string>(null);
    const [addedWidgets, setAddedWidgets] = useState<string[]>([]);
    const [removedLayout, setRemovedLayout] = useState<Layout[]>([]);

    // TODO: Refactor to use internal state of layout
    // Simplify recalculations
    const updateLayouts = useCallback(
        (layout: Layout[]) => {
            const availableWidgetsIds = widgets.map(({ id, version }) => [id, version].filter(notNullGuard).join(WIDGET_VERSION_SEPARATOR));
            const compactedLayout = compact(layout, "vertical", highestColumnsCount, false);
            // NOTE: We use only one layout for widget positioning
            // and we build layouts from single layout.
            // That forces RGL to gradually arrange widgets when the container width changes.
            onLayoutChange?.(buildLayouts(compactedLayout, widgets), availableWidgetsIds);
        },
        [highestColumnsCount, onLayoutChange, widgets],
    );

    useEffect(() => {
        setRemovedLayout(updateRemovedLayout(widgets, layouts[largeBreakpoint], columns, largeBreakpoint));
    }, [widgets, layouts, columns, largeBreakpoint]);

    const handleLayoutChange = useCallback(
        (layout: Layout[]) => {
            if (isEditable && draggedId) {
                updateLayouts(layout);
            }
        },
        [isEditable, draggedId, updateLayouts],
    );

    useEffect(() => {
        if (!isEditable) {
            setAddedWidgets([]);
        }
    }, [isEditable]);

    const handleDragStart: ItemCallback = (_, oldItem) => {
        setDraggedId(oldItem.i);
    };
    const handleDragStop: ItemCallback = () => {
        setDraggedId(null);
    };

    const handleBreakpointChanged = useCallback((breakpoint: ScreenWidthVariant) => {
        setCurrentBreakpoint(breakpoint);
    }, []);

    const handleRemoveWidget = useCallback(
        (widgetId: string) => {
            const newLayouts: Layouts = Object.keys(layouts).reduce((acc, key) => {
                const newLayout = layouts[key].filter((gridItem) => gridItem.i !== widgetId);
                return { ...acc, [key]: newLayout };
            }, {});

            updateLayouts(newLayouts.lg);
            setAddedWidgets(addedWidgets.filter((w) => w !== widgetId));
        },
        [updateLayouts, layouts, addedWidgets],
    );

    const handleAddWidget = useCallback(
        (widgetId: string) => {
            const addedItem = removedLayout.find((gridItem) => gridItem.i === widgetId);
            const newLayouts: Layouts = Object.keys(layouts).reduce((updatedLayouts, screenWidthVariant) => {
                const newItemPosition = calculateNewWidgetPosition(layouts[screenWidthVariant], addedItem, columns[screenWidthVariant]);
                const newLayout = [...layouts[screenWidthVariant], { ...addedItem, ...newItemPosition }];
                return { ...updatedLayouts, [screenWidthVariant]: newLayout };
            }, {} as Layouts);

            updateLayouts(newLayouts.lg);
            setAddedWidgets([...addedWidgets, addedItem.i]);
        },
        [removedLayout, layouts, columns, addedWidgets, updateLayouts],
    );

    const currentLayout = useMemo(
        () =>
            layouts[currentBreakpoint].map((item) => ({
                ...item,
                static: !isEditable && wereWidgetsAdded,
            })),
        [isEditable, layouts, currentBreakpoint, wereWidgetsAdded],
    );

    return {
        columns,
        layouts,
        currentLayout,
        removedLayout,
        isEditable,
        handleLayoutChange,
        handleDragStart,
        handleDragStop,
        handleRemoveWidget,
        handleAddWidget,
        draggedId,
        widgetsMap,
        rowHeight: isEditable ? heightUnit + WIDGET_TOOLBAR_HEIGHT : heightUnit, // NOTE: GLOB-5439 workaround
        breakpoints: GRID_BREAKPOINTS,
        addedWidgets,
        handleBreakpointChanged,
    };
};
