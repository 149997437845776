import { Survicate } from "@survicate/survicate-web-surveys-wrapper";

export const SURVICATE_EVENTS = {
    insights: {
        enterSummary: "enter_kpi_summary",
    },
};

const notReadyCallback = () => {
    console.warn(
        "Trying to use Survicate instance before its initialization. Make sure you used SurvicateProvider properly and check for instance presence if needed",
    );
};

export const SURVICATE_PLACEHOLDER: Survicate = {
    invokeEvent: notReadyCallback,
    showSurvey: () => {
        notReadyCallback();
        return false;
    },
    retarget: notReadyCallback,
    getSurvey: () => {
        notReadyCallback();
        return { id: null, name: null };
    },
    ready: () => {
        notReadyCallback();
        return Promise.reject(new Error("Survicate not ready"));
    },
    destroyVisitor: notReadyCallback,
    addEventListener: notReadyCallback,
    getVisitorId: () => {
        notReadyCallback();
        return "";
    },
    removeEventListener: notReadyCallback,
    setVisitorTraits: notReadyCallback,
} as unknown as Survicate;
