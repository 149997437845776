import { useTranslation } from "react-i18next";

import { Widget } from "../shared/components";
import { SummaryValueTile } from "../shared/components/SummaryValueTile";
import { useCalloutResponseTimeWidget } from "./CalloutResponseTimeWidget.hooks";

export const CalloutResponseTimeWidget = () => {
    const { t } = useTranslation();
    const { previousValue, currentValue, label, isLoading } = useCalloutResponseTimeWidget();

    return (
        <Widget title={t("insights.summary.calloutAverageResponseWidget.title")} loading={isLoading} hasHeaderSeparator={false}>
            <SummaryValueTile value={currentValue} previousValue={previousValue} label={label} />
        </Widget>
    );
};
