import { EmptyState, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { ITrendingEvent } from "src/store/insights/InsightsModel";

import { TrendingEventsChart } from "./Chart";
import { EmptyStateContainer, StyledStack } from "./TrendingEvents.styles";
import { CustomizedCategories } from "./TrendingEvents.types";

interface TrendingEventChartProps {
    events: ITrendingEvent[];
    selectedCategories: CustomizedCategories;
    isTotalEnabled: boolean;
    isEmpty: boolean;
}
export const TrendingEventChart: React.FC<TrendingEventChartProps> = ({ events, selectedCategories, isTotalEnabled, isEmpty }) => {
    const { t } = useTranslation();

    if (isEmpty) {
        return (
            <Stack direction="column">
                <EmptyStateContainer direction="column" justifyContent="center" alignItems="center" flex={1} pv="XL">
                    <EmptyState icon="GraphIndicator" title={t("insights.trendingEvents.emptyState.title")} />
                    <Stack alignItems="center">
                        <Text>{t("insights.trendingEvents.emptyState.subtitle")}</Text>
                    </Stack>
                </EmptyStateContainer>
                <StyledStack mb="S" mt="S" mr="S">
                    <Text color="disabledText" bold>
                        {t("insights.trendingEvents.emptyState.noReports")}
                    </Text>
                </StyledStack>
            </Stack>
        );
    }

    return <TrendingEventsChart events={events} selectedCategories={selectedCategories} isTotalEnabled={isTotalEnabled} />;
};
