import i18next from "i18next";

import { DynamicReportPropertyType } from "../../models/ReportModel";
import { oldFieldCatalogIdMap, VehicleInspectionFields } from "../../models/reportTemplates/VehicleInspection";
import { getFieldValueByCatalogId, hasNrsField, isNrsReport } from "./dynamicTemplatePropertiesHelper";

const YES = "yes";
const NO = "no";

export const getVehicleInspectionReportProperties = (
    properties: DynamicReportPropertyType[],
): {
    licensePlate?: string;
    vehicleModel?: string;
    inspectionCount?: string;
} => {
    const licensePlate = getFieldValueByCatalogId(VehicleInspectionFields.LicensePlate, { properties }, oldFieldCatalogIdMap);
    const model = getFieldValueByCatalogId(VehicleInspectionFields.Model, { properties }, oldFieldCatalogIdMap);
    const emptyFieldLabel = i18next.t("common.noDataFound");

    return {
        licensePlate: licensePlate || emptyFieldLabel,
        vehicleModel: model || emptyFieldLabel,
        inspectionCount: getVehicleInspectionCount({ properties }),
    };
};

const getVehicleInspectionCount = (report: { properties: DynamicReportPropertyType[] }): string | undefined => {
    const inspectionFailQa: Record<string, string> = {
        [VehicleInspectionFields.ExteriorDamage]: YES,
        [VehicleInspectionFields.InteriorDamage]: YES,
        [VehicleInspectionFields.BrokenWindows]: YES,
        [VehicleInspectionFields.EmergencyEquipmentAvailable]: NO,
    };

    const { failedCount, totalCount } = Object.entries(inspectionFailQa).reduce(
        ({ failedCount, totalCount }, [catalogId, failFieldValue]) => {
            const fieldValue = getFieldValueByCatalogId(catalogId, report, oldFieldCatalogIdMap);
            const hasFailValue = fieldValue?.toLowerCase().trim() === failFieldValue;

            if (hasFailValue) {
                failedCount += 1;
            }

            if (fieldValue || (!isNrsReport(report) && catalogId !== VehicleInspectionFields.EmergencyEquipmentAvailable) || hasNrsField(catalogId, report)) {
                totalCount += 1;
            }

            return { failedCount, totalCount };
        },
        { failedCount: 0, totalCount: 0 },
    );

    return totalCount ? `${failedCount}/${totalCount}` : undefined;
};
