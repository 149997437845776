import { Icon, Stack, Text } from "@secuis/ccp-react-components";
import { TableCellProps } from "src/components/shared/Table";
import { PatrolTourType } from "src/models/ReportModel";

type Props = TableCellProps<string, PatrolTourType>;

export const TourNameCell = ({ cell }: Props) => {
    return (
        <Stack alignItems="center" justifyContent="flex-end" gap="XXS" paddingLeft="M">
            <Icon size={"M"} variant="Route" />
            <Text truncate>{cell}</Text>
        </Stack>
    );
};
