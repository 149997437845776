import { useCallback, useMemo, useState } from "react";

import { featureFlags } from "../../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../../hooks/featureFlags";
import { UserPreference } from "../../../../../models/UserModel";
import { useHasMobileServices } from "../../../../../store/siteObjects/SiteObjectsHooks";
import { useSetUserPreference, useUserPreference } from "../../../../../store/user/UserHooks";
import { deserializeSettings } from "../../Summary.helpers";
import { DeviantDataType } from "../types";

export const useDeviantChartDataType = (userPreferencesKey: UserPreference.DeviantHoursDataType | UserPreference.DeviantDayDataType, widgetId: string) => {
    const calloutsEnabled = useFeatureFlag(featureFlags.deviantCallouts);
    const { hasMobileServices } = useHasMobileServices();
    const withCallouts = hasMobileServices && calloutsEnabled;
    const setUserPreference = useSetUserPreference();
    const [preservedDataType] = useUserPreference(userPreferencesKey) as [undefined | DeviantDataType];
    const defaultType = useDefaultDeviantDataType(widgetId);

    const [dataType, setDataType] = useState<DeviantDataType>((withCallouts && preservedDataType) || defaultType);

    return {
        allowSwitch: withCallouts,
        handleDataTypeChange: useCallback(
            (dataType: DeviantDataType) => {
                setDataType(dataType);
                setUserPreference(userPreferencesKey, dataType);
            },
            [userPreferencesKey, setUserPreference],
        ),
        dataType,
    };
};

const useDefaultDeviantDataType = (widgetId: string) => {
    const calloutsEnabled = useFeatureFlag(featureFlags.deviantCallouts);
    const { hasMobileServices } = useHasMobileServices();
    const withCallouts = hasMobileServices && calloutsEnabled;
    const [oldUserSummarySettings] = useUserPreference(UserPreference.InsightsSummarySettingsMonth);
    const [newUserSummarySettings] = useUserPreference(UserPreference.InsightsSummarySettings);
    const deserializedSettings = useMemo(
        () => deserializeSettings(newUserSummarySettings) ?? deserializeSettings(oldUserSummarySettings),
        [oldUserSummarySettings, newUserSummarySettings],
    );

    // NOTE: A bit of a side effect to force 'callouts' being selected when widget is highlighted as new
    // (displayed in a grid yet not added in the settings)
    const isInLayout = useMemo(() => !!deserializedSettings?.layouts?.lg.find(({ i }) => i === widgetId), [deserializedSettings, widgetId]);

    return withCallouts && !!deserializedSettings?.layouts?.lg && !isInLayout ? DeviantDataType.Callouts : DeviantDataType.Events;
};
