import { createSelector } from "reselect";
import { IState } from "src/Store";

const mapLocationToursState = ({ tourSessions }: IState) => tourSessions;

const getLocationTours = createSelector([mapLocationToursState], (locationToursState) => locationToursState.tours);
const getLocationToursQueryStatus = createSelector([mapLocationToursState], (locationToursState) => locationToursState.queryStatus);
const getLocationToursQueryKey = createSelector([mapLocationToursState], (locationToursState) => locationToursState.queryKey);

export { getLocationTours, getLocationToursQueryStatus, getLocationToursQueryKey };
