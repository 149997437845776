import { IconType } from "@secuis/ccp-react-components";
import i18next from "i18next";
import { sumBy } from "lodash";
import {
    addDays,
    addMilliseconds,
    formatDate,
    formatExplicit,
    isAfter,
    isBefore,
    isBeforeOrEqual,
    isSameMonth,
    RAW_DATE_FORMAT,
    subDays,
} from "src/helpers/date";

import { sortByField } from "../../../helpers/ArrayHelper";
import { TABLE_TOTAL_KEY } from "../InsightsModel";
import { HOURS_RANGES } from "./PatrolDeviationsConstants";
import {
    CategoryKey,
    DailyPatrolDeviationResponse,
    DataTimeRange,
    HourlyPatrolDeviationResponse,
    PatrolDeviationData,
    PatrolDeviationItem,
} from "./PatrolDeviationsTypes";

export enum DeviationKeys {
    category = "category",
    children = "children",
    categoryLabel = "categoryLabel",
    chartData = "chartData",
    totalAmount = "totalAmount",
}

const sumHoursValuesByKey = (arr: HourlyPatrolDeviationResponse[], key: string) =>
    arr.reduce((acc, val) => {
        if (val.hours === key) {
            return acc + val.count;
        }
        return acc;
    }, 0);

const getDeviationsByCategoryAndDate = (
    deviations: DailyPatrolDeviationResponse[],
    categoryPropertyKey: string,
    rangeStartDate: string,
    rangeEndDate: string,
    historicalDaysOffset: number = 0,
): { [category: string]: { [date: string]: number } } => {
    return deviations.reduce((acc, deviation) => {
        const category = deviation[categoryPropertyKey];
        let date = formatExplicit(addDays(new Date(deviation.dateTime), historicalDaysOffset), RAW_DATE_FORMAT);
        // if deviation date is out of range, set it to the range start or end date due to location timezone difference
        if (date < rangeStartDate) {
            date = rangeStartDate;
        } else if (date > rangeEndDate) {
            date = rangeEndDate;
        }
        if (acc[category]) {
            if (acc[category][date]) {
                acc[category][date] += deviation.count;
            } else {
                acc[category][date] = deviation.count;
            }
        } else {
            acc[category] = {
                [date]: deviation.count,
            };
        }
        return acc;
    }, {});
};

export const getTrendArrow = (prev: number, current: number): IconType => {
    if (prev > current) {
        return "TrendGoingDown";
    }
    if (prev < current) {
        return "TrendGoingUp";
    }
    if (prev === current) {
        return "TrendMiddle";
    }
};

const getCategoryTranslation = (category: string) =>
    category === null ? i18next.t(`incident.category.noCategory`) : i18next.t(`incident.category.${category}`);

export const getDataTimeRange = (start: Date, end: Date): DataTimeRange => {
    const rangeLength = getDatesArray(start, end).length;
    switch (rangeLength) {
        case 1:
            return DataTimeRange.day;
        case 7:
            return DataTimeRange.week;
        case 30:
            return DataTimeRange.month;
        case 180:
            return DataTimeRange.halfYear;
        default:
            return DataTimeRange.anyRange;
    }
};

export const mapHourlyPatrolDeviationResponse = (
    historicalData: HourlyPatrolDeviationResponse[],
    actualData: HourlyPatrolDeviationResponse[],
    categoryKey: CategoryKey,
): PatrolDeviationData[] => {
    const allCategories = new Set(historicalData.concat(actualData).map((x) => x[categoryKey]));

    return [...allCategories].map((category) => {
        const categoryValues = actualData.filter((x) => x[categoryKey] === category);
        const historicalCategoryValues = historicalData.filter((x) => x[categoryKey] === category);

        return HOURS_RANGES.reduce((acc, time) => {
            const timePeriodValue = sumHoursValuesByKey(categoryValues, time.value);
            const historicalPeriodValue = sumHoursValuesByKey(historicalCategoryValues, time.value);
            return {
                ...acc,
                category: category,
                categoryLabel: getCategoryTranslation(category),
                [time.label]: { count: timePeriodValue, histCount: historicalPeriodValue },
                children:
                    categoryKey === CategoryKey.categoryLevel1 && category !== null
                        ? mapHourlyPatrolDeviationResponse(historicalCategoryValues, categoryValues, CategoryKey.categoryLevel2)
                        : null,
            };
        }, {});
    });
};

export const mapDailyPatrolDeviationResponse = (
    historicalData: DailyPatrolDeviationResponse[],
    actualData: DailyPatrolDeviationResponse[],
    categoryKey: CategoryKey,
    dates: string[],
): PatrolDeviationData[] => {
    const deviationsByCategoryAndDate = getDeviationsByCategoryAndDate(actualData, categoryKey, dates[0], dates[dates.length - 1], 0);
    const historicalDeviationsByCategoryAndDate = getDeviationsByCategoryAndDate(historicalData, categoryKey, dates[0], dates[dates.length - 1], dates.length);
    const allCategories = new Set(
        Object.keys(deviationsByCategoryAndDate)
            .concat(Object.keys(historicalDeviationsByCategoryAndDate))
            .map((key) => (key === "null" ? null : key)),
    );
    return [...allCategories].map((category) => {
        const categoryValues = deviationsByCategoryAndDate[category];
        const historicalCategoryValues = historicalDeviationsByCategoryAndDate[category];

        return dates.reduce((acc, date) => {
            return {
                ...acc,
                category,
                categoryLabel: getCategoryTranslation(category),
                [date]: { count: categoryValues?.[date] ?? 0, histCount: historicalCategoryValues?.[date] ?? 0 },
                children: categoryKey === CategoryKey.categoryLevel1 ? [] : null,
            };
        }, {});
    });
};

export const mapTotalDeviations = (deviationResponse: DailyPatrolDeviationResponse[], start: Date, end: Date) => {
    return deviationResponse.reduce((acc, val) => {
        let date = new Date(val.dateTime);
        if (isBefore(date, start)) {
            date = addDays(date, 1);
        }
        if (isAfter(date, end)) {
            date = subDays(date, 1);
        }
        const dateKey = formatExplicit(date, "MM");
        const category = val.categoryLevel1 ?? "unspecified";
        if (acc[dateKey]) {
            if (acc[dateKey][category]) {
                acc[dateKey][category] += val.count;
            } else {
                acc[dateKey] = {
                    ...acc[dateKey],
                    [category]: val.count,
                };
            }
        } else {
            acc = {
                ...acc,
                [dateKey]: {
                    [category]: val.count,
                },
            };
        }
        if (acc[dateKey]?.total) {
            acc[dateKey].total += val.count;
        } else {
            acc[dateKey] = {
                ...acc[dateKey],
                total: val.count,
            };
        }

        return acc;
    }, {});
};

// returns array of dates betveen start date and end date included
export const getDatesArray = (start: Date, end: Date, dateFormat: string = RAW_DATE_FORMAT): string[] => {
    const dateArr = [formatExplicit(start, dateFormat)];
    const startEndHoursDiff = Math.floor(Math.abs(end.getTime() - start.getTime()) / 36e5);
    if (startEndHoursDiff < 24) {
        return dateArr;
    }
    let currentDate = addMilliseconds(addDays(new Date(start.getTime()), 1), -1);
    while (isBeforeOrEqual(currentDate, end)) {
        dateArr.push(formatExplicit(currentDate, dateFormat));
        currentDate = addDays(currentDate, 1);
    }
    return [...new Set(dateArr)];
};

const aggregateDataByDays = (data: PatrolDeviationData, daysAmount: number): PatrolDeviationData => {
    const result = {};
    const { category, children, categoryLabel, ...rest } = data;
    const dates = Object.keys(rest);

    for (let i = 0; i < dates.length; i += daysAmount) {
        const startDate = new Date(`${dates[i]}T00:00`);
        const endDate = new Date(`${dates[Math.min(i + (daysAmount - 1), dates.length - 1)]}T00:00`);

        let totalCount = 0;
        let totalHistoricalValue = 0;

        for (let j = i; j <= i + (daysAmount - 1) && j < dates.length; j++) {
            totalCount += (data[dates[j]] as any).count;
            totalHistoricalValue += (data[dates[j]] as any).histCount;
        }

        const key = `${isSameMonth(startDate, endDate) ? formatExplicit(startDate, "d") : formatDate(startDate, "shortDayMonthDate")}-${formatDate(
            endDate,
            "shortDayMonthDate",
        )}`;

        result[key] = {
            count: totalCount,
            histCount: totalHistoricalValue,
        };
    }
    return { ...result, category, children, categoryLabel };
};

export const aggregateDataByMonths = (data: PatrolDeviationData, keyFormat: string = "MMM"): PatrolDeviationData => {
    const result = {};
    const { category, children, categoryLabel, ...rest } = data;
    const dates = Object.keys(rest);

    dates.forEach((date) => {
        const key = `${formatExplicit(new Date(`${date}T00:00`), keyFormat)}`;

        result[key] = {
            count: (data[date] as any).count + ((result?.[key] as any)?.count ?? 0),
            histCount: (data[date] as any).histCount + ((result?.[key] as any)?.histCount ?? 0),
        };
    });
    return { ...result, category, children, categoryLabel };
};

const aggregateWeek = (data: PatrolDeviationData): PatrolDeviationData => {
    const result = {};
    const { category, children, categoryLabel, ...rest } = data;
    const dates = Object.keys(rest);

    dates.forEach((date) => {
        const key = `${formatDate(new Date(`${date}T00:00`), "shortDayMonthDate")}`;
        result[key] = {
            count: (data[date] as any).count,
            histCount: (data[date] as any).histCount,
        };
    });
    return { ...result, category, children, categoryLabel };
};

const formatToChartData = (data: PatrolDeviationData): PatrolDeviationData => {
    const { category, children, categoryLabel, ...rest } = data;
    const keys = Object.keys(rest);
    const chartData = keys.map((k) => ({ date: k, value: rest[k] }));
    const totalAmount = {
        count: sumBy(chartData, (d) => (d.value as any).count),
        histCount: sumBy(chartData, (d) => (d.value as any).histCount),
    };
    return {
        category,
        categoryLabel,
        children,
        chartData,
        totalAmount,
    };
};

export const addTotalCount = (data: PatrolDeviationData[]): PatrolDeviationData[] => {
    if (!data[0]) {
        return [];
    }
    const keys = Object.keys(data[0]).filter((key) => !(key === "category" || key === "children"));

    const total = keys.reduce((acc, key) => {
        return {
            ...acc,
            category: TABLE_TOTAL_KEY,
            categoryLabel: i18next.t("insights.exceptionTrend.total_count"),
            [key]: {
                count: data.reduce((a, d) => a + ((d[key] as any)?.count || 0), 0),
                histCount: data.reduce((a, d) => a + ((d[key] as any)?.histCount || 0), 0),
            },
        };
    }, {});

    return [...data, total];
};

const removeEmptyEntries = (data: PatrolDeviationData[]): PatrolDeviationData[] =>
    data.filter((d) => {
        const { category, children, totalAmount, categoryLabel, ...rest } = d;
        const arr = Object.values(rest).map((val) => ({
            count: (val as PatrolDeviationItem).count,
            histCount: (val as PatrolDeviationItem).histCount,
        }));
        return !(sumBy(arr, "count") === 0 && sumBy(arr, "histCount") === 0);
    });

const sortByCategoryName = (a: PatrolDeviationData, b: PatrolDeviationData): 0 | 1 | -1 => {
    if (a.category === null) {
        return 1;
    }
    if (b.category === null) {
        return -1;
    }
    return sortByField("categoryLabel")(a, b);
};

export const formatTableData = (data: PatrolDeviationData[], format: DataTimeRange, countTotal: boolean = true): PatrolDeviationData[] => {
    let tableData = data;
    if (format === DataTimeRange.week) {
        tableData = data.map(aggregateWeek);
    }
    if (format === DataTimeRange.month) {
        tableData = data.map((x) => aggregateDataByDays(x, 10));
    }
    if (format === DataTimeRange.halfYear) {
        tableData = data.map((x) => aggregateDataByMonths(x));
    }
    if (format === DataTimeRange.anyRange) {
        tableData = data.map(formatToChartData);
    }
    tableData = removeEmptyEntries(tableData).sort(sortByCategoryName);
    tableData.sort(sortByCategoryName);
    if (countTotal) {
        return addTotalCount(tableData);
    }
    return tableData;
};
