export enum VehicleInspectionFieldsOld {
    licensePlate = "licensePlate",
    vehicleModel = "vehicleModel",
    modelCar = "modelCar",
    comments = "comments",
    exteriorDamage = "exteriorDamage?",
    interiorDamage = "interiorDamage?",
    brokenCrackedWindows = "broken/crackedWindows?",
    isAllEmergencyEquipmentAvailable = "isAllEmergencyEquipmentAvailable?",
    missingEquipment = "whatEquipmentIsMissing?",
    mileageStart = "mileageStart",
    mileageEnd = "mileageEnd",
    fuelLevel = "fuelLevel",
    oilLevel = "oilLevel",
    indicateTheLocationsOfDamageToTheVehicle = "indicateTheLocation(s)OfDamageToTheVehicle?",
}

export enum VehicleInspectionFields {
    LicensePlate = "CAT_334",
    Model = "CAT_335",
    Comments = "CAT_339",
    ExteriorDamage = "CAT_336",
    InteriorDamage = "CAT_337",
    BrokenWindows = "CAT_338",
    EmergencyEquipmentAvailable = "CAT_345",
    MissingEquipment = "CAT_346",
    LocationsOfDamage = "CAT_340",
    MileageStart = "CAT_341",
    MileageEnd = "CAT_342",
    FuelLevel = "CAT_343",
    OilLevel = "CAT_344",
}

export const oldFieldCatalogIdMap: Record<VehicleInspectionFieldsOld, VehicleInspectionFields> = {
    [VehicleInspectionFieldsOld.licensePlate]: VehicleInspectionFields.LicensePlate,
    [VehicleInspectionFieldsOld.vehicleModel]: VehicleInspectionFields.Model,
    [VehicleInspectionFieldsOld.modelCar]: VehicleInspectionFields.Model,
    [VehicleInspectionFieldsOld.comments]: VehicleInspectionFields.Comments,
    [VehicleInspectionFieldsOld.exteriorDamage]: VehicleInspectionFields.ExteriorDamage,
    [VehicleInspectionFieldsOld.interiorDamage]: VehicleInspectionFields.InteriorDamage,
    [VehicleInspectionFieldsOld.brokenCrackedWindows]: VehicleInspectionFields.BrokenWindows,
    [VehicleInspectionFieldsOld.isAllEmergencyEquipmentAvailable]: VehicleInspectionFields.EmergencyEquipmentAvailable,
    [VehicleInspectionFieldsOld.missingEquipment]: VehicleInspectionFields.MissingEquipment,
    [VehicleInspectionFieldsOld.mileageStart]: VehicleInspectionFields.MileageStart,
    [VehicleInspectionFieldsOld.mileageEnd]: VehicleInspectionFields.MileageEnd,
    [VehicleInspectionFieldsOld.fuelLevel]: VehicleInspectionFields.FuelLevel,
    [VehicleInspectionFieldsOld.oilLevel]: VehicleInspectionFields.OilLevel,
    [VehicleInspectionFieldsOld.indicateTheLocationsOfDamageToTheVehicle]: VehicleInspectionFields.LocationsOfDamage,
};
