import { useMemo } from "react";
import { useSelector } from "react-redux";
import { differenceInDays, endOfDay, startOfDay, subDays } from "src/helpers/date";

import InsightsSelectors from "../../../../../store/insights/InsightsSelectors";
import { SummaryPeriod } from "../types";

export const useSummaryPeriod = () => {
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate);

    return useMemo(() => calculatePeriods(startDate, endDate), [startDate, endDate]);
};

const calculatePeriods = (
    startDate: Date,
    endDate: Date,
): {
    currentPeriod: SummaryPeriod;
    previousPeriod: SummaryPeriod;
} => {
    const rangeLength = differenceInDays(endDate, startDate) + 1;
    const previousPeriodStart = startOfDay(subDays(startDate, rangeLength));
    const previousPeriodEnd = endOfDay(subDays(startDate, 1));

    return {
        currentPeriod: {
            start: startOfDay(startDate),
            end: endOfDay(endDate),
        },
        previousPeriod: {
            start: startOfDay(previousPeriodStart),
            end: endOfDay(previousPeriodEnd),
        },
    };
};
