export enum EventFieldsOld {
    title = "title",
    description = "description",
    policeInvolved = "policeInvolved",
    medicalServicesInvolved = "medicalServicesInvolved",
    fireDepartmentInvolved = "fireDepartmentInvolved",
    siteSupervisorNotified = "siteSupervisorNotified",
    personalData = "enterPersonalData",
    altDescription = "pleaseDescribeTheIncident(excludePersonalData)",
    eventDescription = "pleaseDescribeTheEvent(excludePersonalData)",
    incidentDiscoveryTime = "incidentDiscoveryTimeAndDate",
    eventDiscoveryTime = "eventDiscoveryTimeAndDate",
}

export enum EventFields {
    Title = "CAT_57",
    Description = "CAT_104",
    PoliceInvolved = "CAT_44",
    MedicalServicesInvolved = "CAT_77",
    FireDepartmentInvolved = "CAT_76",
    SupervisorNotified = "CAT_73",
    PersonalData = "CAT_107",
    DiscoveryTimeAndDate = "CAT_101",
    Location = "CAT_103",
}

export const oldFieldCatalogIdMap: Record<EventFieldsOld, EventFields> = {
    [EventFieldsOld.title]: EventFields.Title,
    [EventFieldsOld.policeInvolved]: EventFields.PoliceInvolved,
    [EventFieldsOld.medicalServicesInvolved]: EventFields.MedicalServicesInvolved,
    [EventFieldsOld.fireDepartmentInvolved]: EventFields.FireDepartmentInvolved,
    [EventFieldsOld.siteSupervisorNotified]: EventFields.SupervisorNotified,
    [EventFieldsOld.personalData]: EventFields.PersonalData,
    [EventFieldsOld.description]: EventFields.Description,
    [EventFieldsOld.altDescription]: EventFields.Description,
    [EventFieldsOld.eventDescription]: EventFields.Description,
    [EventFieldsOld.incidentDiscoveryTime]: EventFields.DiscoveryTimeAndDate,
    [EventFieldsOld.eventDiscoveryTime]: EventFields.DiscoveryTimeAndDate,
};
