import { useEffect, useState } from "react";
import { Layout, Layouts } from "react-grid-layout";

import { WIDGET_VERSION_SEPARATOR } from "../DraggableGrid.constants";
import { alignWidgetsForTheTop, buildLayouts, prepareResponsiveLayoutConfig } from "../DraggableGrid.helpers";
import { DraggableGridProps, GridItem } from "../DraggableGrid.types";

export const useHandleIntroducedWidgets = ({
    predefinedLayouts,
    widgets,
    defaultLayoutConfig,
    columns,
    knownWidgetIds,
    onLayoutChange,
}: Pick<DraggableGridProps, "widgets" | "defaultLayoutConfig" | "onLayoutChange" | "knownWidgetIds"> & {
    columns: number;
    predefinedLayouts: Layouts;
}) => {
    const [handledNewWidgets, setHandledNewWidgets] = useState(false);
    const [newItemsAdded, setNewItemsAdded] = useState(false);

    useEffect(() => {
        // We assume to allow items management on 'lg'.
        const userLayout = predefinedLayouts?.lg || [];

        if (!handledNewWidgets && userLayout && knownWidgetIds) {
            const newWidgets = identifyNewWidgets(userLayout, widgets, knownWidgetIds);
            const newWidgetsIds = newWidgets.map(({ id }) => id);
            const oldLayout = userLayout.filter((item) => !newWidgetsIds.includes(item.i));
            const newWidgetsPlacement = alignWidgetsForTheTop(newWidgets, columns);
            const rowsToAdd =
                newWidgetsPlacement.length && Math.max(...newWidgetsPlacement.map((item) => item.y + (item.height ?? item.responsiveSizing.lg.height)));
            if (rowsToAdd) {
                const responsiveLayouts = prepareResponsiveLayoutConfig(widgets, [
                    ...oldLayout.map((item) => ({
                        id: item.i,
                        x: item.x,
                        y: item.y + rowsToAdd,
                    })),
                    ...newWidgetsPlacement,
                ]);

                setNewItemsAdded(true);

                onLayoutChange(
                    buildLayouts(responsiveLayouts.lg, widgets),
                    widgets.map(({ id }) => id),
                );
            }
            setHandledNewWidgets(true);
        }
    }, [knownWidgetIds, columns, onLayoutChange, widgets, predefinedLayouts, defaultLayoutConfig, handledNewWidgets]);

    return handledNewWidgets && newItemsAdded;
};

const identifyNewWidgets = (userLayout: Layout[], widgets: GridItem[] = [], knownWidgetIds: string[] = []): GridItem[] => {
    const userLayoutIds = userLayout?.map(({ i }) => i);
    const knownWidgetsInfo = knownWidgetIds.map((knownId) => {
        const [id, version] = knownId.split(WIDGET_VERSION_SEPARATOR);

        return {
            id,
            version: version ? parseInt(version) : 1,
        };
    });

    return userLayout
        ? widgets.filter((widget) => {
              const isInLayout = userLayoutIds?.includes(widget.id);
              const isNewWidget = !knownWidgetsInfo.find((knownWidget) => knownWidget.id === widget.id);
              const isNewVersionOfOldWidget = knownWidgetsInfo.find(
                  (knownWidget) => knownWidget.id === widget.id && knownWidget.version < (widget?.version || 1),
              );
              return (!isInLayout && isNewWidget) || isNewVersionOfOldWidget;
          })
        : [];
};
