import { convertImageToBase64 } from "../models/ReportImagesModels";

export const getImageFromResponse = (imagePath: string, imageId: string) => async (res: Response) => {
    const contentType = res.headers.get("Content-Type");
    const blobData = await res.blob();
    const convertPromise: Promise<string> = new Promise((resolve) => {
        convertImageToBase64(blobData, (result) => {
            let resultConverted = result;
            //sometimes the content type is  not detected correctly
            if (result.startsWith("data:application/octet-stream;base64,")) {
                const invalidContentRegex = /^data:application\/octet-stream;base64,/;
                resultConverted = result.replace(invalidContentRegex, `data:${contentType};base64,`);
            }
            resolve(resultConverted);
        });
    });

    const imageUri = await convertPromise;
    return {
        imagePath,
        imageUri,
        imageId,
    };
};
