import {
    Breakpoints,
    ButtonText,
    Graph,
    GraphColor,
    GraphIcon,
    GraphIconType,
    Palette,
    Spacing,
    Stack,
    Text,
    Theme,
    useHasMaxWidth,
} from "@secuis/ccp-react-components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { startOfMonth, subMonths } from "src/helpers/date";
import { useFilterableReportTypes } from "src/hooks/ReportTypeHooks";
import { ReportTemplateEnum } from "src/models/ReportModel";
import { CustomizedCategories } from "src/pages/Insights/Events/TrendingEvents/TrendingEvents.types";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { ChartWrapper } from "../../../../../components/Insights/ChartWrapper";
import { EventsCountTick } from "../../../../../components/Insights/EventsCountTick";
import { MonthsTick } from "../../../../../components/Insights/MonthsTick";
import { ITrendingEvent } from "../../../../../store/insights/InsightsModel";
import InsightsSelectors from "../../../../../store/insights/InsightsSelectors";
import { LegendStyled } from "../../../Common.styled";
import { CHART_CATEGORIES } from "./Chart.constants";
import { buildRedirectUrl, getEventDomain } from "./Chart.helpers";
import { ChartCategoryType } from "./Chart.types";
import { CustomIcon } from "./CustomIcon";
import { EventsCountTooltip } from "./EventsCountTooltip";

type Props = {
    events: ITrendingEvent[];
    selectedCategories?: CustomizedCategories;
    isTotalEnabled: boolean;
};

export interface ChartLegendConfig {
    color: GraphColor;
    iconVariant: GraphIconType;
    key: string;
    title: string;
}

export const Chart = ({ events, selectedCategories, isTotalEnabled }: Props) => {
    const { isMobileLayout } = useInsightsPageContext();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isMobileMode = isMobileLayout || isMobile;
    const chartConfig = isTotalEnabled ? CHART_CATEGORIES : CHART_CATEGORIES.slice(1);

    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const { t } = useTranslation();
    const { defaultInsightsReportTypes } = useFilterableReportTypes();
    const trendingEventsReportTypes = useMemo(
        () => [
            ...defaultInsightsReportTypes,
            ReportTemplateEnum.tourException,
            ReportTemplateEnum.tourMultiException,
            ReportTemplateEnum.callout,
            ReportTemplateEnum.vehicleInspection,
        ],
        [defaultInsightsReportTypes],
    );
    const startDate = startOfMonth(subMonths(new Date(), 6));
    const navigate = useNavigate();

    const redirectUrl = useMemo(() => {
        return buildRedirectUrl(events, selectedCategories, selectedRegions, trendingEventsReportTypes, startDate);
    }, [events, selectedCategories, selectedRegions, startDate, trendingEventsReportTypes]);

    const domain = useMemo(() => getEventDomain(events), [events]);

    const getLines = useCallback(() => {
        if (!events?.length) {
            return [];
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { month, ...dataPoints } = events[0];
        return Object.keys(dataPoints).map((p, i) => (
            <Line
                key={`line${p}`}
                type="linear"
                dataKey={p}
                dot={<CustomIcon color={chartConfig[i].color} variant={chartConfig[i].iconVariant as any} />}
                activeDot={<CustomIcon scale color={chartConfig[i].color} variant={chartConfig[i].iconVariant as any} />}
                legendType="circle"
                height={2}
                stroke={{ ...Theme, ...Graph }[chartConfig[i].color] as string}
                strokeWidth={i === 0 ? 2 : 1}
                strokeDasharray={chartConfig[i].strokeDashArray}
                strokeLinecap="round"
            />
        ));
    }, [chartConfig, events]);

    const legendCategories = Object.keys(events[0]).slice(1);

    const getCategoryTitle = useCallback(
        (cat: ChartCategoryType, index: number) => {
            if (cat.title) return t(cat.title);

            const level3 = selectedCategories?.level3Categories?.find((x) => x.value === legendCategories[index])?.parent.value;
            if (level3) return `${t(`incident.category.${level3}`)} • ${t(`incident.category.${legendCategories[index]}`)}`;

            return t(`incident.category.${legendCategories[index]}`);
        },
        [legendCategories, selectedCategories?.level3Categories, t],
    );

    const chartCategoriesLegendConfig = useMemo(() => {
        return legendCategories.map((key, i) => {
            return {
                color: chartConfig[i].color,
                iconVariant: chartConfig[i].iconVariant,
                key: key,
                title: getCategoryTitle(chartConfig[i], i),
            };
        });
    }, [chartConfig, getCategoryTitle, legendCategories]);

    return (
        <ChartWrapper>
            <ResponsiveContainer width="100%" height="100%" minHeight={310}>
                <LineChart
                    data={events}
                    margin={{
                        bottom: 10,
                        left: -20,
                    }}
                >
                    <CartesianGrid />
                    <XAxis
                        axisLine={false}
                        dataKey="month"
                        padding={{ left: Spacing.S, right: Spacing.S }}
                        stroke={Palette.Gray100}
                        tickLine={false}
                        tick={<MonthsTick shouldAddYear />}
                    />
                    <YAxis
                        axisLine={false}
                        domain={domain}
                        interval={0}
                        padding={{ top: Spacing.S, bottom: Spacing.M }}
                        stroke={Palette.Gray100}
                        tickLine={false}
                        tick={<EventsCountTick />}
                        tickCount={5}
                    />
                    <Tooltip
                        cursor={{ stroke: Palette.Navy300, strokeWidth: 1, strokeDasharray: "4 4" }}
                        content={<EventsCountTooltip chartCategoriesLegendConfig={chartCategoriesLegendConfig} />}
                    />
                    {getLines()}
                </LineChart>
            </ResponsiveContainer>
            <Stack direction={isMobileMode ? "column" : "row"} mt="L" justifyContent="space-between">
                <Stack direction="column">
                    <Stack mb="XS">
                        <Text small bold>
                            {t("insights.trendingEvents.legend")}
                        </Text>
                    </Stack>
                    <LegendStyled gap={["M", "M", "XS"]} flexWrap="wrap">
                        {chartCategoriesLegendConfig.map((cat) => {
                            return (
                                <Stack key={`legendItem${cat.title}`} gap={"XXS"} alignItems="center">
                                    <GraphIcon color={cat.color} variant={cat.iconVariant} />
                                    <Text data-testid="legend-item-category" micro>
                                        {cat.title}
                                    </Text>
                                </Stack>
                            );
                        })}
                    </LegendStyled>
                </Stack>
                <Stack alignItems="center" mt={["0", "0", "S"]} justifyContent={isMobileMode ? "flex-end" : null}>
                    <ButtonText onClick={() => navigate(redirectUrl)} direction="row-reverse" icon="ArrowForward">
                        {t("insights.highlights.viewReports")}
                    </ButtonText>
                </Stack>
            </Stack>
        </ChartWrapper>
    );
};
