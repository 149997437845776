import { keyBy, mapValues, maxBy } from "lodash";
import { Layout, Layouts } from "react-grid-layout";

import { notNullGuard } from "../../../../../models";
import { DEFAULT_COLUMNS_PER_BREAKPOINT, LAYOUT_BREAKPOINTS } from "../DraggableGrid.constants";
import { ColumnsPerBreakpoint, GridItem, ItemPlacement, ScreenWidthVariant } from "../DraggableGrid.types";

export { alignWidgetsForTheTop } from "./alignWidgetsForTheTop";

export const getColumnsSetup = (columns: number | ColumnsPerBreakpoint): ColumnsPerBreakpoint => {
    let setup = DEFAULT_COLUMNS_PER_BREAKPOINT;

    if (columns) {
        if (typeof columns === "number") {
            setup = mapValues(setup, () => columns);
        } else {
            setup = columns;
        }
    }

    return setup;
};

export const prepareResponsiveLayoutConfig = (widgets: GridItem[], placement: ItemPlacement[]): Layouts => {
    const widgetsMap = keyBy(widgets, "id");

    return LAYOUT_BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
            [breakpoint]: placement
                .map(({ id, ...item }) => {
                    const widget = widgetsMap[id];

                    if (widget) {
                        const width = widget.responsiveSizing?.[breakpoint]?.width || widget.width || 1;
                        const height = widget.responsiveSizing?.[breakpoint]?.height || widget.height || 1;

                        return { i: id, w: width, h: height, ...item };
                    }
                })
                .filter(notNullGuard),
            ...acc,
        }),
        {},
    );
};

export const calculateNewWidgetPosition = (layout: Layout[], widget: Pick<Layout, "w">, columnsCount: number) => {
    const lastItemY = maxBy(layout, "y") || { x: 0, y: 0, w: 0 };
    const lastRow = layout.filter((item) => item.y === lastItemY.y);
    const lastItemX = maxBy(lastRow, "x") || { x: 0, y: 0, w: 0 };
    const widgetWidth = widget.w;
    const fitInLastLine = lastItemX.x + lastItemX.w + widgetWidth <= columnsCount;

    if (fitInLastLine) {
        const xPos = lastItemX.x + lastItemX.w;
        return { y: lastItemY.y, x: xPos };
    } else {
        return { x: 0, y: lastItemY.y + 1 };
    }
};

export const updateRemovedLayout = (
    widgets: GridItem[],
    baseLayout: Layout[],
    columns: Partial<Record<ScreenWidthVariant, number>>,
    breakpoint: ScreenWidthVariant,
): Layout[] => {
    return widgets
        .filter((widget) => !baseLayout.some((item) => item.i === widget.id))
        .reduce((acc, widget) => {
            const widgetWidth = widget.responsiveSizing?.[breakpoint]?.width || widget.width || 1;
            const widgetHeight = widget.responsiveSizing?.[breakpoint]?.height || widget.height || 1;
            const removedWidget = {
                ...widget,
                w: widgetWidth,
                h: widgetHeight,
                i: widget.id,
            };

            return [
                ...acc,
                {
                    ...removedWidget,
                    ...calculateNewWidgetPosition(acc, removedWidget, columns[breakpoint]),
                },
            ];
        }, []);
};

const getResponsiveLayout = (layout: Layout[], breakpoint: ScreenWidthVariant, widgets: GridItem[]): Layout[] =>
    layout.map(({ w, h, ...rest }) => {
        const widgetSettings = widgets.find((widget) => widget.id === rest.i);
        return {
            ...rest,
            w: widgetSettings?.responsiveSizing?.[breakpoint]?.width || w,
            h: widgetSettings?.responsiveSizing?.[breakpoint]?.height || h,
        };
    });

export const buildLayouts = (layout: Layout[], widgets: GridItem[]): Layouts => {
    return {
        lg: getResponsiveLayout(layout, "lg", widgets),
        md: getResponsiveLayout(layout, "md", widgets),
        sm: getResponsiveLayout(layout, "sm", widgets),
        xs: getResponsiveLayout(layout, "xs", widgets),
        xxs: getResponsiveLayout(layout, "xxs", widgets),
    };
};
