import { Separator } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { getReportTemplateName, resolveReportSubheader } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicReportType } from "../../../models/ReportModel";
import { ISiteObject } from "../../../models/SiteObjectModel";
import { HeaderStackStyled } from "../SharedComponents/ReportDetailsHeader/Common.styled";
import { LocationHeaderLine } from "../SharedComponents/ReportDetailsHeader/LocationHeaderLine";
import { ReportDetailsHeaderLine1 } from "../SharedComponents/ReportDetailsHeader/ReportDetailsHeaderLine1";
import { TemplateHeadline } from "../SharedComponents/ReportDetailsHeader/TemplateHeadline";
import { CarHeaderLine } from "./CarHeaderLine";

type Props = {
    report: DynamicReportType;
    siteObject: ISiteObject;
    isShared: boolean;
};

export const VehicleInspectionDetailsHeader = ({ report, siteObject, isShared }: Props) => {
    const { t } = useTranslation();

    const localizedTemplate = getReportTemplateName(report);

    return (
        <HeaderStackStyled data-testid="name-detail-view">
            <TemplateHeadline
                header={localizedTemplate}
                subheader={resolveReportSubheader(report.template, report.childTemplate)}
                sideText={`${t("reportlist.subtitle.report")} # ${report.sourceId}`}
            />
            <Separator mb="XS" />
            <CarHeaderLine report={report} />

            <ReportDetailsHeaderLine1 report={report} siteObject={siteObject} isShared={isShared} />
            <Separator mv="XS" />
            <LocationHeaderLine siteObject={siteObject} />
            <Separator mt="XS" />
        </HeaderStackStyled>
    );
};
