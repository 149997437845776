import { SeverityLevel } from "src/store/organization/types";

enum SeverityIconVariant {
    PriorityInfo = "PriorityInfo",
    PriorityLow = "PriorityLow",
    PriorityModerate = "PriorityModerate",
    PriorityHigh = "PriorityHigh",
    PrioritySevere = "PrioritySevere",
}

const SEVERITY_ICONS: Record<SeverityLevel, SeverityIconVariant> = {
    Info: SeverityIconVariant.PriorityInfo,
    Low: SeverityIconVariant.PriorityLow,
    Moderate: SeverityIconVariant.PriorityModerate,
    High: SeverityIconVariant.PriorityHigh,
    Severe: SeverityIconVariant.PrioritySevere,
};

export const getSeverityIconVariant = (severityLevel: SeverityLevel): SeverityIconVariant => SEVERITY_ICONS[severityLevel] || SeverityIconVariant.PriorityLow;
