import { faker } from "@faker-js/faker";
import { Breakpoints, Spacing, Text, type TooltipProps, TooltipRich } from "@secuis/ccp-react-components";
import { PropsWithChildren, ReactNode, useMemo } from "react";
import styled from "styled-components";

export type Place = TooltipProps["place"];

/*
 * NOTE: This serves as our internal wrapper for SCL's tooltip,
 * to provide some predefined settings used consistently in the app.
 * It also allows to provide more complex message as TSX instead of simple string, if needed.
 * We use it multiple times in Guarding.
 * That serves a different feature that pure TooltipRich which is prepared for extended logic,
 * user interactions and so on.
 *
 * If reasonable, we can consider combining Tooltip and TooltipRich with full functionalities in the future.
 *
 * */

type Props = PropsWithChildren<Omit<TooltipProps, "message">> & {
    disabled?: boolean;
    message: string | ReactNode;
    title?: string;
    noSizeLimit?: boolean;
};

const limitWidth = `
    max-width: 365px;

    @media (max-width: ${Breakpoints.XS}) {
        max-width: 85vw;
    }
`;

// const TooltipStyled = styled(SclTooltip)`
//     ${limitWidth}
// `;

const TooltipRichStyled = styled(TooltipRich).attrs({
    borderRadius: "XXS",
})<{
    smallSpacing?: boolean;
    noSizeLimit?: boolean;
}>`
    &.custom-tooltip {
        ${({ smallSpacing }) => (smallSpacing ? `padding: ${Spacing.XXS}px ${Spacing.XS}px;` : "")};

        ${({ noSizeLimit }) => !noSizeLimit && limitWidth};

        //NOTE: Overriding sizing forced by TooltipRich
        @media (min-width: 0px) {
            width: max-content;
        }
    }
`;

export const Tooltip = ({ message, disabled, children, place = "top", color = "purple", ...rest }: Props) => {
    if (disabled || !message) {
        return children;
    }

    /* NOTE: Temporarily commented code
     * for some reason, SclTooltip does not get proper placement based on the viewport;
     * However, SclTooltipRich is working fine and our simple version works as a charm.
     */
    // if (typeof message === "string") {
    //     return (
    //         <TooltipStyled place={place} message={message} {...rest}>
    //             {children}
    //         </TooltipStyled>
    //     );
    // } else {
    return (
        <ComplexMessageTooltip place={place} color={color} message={message} {...rest}>
            {children}
        </ComplexMessageTooltip>
    );
    // }
};

const ComplexMessageTooltip = ({
    message,
    children,
    color = "purple",
    place,
    title,
    noSizeLimit,
}: PropsWithChildren<
    Pick<TooltipProps, "color" | "place"> & {
        message?: ReactNode;
        title?: string;
        noSizeLimit?: boolean;
    }
>) => {
    const anchorClass = useMemo(() => `a${faker.string.uuid()}`, []);

    return (
        <>
            <div className={anchorClass}>{children}</div>
            <TooltipRichStyled
                title={title}
                anchorSelect={`.${anchorClass}`}
                place={place}
                backgroundColor={color}
                smallSpacing={!title}
                noSizeLimit={noSizeLimit}
            >
                <Text micro bold>
                    {message}
                </Text>
            </TooltipRichStyled>
        </>
    );
};
