import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getIsFieldPresentByCatalogId,
    hasNrsField,
    isNrsReport,
} from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { MaintenanceFields, oldFieldsCatalogIdMap } from "../../../models/reportTemplates/Maintenance";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { PicturesSection } from "../SharedComponents/Pictures";
import { TextAreaSummaryField } from "../SharedComponents/TextArea";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "./DynamicTemplateUtils";

export const getMaintenanceReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const summaryChildren = [
        {
            component: TextSummaryField,
            props: {
                fieldName: getFieldNameByCatalogId(MaintenanceFields.MaintenancePerformed, report) ?? "reportdetails.maintenance.performingMaintenanceQuestion",
                fieldValue: getFieldValueByCatalogId(MaintenanceFields.MaintenancePerformed, report, oldFieldsCatalogIdMap),
                mobileDirection: "column",
                isRequired: !isNrsReport(report) || getIsFieldPresentByCatalogId(MaintenanceFields.MaintenancePerformed, report, oldFieldsCatalogIdMap),
                shouldAutoTranslate: true,
            },
        },
        {
            component: TextAreaSummaryField,
            props: {
                title: getFieldNameByCatalogId(MaintenanceFields.Description, report) ?? "reportdetails.maintenance.summary.description",
                content: getFieldValueByCatalogId(MaintenanceFields.Description, report, oldFieldsCatalogIdMap),
                isRequired: hasNrsField(MaintenanceFields.Description, report),
                shouldAutoTranslate: true,
            },
        },
        ...getUnknownFieldsComponentsNRS({
            report,
            oldFieldFallbackMap: oldFieldsCatalogIdMap,
            excludedCatalogIds: Object.values(MaintenanceFields),
            sharingId: sharedReportId,
        }),
    ];

    // NOTE: Workaround temporary approach to determine if there is something inside the section.
    // However, we should not rely on component's props as its logic may change in time
    const shouldShowSummary = summaryChildren.some(({ props }) => props.isRequired !== false || !!props.value);

    return [
        ...getBaseReportConfig({ report, siteObject, sharedReportId }),

        conditionalRender(report.images.length > 0, () => ({
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.maintenance.description",
            },
            children: [
                {
                    component: PicturesSection,
                    props: {
                        sharingId: sharedReportId,
                        imagesData: report.images,
                    },
                },
            ],
        })),
        conditionalRender(shouldShowSummary, () => ({
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.maintenance.summary.title",
                collapsedLabel: "reportdetails.summary.moreDetails",
                expandedLabel: "reportdetails.summary.lessDetails",
            },
            children: summaryChildren,
        })),
    ];
};
