import { formatFriendly } from "src/helpers/date";

import { useSummaryPeriod } from "../shared/hooks";
import { SummaryPeriod } from "../shared/types";

export const useSummaryHeader = () => {
    const periods = useSummaryPeriod();
    const comparePeriodLabel = getRangeLabel(periods.previousPeriod);

    return {
        comparePeriodLabel,
    };
};

const getRangeLabel = ({ start, end }: SummaryPeriod): string => {
    const startLabel = formatFriendly(start, true);
    const endLabel = formatFriendly(end, true);

    return startLabel + (startLabel !== endLabel ? ` - ${endLabel}` : "");
};
