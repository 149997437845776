import { StyleSheet } from "@react-pdf/renderer";
import { Palette, Spacing } from "@secuis/ccp-react-components";

export const styles = StyleSheet.create({
    body: {
        backgroundColor: "white",
        color: "black",
        fontFamily: "SecuritasPro-Regular",
        fontWeight: "normal",
        padding: 32,
        paddingBottom: 98,
        paddingTop: 76,
        fontSize: 11,
    },
    logoSection: {
        top: 24,
        position: "absolute",
        right: 32,
    },
    section: {
        display: "flex",
        flexDirection: "column",
        paddingTop: 12,
    },
    sectionTitle: {
        marginBottom: 12,
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: "0.4px",
        color: Palette.Navy800,
        fontSize: 9,
    },
    sectionTextHeader: {
        marginBottom: 8,
        fontWeight: "bold",
        letterSpacing: 0.3,
    },
    involvementSection: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    imagesSection: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    imagesCarousel: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginTop: 4,
    },
    reportImage: {
        marginRight: 4,
        maxHeight: 150,
        maxWidth: "20%",
        objectFit: "contain",
    },
    propertyImage: {
        maxWidth: "93", // 124px
        textAlign: "right",
        maxHeight: "93",
        objectFit: "contain",
    },
    indentedSection: {
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        marginLeft: "auto",
        width: 60,
        height: 32,
    },
    headlineWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        gap: 24,
        flexWrap: "wrap",
    },
    headlineHeaderText: {
        fontWeight: "bold",
        fontSize: 18,
        color: Palette.Navy800,
    },
    headlineSubheaderText: {
        fontWeight: "bold",
        fontSize: 10,
        lineHeight: 1.4,
        letterSpacing: "0.3px",
        color: Palette.Navy800,
    },
    headlineSideText: {
        fontWeight: "bold",
        fontSize: 9,
        lineHeight: 1.4,
        letterSpacing: "0.3px",
        color: Palette.Navy800,
        marginLeft: "auto",
    },
    headerDate: {
        fontSize: 9,
        letterSpacing: "0.3px",
        color: Palette.Navy800,
    },
    categoryWrapper: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 4,
    },
    categoryText: {
        fontSize: 10,
        fontWeight: "bold",
    },
    detailsLine: {
        display: "flex",
        flexDirection: "row",
        color: Palette.Navy800,
        marginTop: 12,
    },
    detailsWrappableWrapper: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 8,
    },
    locationBoldText: {
        fontSize: 9,
        fontWeight: "bold",
    },
    locationText: {
        fontSize: 9,
    },
    site: {
        fontWeight: "bold",
    },
    categoriesWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 16,
        marginTop: 12,
    },
    severityText: {
        textAlign: "center",
        textTransform: "uppercase",
    },
    sectionLineBreak: {
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#E5E5E4", //grey300
        width: "100%",
        marginTop: 12,
    },
    verticalDivider: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "#E5E5E4", //grey300
        alignSelf: "stretch",
        minHeight: 0,
        maxWidth: 1,
        flexGrow: 1,
    },
    lineBreak: {
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#E5E5E4", //grey300
        marginTop: 4,
        marginBottom: 4,
        width: "100%",
    },
    pageNumber: {
        position: "absolute",
        bottom: 24,
        left: 0,
        right: 0,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 10,
    },
    footerText: {
        position: "absolute",
        bottom: 50,
        left: 0,
        right: 0,
        textAlign: "center",
        fontSize: 11,
    },
    footerLine: {
        position: "absolute",
        bottom: 81,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#E5E5E4", //grey300
        width: "100%",
        marginLeft: 32,
    },
    inlineFullWidth: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    inline: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    summaryFieldName: {
        flex: "0 1 50%",
        fontWeight: "bold",
        alignSelf: "center",
    },
    summaryFieldValue: {
        flex: 1,
        textAlign: "right",
    },
    severityBadge: {
        borderRadius: "24px",
        fontSize: "12px",
        fontWeight: "bold",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 8px",
    },
    xxsColumnGap: {
        marginLeft: "4px",
    },
    reportTypeBadge: {
        backgroundColor: Palette.Navy100,
        borderRadius: "24px",
        fontSize: "9px",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 8px",
    },
    detailsIconContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 6,
    },
    fromToText: {
        marginRight: "8px",
        color: "#717376",
    },
    fromToDateValue: {
        maxWidth: "30%",
    },
    boldText: {
        fontWeight: "bold",
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
    },
    dot: {
        height: Spacing.XS,
        width: Spacing.XS,
        borderRadius: "50",
        marginRight: Spacing.XS,
        marginTop: 2,
    },
    completionPercentage: {
        fontSize: Spacing.XL,
        marginRight: 40,
        marginTop: Spacing.S,
    },
    pieChart: {
        width: 128,
        height: 128,
    },
    checkpointNameLabel: {
        fontWeight: "bold",
        flex: 1,
        paddingRight: Spacing.XS,
    },
});
