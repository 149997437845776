import { IMultiListItem } from "../components/shared/CheckboxList/CheckboxListItem.types";
import { sortByField } from "./ArrayHelper";

export const getAllDescendants = (item: IMultiListItem): string[] => {
    const children: string[] = [];
    getChildrenRecursive(item, children);
    return children;
};

const getChildrenRecursive = (item: IMultiListItem, children: string[]): string[] | undefined => {
    if (!item?.children?.length) {
        return children;
    }
    item.children.forEach((c) => {
        children.push(c.value);
        getChildrenRecursive(c, children);
    });
};

export const getLeafs = (items: IMultiListItem[]): string[] => {
    return items.reduce((leafs, item) => {
        if (!item?.children?.length) {
            return [...leafs, item.value];
        } else {
            return [...leafs, ...getLeafs(item.children)];
        }
    }, [] as string[]);
};

export const getItemsFromSelectedIds = (
    selectedItems: string[],
    allItems: IMultiListItem[],
): {
    itemsCount: number;
    items: IMultiListItem[];
} => {
    let itemsCount = 0;
    const selected: IMultiListItem[] = [];

    const updateSelectionInsideDropdownItemRecursive = (item: IMultiListItem): void => {
        if (item?.children?.length) {
            const allChildrenSelected = item.children.every((c) => selectedItems.includes(c.value));
            if (allChildrenSelected) {
                selected.push(item);
                itemsCount += getLeafs([item]).length;
                return;
            }
            item.children.forEach((child) => {
                updateSelectionInsideDropdownItemRecursive(child);
            });
        }
        if (selectedItems.includes(item.value)) {
            selected.push(item);
            itemsCount += 1;
        }
    };

    allItems.forEach((i) => {
        updateSelectionInsideDropdownItemRecursive(i);
    });

    selected.sort(sortByField("value"));

    return { itemsCount, items: selected };
};

export const getNewDropdownSelection = (selectedItem: IMultiListItem, selectedItemIds: string[]): string[] => {
    let newSelection = [...new Set(selectedItemIds)];
    if (newSelection.includes(selectedItem.value)) {
        deselectDescendants();
        deselectAscendants();
    } else {
        newSelection.push(selectedItem.value);
        selectDescendants();
        selectAscendants();
    }
    return [...new Set(newSelection)];

    function selectDescendants() {
        const allDescendants = getAllDescendants(selectedItem);
        allDescendants.forEach((c) => newSelection.push(c));
    }

    function selectAscendants() {
        let parent = selectedItem.parent;
        while (parent) {
            const allChildrenSelected = !!parent.children && parent.children.every((c) => newSelection.includes(c.value));

            if (allChildrenSelected) {
                newSelection.push(parent.value);
                parent = parent.parent;
            } else {
                break;
            }
        }
    }

    function deselectDescendants() {
        const allDescendants = getAllDescendants(selectedItem);
        newSelection = newSelection.filter((value) => value !== selectedItem.value && allDescendants.every((d) => d !== value));
    }

    function deselectAscendants() {
        let parent = selectedItem.parent;
        while (parent) {
            if (newSelection.includes(parent.value)) {
                newSelection = newSelection.filter((c) => c !== parent.value);
                parent = parent.parent;
            } else {
                break;
            }
        }
    }
};
