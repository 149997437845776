import { Breakpoints, Stack, Table, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSettingsRefContext } from "src/pages/Settings/Settings.provider";

import type { ClientSeverityTableData, SeverityUpdateProps } from "../../ClientSeverityLevel.types";
import { EmptyResults } from "../EmptyResults";
import { TableVirtuosoStyled, WrapperStyled } from "./ClientSeverityTable.styles";
import TableRow from "./components/TableRow";

interface ClientSeverityTableProps {
    data: ClientSeverityTableData;
    isEditable?: boolean;
    searchValue?: string;
    onSeverityChange?: ({ category, previousSeverity, newSeverity }: SeverityUpdateProps) => void;
}

export const ClientSeverityTable = ({ data, isEditable, searchValue, onSeverityChange }: ClientSeverityTableProps) => {
    const { t } = useTranslation();
    const { settingsRef } = useSettingsRefContext();
    const isTablet = useHasMaxWidth(Breakpoints.L);

    const renderHeader = () => {
        return (
            <Table.Tr>
                <Table.Th>
                    <Stack justifyContent="flex-start">{t("settings.clientSeverityLevel.Table.Category")}</Stack>
                </Table.Th>
                <Table.Th>
                    <Stack justifyContent="flex-start">{t("settings.clientSeverityLevel.Table.Severity")}</Stack>
                </Table.Th>
            </Table.Tr>
        );
    };

    const renderRow = (index: number) => {
        const row = data[index];
        return (
            <TableRow
                key={row.category.level3}
                category={row.category}
                severity={row.severity}
                defaultSeverity={row.defaultSeverity}
                isEditable={isEditable}
                searchValue={searchValue}
                onSeverityChange={onSeverityChange}
            />
        );
    };

    return (
        <WrapperStyled data-testid="settings-csl-severity-table">
            {data.length > 0 ? (
                <TableVirtuosoStyled
                    key={isTablet ? "tablet" : "desktop"}
                    customScrollParent={isTablet ? settingsRef?.current : undefined}
                    totalCount={data.length}
                    fixedHeaderContent={renderHeader}
                    itemContent={renderRow}
                />
            ) : (
                <EmptyResults />
            )}
        </WrapperStyled>
    );
};
