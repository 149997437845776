import { Headline, Icon, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "src/components/shared/Tooltip";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { useIsUserClientGuardingAdmin } from "src/store/clientSwitcher/ClientSwitcherHooks";

export const PageHeadline = () => {
    const { t } = useTranslation();
    const isUserClientGuardingAdmin = useIsUserClientGuardingAdmin();
    const isClientSeverityLevelEditModeEnabled = useFeatureFlag(featureFlags.clientSeverityLevelEditMode);

    const tooltipMessage = isUserClientGuardingAdmin
        ? isClientSeverityLevelEditModeEnabled
            ? t("settings.headlineTooltip.adminTextEditEnabled")
            : t("settings.headlineTooltip.adminText")
        : t("settings.headlineTooltip.otherUsersText");

    return (
        <Stack gap="XS" mb="M" pt="S" alignItems="center">
            <Headline>{t("settings.severityLevels")}</Headline>
            <Tooltip message={tooltipMessage}>
                <Icon variant="Info" data-testid="csl-headline-icon" />
            </Tooltip>
        </Stack>
    );
};
