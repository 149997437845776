import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getFieldValueByFieldName,
    hasNrsField,
} from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { ActivityLogFields, ActivityLogFieldsOld, oldFieldFallbackMap } from "../../../../models/reportTemplates/ActivityLog";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { shouldRenderActivitiesSummary, shouldRenderDescription } from "../activityLogReportConfig";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getActivityLogReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const unknownFields = getUnknownFieldsComponentsNRS({
        report,
        sharingId: sharedReportId,
        excludedCatalogIds: Object.values(ActivityLogFields),
        oldFieldFallbackMap,
        forPdf: true,
        images,
    });
    const shouldRenderUnknownFields = unknownFields.some((field) => field.props?.isRequired || field.props?.fieldValue);
    return [
        ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
        conditionalRender(shouldRenderDescription(report), () => ({
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: getFieldNameByCatalogId(ActivityLogFields.Comments, report) || "reportdetails.activities",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByCatalogId(ActivityLogFields.Comments, report, oldFieldFallbackMap),
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        })),
        conditionalRender(shouldRenderActivitiesSummary(report, shouldRenderUnknownFields), () => {
            return {
                component: SummarySectionPdf,
                props: {
                    label: "reportdetails.detailedInfo.activitiesSummary",
                },
                children: [
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(ActivityLogFields.LoggedIntoTheJournal, report) ||
                                "reportdetails.activityLog.wasTheActivityLoggedIntoTheJournal",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.LoggedIntoTheJournal, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.LoggedIntoTheJournal, report),
                        },
                    },
                    {
                        component: TextAreaSummaryFieldPdf,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(ActivityLogFields.AdditionalDescription, report) || "reportdetails.activityLog.additionalDescription",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.AdditionalDescription, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.AdditionalDescription, report),
                        },
                    },
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(ActivityLogFields.ColleagueDebrief, report) || "reportdetails.activityLog.debriefOfColleagueHappened",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.ColleagueDebrief, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.ColleagueDebrief, report),
                        },
                    },
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName: getFieldNameByCatalogId(ActivityLogFields.PassDownReport, report) || "reportdetails.activityLog.passDownReportCompleted",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.PassDownReport, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.PassDownReport, report),
                        },
                    },
                    {
                        component: TextAreaSummaryFieldPdf,
                        props: {
                            fieldName: getFieldNameByCatalogId(ActivityLogFields.OpenItems, report) || "reportdetails.activityLog.anyOpenItemsToBeAddressed",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.OpenItems, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.OpenItems, report),
                        },
                    },
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(ActivityLogFields.CommanderOnSite, report) || "reportdetails.activityLog.commanderPresenceOnSite",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.CommanderOnSite, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.CommanderOnSite, report),
                        },
                    },
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName: getFieldNameByCatalogId(ActivityLogFields.CustomerName, report) || "reportdetails.activityLog.nameOfCustomer",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.CustomerName, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.CustomerName, report),
                        },
                    },
                    // NOTE: Job and JobId fileds do not have fieldTag
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName: "reportdetails.activityLog.job",
                            fieldValue: getFieldValueByFieldName(ActivityLogFieldsOld.activityLogJob, report),
                            isRequired: false,
                        },
                    },
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName: "reportdetails.activityLog.jobId",
                            fieldValue: getFieldValueByFieldName(ActivityLogFieldsOld.activityLogJobId, report),
                            isRequired: false,
                        },
                    },
                    ...unknownFields,
                ],
            };
        }),
    ];
};
