import { difference, sum } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDataNotifier } from "src/helpers/dataStatus";
import { CategoryLevel1 } from "src/models/ReportCategoryModel";
import { UserPreference } from "src/models/UserModel";
import { useSetUserPreference, useUserPreference } from "src/store/user/UserHooks";

import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";
import { SUMMARY_EVENT_CATEGORIES } from "../shared/constants";
import { useIncidentAndDeviationCategoriesCount, useSummaryPeriod } from "../shared/hooks";
import { CategoryStats } from "./IncidentCategoriesWidget.types";

export const useIncidentCategoriesWidget = () => {
    const setUserPreference = useSetUserPreference();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const [userPreference] = useUserPreference(UserPreference.IncidentsSummaryCategories);
    const userCategories = useMemo(() => userPreference?.split(",") as CategoryLevel1[] | undefined, [userPreference]);
    const [activeCategories, setActiveCategories] = useState<CategoryLevel1[]>(userCategories ?? []);
    const { isLoading: isLoadingCurrent, queryResult: currentValues } = useIncidentAndDeviationCategoriesCount(currentPeriod.start, currentPeriod.end);
    const { isLoading: isLoadingPrevious, queryResult: oldValues } = useIncidentAndDeviationCategoriesCount(previousPeriod.start, previousPeriod.end);
    const { notifyData, notifyLoading } = useDataNotifier();
    const isLoading = useMemo(() => isLoadingCurrent || isLoadingPrevious, [isLoadingCurrent, isLoadingPrevious]);

    useEffect(() => {
        notifyLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const hasData = !!sum(Object.values({ ...currentValues, ...oldValues }));
        notifyData(hasData);
    }, [currentValues, oldValues]);

    const updateActiveCategories = useCallback(
        (categories: CategoryLevel1[]) => {
            const userCategoriesHaveChanged = difference(userCategories ?? [], categories).length || difference(categories, userCategories ?? []).length;

            setActiveCategories(categories);

            if (userCategoriesHaveChanged) {
                setUserPreference(UserPreference.IncidentsSummaryCategories, categories.join(","));
            }
        },
        [userCategories, setUserPreference],
    );

    const categoriesStats: CategoryStats[] = useMemo(() => {
        const handleClick = (categoryKey: string) => {
            navigate(`/insights/overview-events/${categoryKey}`, {
                state: {
                    startDate: currentPeriod.start,
                    endDate: currentPeriod.end,
                    originUrl: "/insights",
                    originTitle: t("insights.tabs.summary"),
                    originActiveTab: 0,
                },
            });
        };

        return SUMMARY_EVENT_CATEGORIES.filter((k) => activeCategories.includes(k))
            .map(
                (categoryKey) =>
                    ({
                        id: categoryKey,
                        icon: REPORT_CATEGORY_ICON_MAP[categoryKey],
                        value: currentValues?.[categoryKey] ?? 0,
                        previousValue: oldValues?.[categoryKey] ?? 0,
                        onClick: () => handleClick(categoryKey),
                    }) satisfies Partial<CategoryStats>,
            )
            .map(
                (stat) =>
                    ({
                        ...stat,
                        title: t(`incident.category.${stat.id}`),
                        label: t("common.event", { count: stat.value }),
                    }) satisfies CategoryStats,
            );
    }, [activeCategories, currentValues, oldValues, currentPeriod.start, currentPeriod.end, navigate, t]);

    return {
        categoriesStats,
        isLoading,
        updateActiveCategories,
        initCategories: userCategories,
    };
};
