export interface IMixpanelPageViewAdditionalProperties {
    "Has custom groups"?: boolean;
    "Is Admin User"?: boolean;
}

export interface IMixpanelPageViewProperties extends IMixpanelPageViewAdditionalProperties {
    Page: string;
    "Page Title": PageName;
}

export enum PageNames {
    Insights = "Insights Page",
    ReportList = "Report List Page",
    ReportDetails = "Report Details Page",
    SiteOverview = "Site Overview Page",
    OverviewEvents = "Overview Events Page",
    Settings = "Settings Page",
}

export type PageName = (typeof PageNames)[keyof typeof PageNames];
