import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { regionsByCountry } from "../../data/regions";
import { checkIfSiteHasMobileServices } from "../../helpers";
import { sortByField } from "../../helpers/ArrayHelper";
import { ISiteObject } from "../../models/SiteObjectModel";
import { useLazyGetUsersAtLocationQuery } from "../authorisation/authorizationApi";
import { useAuthorizedLocations } from "../locations/LocationsHooks";
import { RequestStatus } from "../RequestStatus";
import UserSelectors from "../user/UserSelectors";
import SiteObjectsSelectors from "./SiteObjectsSelectors";
import { IUserWithRoles } from "./types";

export const useUsersOfLocation = (
    locationId: string,
    roles: string[],
    forceFetch = true,
): {
    users: IUserWithRoles[];
    fetchStatus: RequestStatus;
} => {
    const [getUsersAtLocation] = useLazyGetUsersAtLocationQuery();
    const currentUser = useSelector(UserSelectors.getUserInfo);
    const usersByLocationIdState = useSelector(SiteObjectsSelectors.getSiteObjectUsers(locationId));

    useEffect(() => {
        if (forceFetch && usersByLocationIdState.status === RequestStatus.undefined) {
            getUsersAtLocation({ locationId });
        }
    }, [locationId, usersByLocationIdState.status, forceFetch, getUsersAtLocation]);

    const usersWithRolesByLocationId: IUserWithRoles[] = useMemo(() => {
        //if no users are available (still loading or an error occurred) at least the current user should be returned.
        // Otherwise, the current user will be included in the response from BE
        if (!roles?.length) {
            return usersByLocationIdState.users;
        }
        return usersByLocationIdState.users.filter((userWithRoles) => userWithRoles.roles.some((r) => roles.includes(r)));
    }, [roles, usersByLocationIdState.users]);

    return {
        users: usersWithRolesByLocationId.length ? usersWithRolesByLocationId : [currentUser.info as IUserWithRoles],
        fetchStatus: usersByLocationIdState.status,
    };
};

export const useSelectedSites = (selectedRegions: string[], implicitSelection = true): { siteIds: string[] } => {
    const { siteObjects } = useAuthorizedLocations();
    const { sitesByRegions } = useSiteObjectItemsByRegions();

    const siteIds = useMemo((): string[] => {
        if (!selectedRegions.length) {
            return implicitSelection ? siteObjects.map((l) => l.id) : [];
        }

        return selectedRegions.reduce((ids, region) => {
            if (!sitesByRegions.itemsLookup[region]?.children?.length) {
                ids.push(region);
            }

            return ids;
        }, [] as string[]);
    }, [implicitSelection, siteObjects, selectedRegions, sitesByRegions]);

    return { siteIds };
};

export type RegionItem = {
    value: string;
    label: string;
    children?: RegionItem[];
    parent?: RegionItem;
};

export const useGetSitesByRegions = (siteObjects: ISiteObject[]) => {
    const { t } = useTranslation();

    const getContinent = useCallback(
        (continentKey: string, countries: RegionItem[]): RegionItem => {
            return {
                value: continentKey,
                label: t(`region.${continentKey}`),
                children: countries,
            };
        },
        [t],
    );
    const getCountry = useCallback(
        (countryCode: string, sites: RegionItem[], parent?: RegionItem): RegionItem => {
            return {
                value: countryCode,
                label: t(`country.${countryCode}`),
                children: sites,
                parent: parent,
            };
        },
        [t],
    );
    const getSite = useCallback((siteObject: ISiteObject): RegionItem => {
        return {
            value: siteObject.id,
            label: siteObject.displayName,
        };
    }, []);

    const sitesByRegions = useMemo(() => {
        const unorderedTreeOfSites = siteObjects.reduce(
            (acc, location) => {
                const continentKey = regionsByCountry[location.countryCode];

                if (!acc.items.find((c) => c.value === continentKey)) {
                    const site = getSite(location);
                    const country = getCountry(location.countryCode, [site]);
                    const continent = getContinent(continentKey, [country]);

                    acc.items.push(continent);

                    country.parent = continent;
                    site.parent = country;
                    acc.itemsLookup[continentKey] = continent;
                    acc.itemsLookup[location.countryCode] = country;
                    acc.itemsLookup[location.id] = site;
                } else if (acc.itemsLookup[location.countryCode]) {
                    const site = getSite(location);
                    const continent = acc.items.find((c) => c.value === continentKey);
                    const country = continent?.children?.find((c) => c.value === location.countryCode);

                    country?.children?.push(site);
                    site.parent = country;
                    acc.itemsLookup[location.id] = site;
                } else {
                    const site = getSite(location);
                    const country = getCountry(location.countryCode, [site]);
                    const continent = acc.items.find((c) => c.value === continentKey);

                    continent?.children?.push(country);
                    country.parent = continent;
                    site.parent = country;

                    acc.itemsLookup[location.countryCode] = country;
                    acc.itemsLookup[location.id] = site;
                }

                return acc;
            },
            { items: [], itemsLookup: {} } as { items: RegionItem[]; itemsLookup: Record<string, RegionItem> },
        );

        unorderedTreeOfSites.items.forEach((continent) => {
            continent.children?.forEach((country) => {
                country.children?.sort(sortByField("label"));
            });
            continent.children?.sort(sortByField("label"));
        });

        unorderedTreeOfSites.items.sort(sortByField("label"));

        return unorderedTreeOfSites;
    }, [getContinent, getCountry, getSite, siteObjects]);

    return { sitesByRegions };
};

export const useSiteObjectItemsByRegions = (): {
    sitesByRegions: {
        items: RegionItem[];
        itemsLookup: Record<string, RegionItem>;
    };
} => {
    const { siteObjects } = useAuthorizedLocations();
    const { sitesByRegions } = useGetSitesByRegions(siteObjects);

    return { sitesByRegions };
};

// Probably we should place it somewhere else
// todo: incorporate in survicate traits calculation
export const useHasMobileServices = () => {
    const { siteObjects } = useAuthorizedLocations();
    const mobileSitesCount = siteObjects.reduce((mobileCounter, site) => {
        return mobileCounter + (checkIfSiteHasMobileServices(site) ? 1 : 0);
    }, 0);

    return {
        hasMobileServices: !!mobileSitesCount,
        hasAllSitesWithMobileServices: !!mobileSitesCount && mobileSitesCount === siteObjects.length,
    };
};
