import i18next from "i18next";
import { isEmpty, max } from "lodash";
import { useEffect, useMemo } from "react";
import { featureFlags } from "src/data/featureFlags";
import { useDataNotifier } from "src/helpers/dataStatus";
import { getDay } from "src/helpers/date";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { UserPreference } from "src/models/UserModel";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import { useGetTourExceptionsDailySummaryQuery } from "src/store/insights/insightsApi";
import { useLazyGetCalloutsSummaryByDayQuery } from "src/store/reports/reportsApi";

import { useDeviantChartDataType, useSummaryPeriod } from "../shared/hooks";
import { DeviantDataType, SummaryWidgetId } from "../shared/types";
import { MAX_PEAK_DAYS_COUNT, WEEKDAYS_ORDER } from "./DeviantDayWidget.constants";
import { parseToChartData } from "./DeviantDayWidget.helpers";
import { parseResult, query } from "./DeviantDayWidget.queries";

export const useDeviantDayWidget = () => {
    const { siteIds } = useFilteredSites();
    const { currentPeriod } = useSummaryPeriod();
    const {
        allowSwitch: areCalloutsEnabled,
        handleDataTypeChange,
        dataType: reportType,
    } = useDeviantChartDataType(UserPreference.DeviantDayDataType, SummaryWidgetId.DeviantDay);
    const { reportsPerDay, isLoading } = useDeviantDayData(siteIds, currentPeriod.start, currentPeriod.end, reportType, areCalloutsEnabled);
    const { notifyData, notifyLoading } = useDataNotifier();
    const maxDataValue = useMemo(() => {
        if (!reportsPerDay || isEmpty(reportsPerDay)) {
            return 0;
        }
        return max(Object.values(reportsPerDay));
    }, [reportsPerDay]);

    const chartData = useMemo(() => {
        const tooltipSecondLine =
            areCalloutsEnabled && reportType === DeviantDataType.Callouts
                ? i18next.t("common.callouts", { count: maxDataValue })
                : i18next.t("common.event", { count: maxDataValue });
        return parseToChartData(reportsPerDay, maxDataValue, tooltipSecondLine);
    }, [reportsPerDay, maxDataValue, areCalloutsEnabled, reportType]);

    useEffect(() => {
        notifyLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const hasData = !!chartData?.some((d) => d.value);
        notifyData(hasData);
    }, [chartData]);

    const shouldHighlightMaxDataValue = useMemo(
        () => chartData.filter((item) => item.value === maxDataValue).length <= MAX_PEAK_DAYS_COUNT,
        [chartData, maxDataValue],
    );

    return {
        chartData,
        isLoading,
        maxDataValue,
        shouldHighlightMaxDataValue,
        changeReportType: handleDataTypeChange,
        selectedReportType: reportType,
        areCalloutsEnabled,
    };
};

const useDeviantDayData = (siteIds: string[], startDate: Date, endDate: Date, selectedReportType: DeviantDataType, areCalloutsEnabled: boolean) => {
    const areDeviantCalloutsEnabled = useFeatureFlag(featureFlags.deviantCallouts);
    const { queryResult, isLoading: isLoadingReports } = useSqlQuery(query, parseResult, {
        siteIds,
        startDate,
        endDate,
        excludeCallouts: areDeviantCalloutsEnabled,
    });

    const { exceptionsPerDay, isLoading: isLoadingExceptions } = useExceptionsPerDay(siteIds, startDate, endDate);
    const [getCalloutsSummaryByDay, { data: calloutsSummaryByDay = [], isLoading: isCalloutsSummaryByDayLoading }] = useLazyGetCalloutsSummaryByDayQuery();

    useEffect(() => {
        if (areCalloutsEnabled) {
            getCalloutsSummaryByDay({ fromDate: startDate, toDate: endDate, siteIds });
        }
    }, [startDate, endDate, siteIds, areCalloutsEnabled, getCalloutsSummaryByDay]);

    const calloutsPerDay: Record<string, number> = useMemo(
        () =>
            calloutsSummaryByDay.reduce(
                (acc, item) => {
                    acc[item.day] = item.count;
                    return acc;
                },
                {} as Record<string, number>,
            ),
        [calloutsSummaryByDay],
    );

    const eventsPerDay: Record<string, number> = useMemo(() => {
        const result: Record<string, number> = {};
        WEEKDAYS_ORDER.forEach((weekdayNumber) => {
            const eventsCount = (queryResult?.[weekdayNumber]?.totalEvents ?? 0) + (exceptionsPerDay[weekdayNumber] ?? 0);
            const reportsCount = areCalloutsEnabled ? eventsCount + (calloutsPerDay[weekdayNumber] ?? 0) : eventsCount;
            result[weekdayNumber] = reportsCount;
        });
        return result;
    }, [queryResult, exceptionsPerDay, calloutsPerDay, areCalloutsEnabled]);

    const reportsPerDay = selectedReportType === DeviantDataType.Callouts ? calloutsPerDay : eventsPerDay;

    return {
        reportsPerDay,
        isLoading: isLoadingExceptions || isLoadingReports || isCalloutsSummaryByDayLoading,
    };
};

const useExceptionsPerDay = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { data, isLoading } = useGetTourExceptionsDailySummaryQuery({ fromDate: startDate, toDate: endDate, siteIds });

    const exceptionsPerDay = (data ?? []).reduce(
        (acc, item) => {
            const weekdayNumber = getDay(new Date(item.dateTime)).toString();
            acc[weekdayNumber] = (acc[weekdayNumber] ?? 0) + item.count;

            return acc;
        },
        {} as Record<string, number>,
    );

    return {
        isLoading,
        exceptionsPerDay,
    };
};
