import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getIsFieldPresentByCatalogId,
    hasNrsField,
    isNrsReport,
} from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { MaintenanceFields, oldFieldsCatalogIdMap } from "../../../../models/reportTemplates/Maintenance";
import { DynamicTemplateSectionPdf } from "../../../PdfReports/shared/PdfDynamicTemplateSection";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";
import { getPdfImagesCarousel } from "./imageConfigPdf";

export const getMaintenanceReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const summaryChildren = [
        {
            component: TextSummaryFieldPdf,
            props: {
                fieldName: getFieldNameByCatalogId(MaintenanceFields.MaintenancePerformed, report) ?? "reportdetails.maintenance.performingMaintenanceQuestion",
                fieldValue: getFieldValueByCatalogId(MaintenanceFields.MaintenancePerformed, report, oldFieldsCatalogIdMap),
                isRequired: !isNrsReport(report) || getIsFieldPresentByCatalogId(MaintenanceFields.MaintenancePerformed, report, oldFieldsCatalogIdMap),
                mobileDirection: "column",
                shouldAutoTranslate: true,
            },
        },
        {
            component: TextAreaSummaryFieldPdf,
            props: {
                fieldName: getFieldNameByCatalogId(MaintenanceFields.Description, report) ?? "reportdetails.maintenance.summary.description",
                fieldValue: getFieldValueByCatalogId(MaintenanceFields.Description, report, oldFieldsCatalogIdMap),
                isRequired: hasNrsField(MaintenanceFields.Description, report),
                shouldAutoTranslate: true,
            },
        },
        ...getUnknownFieldsComponentsNRS({
            report,
            excludedCatalogIds: Object.values(MaintenanceFields),
            images,
            sharingId: sharedReportId,
            forPdf: true,
            oldFieldFallbackMap: oldFieldsCatalogIdMap,
        }),
    ];

    // NOTE: Workaround temporary approach to determine if there is something inside the section.
    // However, we should not rely on component's props as its logic may change in time
    const shouldShowSummary = summaryChildren.some(({ props }) => props.isRequired !== false || !!props.value);

    return [
        ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
        conditionalRender(report.images.length > 0, () => ({
            component: DynamicTemplateSectionPdf,
            props: {
                label: "reportdetails.maintenance.description",
                marginTop: "S",
            },
            children: [getPdfImagesCarousel(images)],
        })),
        conditionalRender(shouldShowSummary, () => ({
            component: SummarySectionPdf,
            props: {
                label: "reportdetails.maintenance.summary.title",
            },
            children: summaryChildren,
        })),
    ];
};
