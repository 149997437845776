import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDataNotifier } from "src/helpers/dataStatus";

import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { useGetCalloutsSummaryQuery } from "../../../../store/reports/reportsApi";
import { useSummaryPeriod } from "../shared/hooks";

export const useCalloutResponseTimeWidget = () => {
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { siteIds } = useFilteredSites();
    const { averageResponseTime, isLoading: isLoadingCurrent } = useAverageResponseTime(siteIds, currentPeriod.start, currentPeriod.end);
    const { averageResponseTime: previousAverage, isLoading: isLoadingPrevious } = useAverageResponseTime(siteIds, previousPeriod.start, previousPeriod.end);
    const { notifyData, notifyLoading } = useDataNotifier();
    const isLoading = useMemo(() => isLoadingCurrent || isLoadingPrevious, [isLoadingCurrent, isLoadingPrevious]);

    useEffect(() => {
        notifyLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const hasData = !!(averageResponseTime || previousAverage);
        notifyData(hasData);
    }, [averageResponseTime, previousAverage]);

    const currentValue = averageResponseTime;
    const label = useMemo(() => t("common.minutes", { count: currentValue }).replace(`${currentValue}`, "").trim(), [currentValue, t]);

    return {
        currentValue,
        previousValue: previousAverage,
        label,
        isLoading,
    };
};

const useAverageResponseTime = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { data, isLoading } = useGetCalloutsSummaryQuery({
        fromDate: startDate,
        toDate: endDate,
        siteIds,
    });

    return {
        averageResponseTime: (data?.averageResponseTimeInSeconds ?? 0) && Math.round(data?.averageResponseTimeInSeconds / 60),
        isLoading,
    };
};
