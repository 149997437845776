import { Spacing, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { StyleProps } from "src/models";

import { HorizontalLine } from "../../../../components/shared/HorizontalLine";
import { useHasMobileServices } from "../../../../store/siteObjects/SiteObjectsHooks";
import { Widget } from "../shared/components";
import { SummaryValueTile } from "../shared/components/SummaryValueTile";
import { SEPARATOR_COLOR } from "../Summary.constants";
import { usePerformanceIndicatorsWidget } from "./PerformanceIndicatorsWidget.hooks";
import { GridLayoutStyled } from "./PerformanceIndicatorsWidget.styles";

type Props = StyleProps;

export const PerformanceIndicatorsWidget = ({ style }: Props) => {
    const { t } = useTranslation();
    const { indicators, isLoading } = usePerformanceIndicatorsWidget();
    const { hasMobileServices } = useHasMobileServices();

    return (
        <Widget
            data-testid="performance-indicators-widget"
            title={t("insights.summary.performanceIndicators.title")}
            hasHeaderSeparator={false}
            style={style}
            loading={isLoading}
        >
            <GridLayoutStyled childSize={hasMobileServices ? 315 : 360}>
                {indicators.map(({ value, prevValue, title }, index) => (
                    <Stack direction="column" alignItems="stretch" key={title}>
                        {!!index && <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={Spacing.M} />}
                        <SummaryValueTile
                            key={title}
                            value={value}
                            previousValue={prevValue}
                            title={title}
                            label={t("common.tours", { count: value })}
                            icon="Route"
                        />
                    </Stack>
                ))}
            </GridLayoutStyled>
        </Widget>
    );
};
