import styled from "styled-components";

import { getFieldNameByCatalogId, getFieldValueByCatalogId, hasNrsField, isNrsReport } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { ImageSizePaths } from "../../../models/ReportImagesModels";
import { DynamicReportType } from "../../../models/ReportModel";
import { oldFieldCatalogIdMap, VehicleInspectionFields } from "../../../models/reportTemplates/VehicleInspection";
import { ISiteObject } from "../../../models/SiteObjectModel";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { PicturesSection } from "../SharedComponents/Pictures";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { TextTextAreaSummaryField } from "../SharedComponents/TextTextAreaSummaryField";
import { VehicleInspectionDetailsHeader } from "../VehicleInspectionDetailsHeader/VehicleInspectionDetailsHeader";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "./DynamicTemplateUtils";

export const getVehicleInspectionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    getHeader(report, siteObject, sharedReportId),
    getVehicleInspectionDescriptionSection(report, sharedReportId),
    getIndicateTheLocationsOfDamageToTheVehicleSection(report, sharedReportId),
    getVehicleInspectionSummarySection(report, sharedReportId),
    getVehicleStatusSection(report),
];

const getHeader = (report: DynamicReportType, siteObject: ISiteObject, sharedReportId: string): DynamicComponentConfigType => {
    return {
        component: VehicleInspectionDetailsHeader,
        props: {
            report: report,
            siteObject: siteObject,
            isShared: !!sharedReportId,
        },
    };
};

const getVehicleInspectionDescriptionSection = (report: DynamicReportType, sharedReportId: string): DynamicComponentConfigType => {
    const description = getFieldValueByCatalogId(VehicleInspectionFields.Comments, report, oldFieldCatalogIdMap);
    const hasNrsComments = hasNrsField(VehicleInspectionFields.Comments, report);
    const hasImages = report.images.length;

    return conditionalRender(!!description || hasImages || hasNrsComments, () => ({
        component: DynamicTemplateSection,
        props: {
            label: getFieldNameByCatalogId(VehicleInspectionFields.Comments, report) ?? "reportdetails.vehicleInspection.vehicleInspectionDescription",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    description,
                    sharedReportId: sharedReportId,
                    imagesData: report.images,
                    shouldAutoTranslate: true,
                },
            },
        ],
    }));
};

// this is the height of the sample image we received
export const VEHICLE_IMAGE_HEIGHT_IN_PX = 191;

const StyledPicturesSection = styled(PicturesSection)`
    img {
        max-width: 100%;
    }

    max-height: ${VEHICLE_IMAGE_HEIGHT_IN_PX}px;
`;

const getIndicateTheLocationsOfDamageToTheVehicleSection = (report: DynamicReportType, sharedReportId: string): DynamicComponentConfigType => {
    const vehicleImage = getFieldValueByCatalogId(VehicleInspectionFields.LocationsOfDamage, report, oldFieldCatalogIdMap);

    return conditionalRender(vehicleImage || hasNrsField(VehicleInspectionFields.LocationsOfDamage, report), () => ({
        component: DynamicTemplateSection,
        props: {
            label:
                getFieldNameByCatalogId(VehicleInspectionFields.LocationsOfDamage, report) ??
                "reportdetails.vehicleInspection.indicateTheLocationsOfDamageToTheVehicle",
        },
        children: [
            {
                component: StyledPicturesSection,
                props: {
                    sharingId: sharedReportId,
                    imagesData: [vehicleImage],
                    imageSize: ImageSizePaths.Medium,
                },
            },
        ],
    }));
};

const getVehicleInspectionSummarySection = (report: DynamicReportType, sharedReportId: string): DynamicComponentConfigType => {
    return {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.vehicleInspection.vehicleInspectionSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },

        children: [
            ...[
                [VehicleInspectionFields.ExteriorDamage, "reportdetails.vehicleInspection.exteriorDemage"],
                [VehicleInspectionFields.InteriorDamage, "reportdetails.vehicleInspection.interiorDamage"],
                [VehicleInspectionFields.BrokenWindows, "reportdetails.vehicleInspection.brokenCrackedWindows"],
            ].map(([catId, defaultLabel]) => {
                const fieldValue = getFieldValueByCatalogId(catId, report, oldFieldCatalogIdMap);

                return {
                    component: TextSummaryField,
                    props: {
                        fieldName: getFieldNameByCatalogId(catId, report) ?? defaultLabel,
                        fieldValue,
                        mobileDirection: "column",
                        shouldAutoTranslate: true,
                        isRequired: !(!hasNrsField(catId, report) && !fieldValue && isNrsReport(report)),
                    },
                };
            }),
            {
                component: TextTextAreaSummaryField,
                props: {
                    textSummaryFieldProps: {
                        fieldName:
                            getFieldNameByCatalogId(VehicleInspectionFields.EmergencyEquipmentAvailable, report) ??
                            "reportdetails.vehicleInspection.isAllEmergencyEquipmentAvailable",
                        fieldValue: getFieldValueByCatalogId(VehicleInspectionFields.EmergencyEquipmentAvailable, report, oldFieldCatalogIdMap),
                        mobileDirection: "column",
                        isRequired: hasNrsField(VehicleInspectionFields.EmergencyEquipmentAvailable, report),
                        shouldAutoTranslate: true,
                    },
                    textAreaSummaryFieldProps: {
                        title: getFieldNameByCatalogId(VehicleInspectionFields.MissingEquipment, report) ?? "reportdetails.vehicleInspection.missingEquipment",
                        content: getFieldValueByCatalogId(VehicleInspectionFields.MissingEquipment, report, oldFieldCatalogIdMap),
                        isRequired: hasNrsField(VehicleInspectionFields.MissingEquipment, report),
                        shouldAutoTranslate: true,
                    },
                },
            },
            ...getUnknownFieldsComponentsNRS({
                report,
                sharingId: sharedReportId,
                excludedCatalogIds: Object.values(VehicleInspectionFields),
                oldFieldFallbackMap: oldFieldCatalogIdMap,
            }),
        ],
    };
};

const getVehicleStatusSection = (report: DynamicReportType): DynamicComponentConfigType => {
    return conditionalRender(shouldRenderVehicleStatusSection(report), () => ({
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.vehicleInspection.vehicleStatus",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            [VehicleInspectionFields.MileageStart, "reportdetails.vehicleInspection.mileageStart"],
            [VehicleInspectionFields.MileageEnd, "reportdetails.vehicleInspection.mileageEnd"],
            [VehicleInspectionFields.FuelLevel, "reportdetails.vehicleInspection.fuelLevel"],
            [VehicleInspectionFields.OilLevel, "reportdetails.vehicleInspection.oilLevel"],
        ].map(([catId, defaultLabel]) => ({
            component: TextSummaryField,
            props: {
                fieldName: getFieldNameByCatalogId(catId, report) ?? defaultLabel,
                fieldValue: getFieldValueByCatalogId(catId, report, oldFieldCatalogIdMap),
                mobileDirection: "column",
                isRequired: hasNrsField(catId, report),
            },
        })),
    }));
};

export const shouldRenderVehicleStatusSection = (report: DynamicReportType): boolean => {
    return [VehicleInspectionFields.MileageStart, VehicleInspectionFields.MileageEnd, VehicleInspectionFields.FuelLevel, VehicleInspectionFields.OilLevel].some(
        (catId) => !!getFieldValueByCatalogId(catId, report, oldFieldCatalogIdMap) || hasNrsField(catId, report),
    );
};
