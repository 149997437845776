import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { notNullGuard } from "../../models";
import { IUser } from "../../models/UserModel";
import { useLazyGetUsersByIdsQuery } from "../user/userApi";
import UserSelectors from "../user/UserSelectors";
import UsersSelectors from "./UsersSelectors";

export const useUsers = (
    usersIds: string[],
): {
    users: IUser[];
    isSuccess: boolean;
} => {
    const [getUsersByIds, { isError, isFetching }] = useLazyGetUsersByIdsQuery();
    const usersState = useSelector(UsersSelectors.getUsers);
    const currentUser = useSelector(UserSelectors.getUserInfo);

    const usersToFetch: string[] = useMemo(() => {
        return usersIds?.filter((id) => currentUser.info?.id !== id && !usersState.users[id]);
    }, [usersIds, usersState, currentUser]);

    useEffect(() => {
        if (usersToFetch?.length && !isFetching && currentUser.info) {
            getUsersByIds({ userIds: usersToFetch });
        }
    }, [getUsersByIds, usersToFetch, usersState, currentUser, isFetching]);

    const users: IUser[] = useMemo(() => {
        return [...Object.values(usersState.users), currentUser.info].filter(notNullGuard);
    }, [usersState, currentUser]);
    const isSuccess = !isError && !isFetching;

    return {
        users: users,
        isSuccess,
    };
};
