import { faker } from "@faker-js/faker";
import { PropsWithChildren, useCallback, useMemo, useRef, useState } from "react";

import { createContext } from "../createContext";

type DataStatusContextProps = {
    hasData: boolean | null;
    isLoading: boolean | null;
    notifyLoading: (key: string, loading: boolean) => void;
    notifyData: (key: string, hasData: boolean) => void;
};

const [DataStatusContext, useDataStatusContext] = createContext<DataStatusContextProps>({
    defaultValue: {
        hasData: null,
        isLoading: null,
        notifyLoading: () => {},
        notifyData: () => {},
    },
    providerName: "DataStatusProvider",
});

export const useDataStatus = () => {
    const { isLoading, hasData } = useDataStatusContext();

    return { isLoading, hasData };
};

export const useDataNotifier = () => {
    const { notifyLoading, notifyData } = useDataStatusContext();
    const keyRef = useRef(faker.string.uuid());
    const key = keyRef.current;

    return {
        notifyLoading: (loading: boolean) => {
            notifyLoading(key, loading);
        },
        notifyData: (hasData: boolean) => {
            notifyData(key, hasData);
        },
    };
};

export const DataStatusProvider = ({ children }: PropsWithChildren) => {
    const [loadingMap, setLoadingMap] = useState<Record<string, boolean | undefined>>({});
    const [dataMap, setDataMap] = useState<Record<string, boolean | undefined>>({});

    const isLoading = useMemo(() => getStatusFromMap(loadingMap), [loadingMap]);
    const hasData = useMemo(() => getStatusFromMap(dataMap), [dataMap]);

    const notifyLoading = useCallback(
        (key: string, isLoading: boolean) =>
            setLoadingMap((prev) => ({
                ...prev,
                [key]: isLoading,
            })),
        [],
    );
    const notifyData = useCallback(
        (key: string, hasData: boolean) =>
            setDataMap((prev) => ({
                ...prev,
                [key]: hasData,
            })),
        [],
    );

    const contextValue = useMemo(
        () => ({
            isLoading,
            hasData,
            notifyLoading,
            notifyData,
        }),
        [isLoading, hasData, notifyLoading, notifyData],
    );

    return <DataStatusContext.Provider value={contextValue}>{children}</DataStatusContext.Provider>;
};

const getStatusFromMap = (map: Record<string, boolean | undefined>): boolean | null => {
    return Object.keys(map).length ? Object.values(map).some(Boolean) : null;
};
