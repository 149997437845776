export enum MaintenanceFieldsOld {
    wereYouAbleToPerformTheMaintenance = "wereYouAbleToPerformTheMaintenance?",
    description = "description",
}

export enum MaintenanceFields {
    MaintenancePerformed = "CAT_135",
    Description = "CAT_136",
}

export const oldFieldsCatalogIdMap: Record<MaintenanceFieldsOld, MaintenanceFields> = {
    [MaintenanceFieldsOld.wereYouAbleToPerformTheMaintenance]: MaintenanceFields.MaintenancePerformed,
    [MaintenanceFieldsOld.description]: MaintenanceFields.Description,
};
