import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    hasNrsField,
    isNrsReport,
} from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { IReportImage } from "../../../../models/ReportImagesModels";
import { DynamicReportType } from "../../../../models/ReportModel";
import { oldFieldCatalogIdMap, VehicleInspectionFields } from "../../../../models/reportTemplates/VehicleInspection";
import { base64Assets } from "../../../PdfReports/Base64Assets";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { DynamicTemplateSectionPdf } from "../../../PdfReports/shared/PdfDynamicTemplateSection";
import { PdfImagesCarousel } from "../../../PdfReports/shared/PdfImageSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { PdfLogoHeader } from "../../../PdfReports/shared/PdfTemplateHeadline";
import { TextTextAreaSummaryFieldPdf } from "../../../PdfReports/shared/PdfTextTextAreaSummaryField";
import { PdfVehicleInspectionDetailsHeader } from "../../../PdfReports/VehicleInspection/PdfVehicleInspectionDetailsHeader/PdfVehicleInspectionDetailsHeader";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "../DynamicTemplateUtils";
import { shouldRenderVehicleStatusSection, VEHICLE_IMAGE_HEIGHT_IN_PX } from "../vehicleInspectionReportConfig";

export const getVehicleInspectionReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    return [
        {
            component: PdfLogoHeader,
            props: {
                logo: `data:image/png;base64,${base64Assets.securitasLogo}`,
            },
        },
        {
            component: PdfVehicleInspectionDetailsHeader,
            props: { report, siteObject, isShared: !!sharedReportId, user },
        },
        getVehicleInspectionDescriptionSection(report, images),
        getIndicateTheLocationsOfDamageToTheVehicleSection(report, images),
        getVehicleInspectionSummarySection(report, images, sharedReportId),
        getVehicleStatusSection(report),
    ];
};

const getVehicleInspectionDescriptionSection = (report: DynamicReportType, images: IReportImage[]): DynamicComponentConfigType => {
    const description = getFieldValueByCatalogId(VehicleInspectionFields.Comments, report, oldFieldCatalogIdMap);
    const hasNrsComments = hasNrsField(VehicleInspectionFields.Comments, report);
    const hasImages = images?.length;

    return conditionalRender(!!description || hasImages || hasNrsComments, () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle:
                    getFieldNameByCatalogId(VehicleInspectionFields.Comments, report) ?? "reportdetails.vehicleInspection.vehicleInspectionDescription",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description,
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        };
    });
};

const PX_TO_PT_RATIO = 72 / 96;

const getIndicateTheLocationsOfDamageToTheVehicleSection = (report: DynamicReportType, images: IReportImage[]): DynamicComponentConfigType => {
    // TODO: Adjust fetching images by catalog ID (if applicable) when we know how the data looks like
    const vehicleImage = images?.find((i) => i.reportFieldName === getFieldNameByCatalogId(VehicleInspectionFields.LocationsOfDamage, report));

    return conditionalRender(vehicleImage, () => ({
        component: DynamicTemplateSectionPdf,
        props: {
            label:
                getFieldNameByCatalogId(VehicleInspectionFields.LocationsOfDamage, report) ??
                "reportdetails.vehicleInspection.indicateTheLocationsOfDamageToTheVehicle",
            marginTop: "S",
        },
        children: [
            {
                component: PdfImagesCarousel,
                props: {
                    images: [vehicleImage],
                    imageStyle: {
                        marginRight: 4,
                        maxHeight: VEHICLE_IMAGE_HEIGHT_IN_PX * PX_TO_PT_RATIO,
                        objectFit: "contain",
                        objectPosition: "left",
                    },
                },
            },
        ],
    }));
};

const getVehicleInspectionSummarySection = (report: DynamicReportType, images: IReportImage[], sharingId?: string): DynamicComponentConfigType => {
    return {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.vehicleInspection.vehicleInspectionSummary",
        },
        children: [
            ...[
                [VehicleInspectionFields.ExteriorDamage, "reportdetails.vehicleInspection.exteriorDemage"],
                [VehicleInspectionFields.InteriorDamage, "reportdetails.vehicleInspection.interiorDamage"],
                [VehicleInspectionFields.BrokenWindows, "reportdetails.vehicleInspection.brokenCrackedWindows"],
            ].map(([catId, defaultLabel]) => {
                const fieldValue = getFieldValueByCatalogId(catId, report, oldFieldCatalogIdMap);

                return {
                    component: TextSummaryFieldPdf,
                    props: {
                        fieldName: getFieldNameByCatalogId(catId, report) ?? defaultLabel,
                        fieldValue,
                        shouldAutoTranslate: true,
                        isRequired: !(!hasNrsField(catId, report) && !fieldValue && isNrsReport(report)),
                    },
                };
            }),
            getEquipmentTextTextAreaSummaryField(report),
            ...getUnknownFieldsComponentsNRS({
                report,
                excludedCatalogIds: Object.values(VehicleInspectionFields),
                oldFieldFallbackMap: oldFieldCatalogIdMap,
                sharingId,
                images,
                forPdf: true,
            }),
        ],
    };
};

const getEquipmentTextTextAreaSummaryField = (report: DynamicReportType): DynamicComponentConfigType => {
    const emergencyEquipmentValue = getFieldValueByCatalogId(VehicleInspectionFields.EmergencyEquipmentAvailable, report, oldFieldCatalogIdMap);
    const missingEquipmentValue = getFieldValueByCatalogId(VehicleInspectionFields.MissingEquipment, report, oldFieldCatalogIdMap);

    const shouldPrepareValue =
        !!emergencyEquipmentValue ||
        missingEquipmentValue ||
        hasNrsField(VehicleInspectionFields.EmergencyEquipmentAvailable, report) ||
        hasNrsField(VehicleInspectionFields.MissingEquipment, report);

    const value = shouldPrepareValue
        ? {
              textSummaryFieldProps: {
                  fieldName:
                      getFieldNameByCatalogId(VehicleInspectionFields.EmergencyEquipmentAvailable, report) ??
                      "reportdetails.vehicleInspection.isAllEmergencyEquipmentAvailable",
                  fieldValue: emergencyEquipmentValue,
                  isRequired: hasNrsField(VehicleInspectionFields.EmergencyEquipmentAvailable, report),
                  shouldAutoTranslate: true,
              },
              textAreaSummaryFieldProps: {
                  fieldName: getFieldNameByCatalogId(VehicleInspectionFields.MissingEquipment, report) ?? "reportdetails.vehicleInspection.missingEquipment",
                  fieldValue: missingEquipmentValue,
                  isRequired: hasNrsField(VehicleInspectionFields.MissingEquipment, report),
                  shouldAutoTranslate: true,
              },
          }
        : null;

    return {
        component: TextTextAreaSummaryFieldPdf,
        props: {
            isRequired: false,
            fieldValue: value,
        },
    };
};

const getVehicleStatusSection = (report: DynamicReportType): DynamicComponentConfigType => {
    return conditionalRender(shouldRenderVehicleStatusSection(report), () => ({
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.vehicleInspection.vehicleStatus",
        },
        children: [
            [VehicleInspectionFields.MileageStart, "reportdetails.vehicleInspection.mileageStart"],
            [VehicleInspectionFields.MileageEnd, "reportdetails.vehicleInspection.mileageEnd"],
            [VehicleInspectionFields.FuelLevel, "reportdetails.vehicleInspection.fuelLevel"],
            [VehicleInspectionFields.OilLevel, "reportdetails.vehicleInspection.oilLevel"],
        ].map(([catId, defaultLabel]) => ({
            component: TextSummaryFieldPdf,
            props: {
                fieldName: getFieldNameByCatalogId(catId, report) ?? defaultLabel,
                fieldValue: getFieldValueByCatalogId(catId, report, oldFieldCatalogIdMap),
                isRequired: hasNrsField(catId, report),
            },
        })),
    }));
};
