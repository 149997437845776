import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDataNotifier } from "src/helpers/dataStatus";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import { useGetCalloutsSummaryQuery } from "src/store/reports/reportsApi";

import { useSummaryPeriod } from "../shared/hooks";

export const useCalloutsPerformanceWidget = () => {
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { siteIds } = useFilteredSites();

    const { data: calloutsSummary, isLoading: isLoadingCurrent } = useCalloutsSummary(siteIds, currentPeriod.start, currentPeriod.end);
    const { data: previousCalloutsSummary, isLoading: isLoadingPrevious } = useCalloutsSummary(siteIds, previousPeriod.start, previousPeriod.end);
    const { notifyData, notifyLoading } = useDataNotifier();
    const isLoading = useMemo(() => isLoadingCurrent || isLoadingPrevious, [isLoadingCurrent, isLoadingPrevious]);

    useEffect(() => {
        notifyLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const hasData = !!(calloutsSummary?.calloutsCount || previousCalloutsSummary?.calloutsCount);
        notifyData(hasData);
    }, [calloutsSummary, previousCalloutsSummary]);

    const indicators = [
        {
            title: t("insights.summary.calloutsPerformanceWidget.callouts"),
            value: calloutsSummary?.calloutsCount,
            prevValue: previousCalloutsSummary?.calloutsCount,
            tooltip: t("insights.summary.calloutsPerformanceWidget.calloutsTooltip"),
        },
        {
            title: t("insights.summary.calloutsPerformanceWidget.calloutsPolice"),
            value: calloutsSummary?.policeInvolvedCount,
            prevValue: previousCalloutsSummary?.policeInvolvedCount,
        },
        {
            title: t("insights.summary.calloutsPerformanceWidget.canceledCallouts"),
            value: calloutsSummary?.cancelledCount,
            prevValue: previousCalloutsSummary?.cancelledCount,
        },
    ];

    return {
        indicators,
        isLoading,
    };
};

const useCalloutsSummary = (siteIds: string[], fromDate: Date, toDate: Date) => {
    const { data, isLoading } = useGetCalloutsSummaryQuery({
        fromDate,
        toDate,
        siteIds,
    });

    const output = useMemo(
        () => ({
            calloutsCount: data ? data.count - data.cancelledCount : 0,
            policeInvolvedCount: data?.policeInvolvedCount ?? 0,
            cancelledCount: data?.cancelledCount ?? 0,
        }),
        [data],
    );

    return { data: output, isLoading };
};
