import { EvaluationContext, OpenFeature } from "@openfeature/web-sdk";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { featureFlags } from "../data/featureFlags";
import FeatureFlagsSelectors from "../store/featureFlags/FeatureFlagsSelectors";

export const useFeatureFlag = (featureName: (typeof featureFlags)[keyof typeof featureFlags]): boolean => {
    return useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureName));
};

export const useSetFeatureFlagContextAsync = (context: EvaluationContext) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        OpenFeature.setContext(context)
            .catch((error) => {
                console.error(error);
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [context]);

    return { isLoading, isError };
};
