import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/api/baseQuery";

import { parseDateRangeRequestBody } from "../parseDateRangeRequestBody";
import {
    CalloutsByHourResponse,
    CommonInsightsParams,
    DailyExceptionsSummaryResponse,
    GetTourExceptionsDailySummaryParams,
    GetTourExceptionsHourlySummaryParams,
    TourExceptionsHourlySummaryResponse,
} from "./types";

export const insightsApi = createApi({
    reducerPath: "insightsApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getTourExceptionsHourlySummary: builder.query<TourExceptionsHourlySummaryResponse, GetTourExceptionsHourlySummaryParams>({
            query: ({ fromDate, toDate, groupHours = false, ...payload }) => ({
                url: "/v2/global/insights/tour-exc/summary/hourly",
                method: "POST",
                body: parseDateRangeRequestBody({ fromDateTime: fromDate, toDateTime: toDate, groupHours, ...payload }),
            }),
        }),
        getTourExceptionsDailySummary: builder.query<DailyExceptionsSummaryResponse, GetTourExceptionsDailySummaryParams>({
            query: ({ fromDate, toDate, siteIds }) => ({
                url: "/v2/global/insights/tour-exc/summary/daily",
                method: "POST",
                body: parseDateRangeRequestBody({ fromDateTime: fromDate, toDateTime: toDate, siteIds }),
            }),
        }),
        getCalloutsByHour: builder.query<CalloutsByHourResponse, CommonInsightsParams>({
            query: ({ fromDate, toDate, ...payload }) => ({
                url: "/v2/global/insights/reports/callouts/by-hour",
                method: "POST",
                body: parseDateRangeRequestBody({ fromDateTime: fromDate, toDateTime: toDate, ...payload }),
            }),
        }),
    }),
});

export const {
    useGetTourExceptionsHourlySummaryQuery,
    useLazyGetTourExceptionsHourlySummaryQuery,
    useGetCalloutsByHourQuery,
    useLazyGetCalloutsByHourQuery,
    useGetTourExceptionsDailySummaryQuery,
} = insightsApi;
