import { Dictionary } from "@reduxjs/toolkit";

import { RawDateString } from "../../../../../helpers/date";
import { useApiRequest } from "../../../../../hooks/accessApi";
import { CategoryLevel1, CategoryLevel1Keys } from "../../../../../models/ReportCategoryModel";
import { SUMMARY_EVENT_CATEGORIES } from "../constants";
import { parseDateRangeRequestBody } from "../../../../../store/parseDateRangeRequestBody";

type DeviationSiteSummaryItem = {
    locationId: string;
    category: CategoryLevel1 | null;
    date: RawDateString;
    count: number;
};

const DEFAULT_CATEGORIES = [...SUMMARY_EVENT_CATEGORIES, CategoryLevel1Keys.internalSecuritas];

export const useDeviationsSiteSummary = (siteIds: string[], startDate: Date, endDate: Date, categoriesFilter = DEFAULT_CATEGORIES) => {
    const { data, isLoading } = useApiRequest<DeviationSiteSummaryItem[]>(
        `/v2/global/insights/tour-exc/location-summary/daily`,
        "POST",
        parseDateRangeRequestBody({
            siteIds,
            fromDateTime: startDate,
            toDateTime: endDate,
            categories: categoriesFilter.map((category) => ({ category, level: 1 })),
        }),
    );

    return { sitesDeviationsCountMap: parseData(data), isLoading };
};

const parseData = (data: DeviationSiteSummaryItem[] | undefined) => {
    const output: Dictionary<Partial<Record<CategoryLevel1, number>>> = {};

    return (data ?? []).reduce((sitesMap, stat) => {
        if (stat.category) {
            const siteInfo = sitesMap[stat.locationId] ?? {};

            siteInfo[stat.category] = (siteInfo[stat.category] ?? 0) + stat.count;
            sitesMap[stat.locationId] = siteInfo;
        }

        return sitesMap;
    }, output);
};
