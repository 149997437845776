import { keyBy } from "lodash";
import { useRequestTourSessionsQuery } from "src/store/tourSessions/tourSessionsApi";

export const useTourSessionsPerSite = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { data: tourData, isLoading } = useRequestTourSessionsQuery({
        body: {
            siteIds,
            fromDateTime: startDate,
            toDateTime: endDate,
        },
    });
    const tourSessionsMap = keyBy(tourData, "locationId");

    return { tourSessionsMap, isLoading };
};
