import { useCallback } from "react";
import { UserPreference } from "src/models/UserModel";
import { useSetUserPreference } from "src/store/user/UserHooks";

import { CustomizedCategories, CustomizedCategory } from "../TrendingEvents.types";

export const useTotalToggle = (
    selectedCategories: CustomizedCategories,
    isTotalEnabled: boolean,
    setIsTotalEnabled: (value: boolean | ((prev: boolean) => boolean)) => void,
) => {
    const setUserPreference = useSetUserPreference();

    const handleTotalToggleChange = useCallback(() => {
        const level1Categories: CustomizedCategory[] = selectedCategories?.level1Categories?.map((c) => ({
            value: c?.value,
        }));

        const level2Categories: CustomizedCategory[] = selectedCategories?.level2Categories?.map((c) => ({
            value: c?.value,
            parent: c?.parent.value,
        }));

        const level3Categories: CustomizedCategory[] = selectedCategories?.level3Categories?.map((c) => ({
            value: c?.value,
            parent: c?.parent.value,
            root: c?.parent.parent.value,
        }));

        setUserPreference(
            UserPreference.Top5IncidentsCategories,
            JSON.stringify({
                level1Categories: level1Categories ?? [],
                level2Categories: level2Categories ?? [],
                level3Categories: level3Categories ?? [],
                showTotal: !isTotalEnabled,
            }),
        );

        setIsTotalEnabled((prev) => !prev);
    }, [selectedCategories, isTotalEnabled, setUserPreference, setIsTotalEnabled]);

    return { handleTotalToggleChange };
};
