import type { SummarySettings } from "./Summary.types";

export const serializeSettings = <T = SummarySettings>(settings: T): string => {
    return JSON.stringify(settings);
};

export const deserializeSettings = <T = SummarySettings>(data?: string): T | undefined => {
    if (!data) {
        return undefined;
    }

    try {
        return JSON.parse(data) as T;
    } catch (e) {
        console.error("Failed to parse settings", e);
    }
};
