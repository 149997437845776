import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapFeatureFlags = ({ featureFlags: { flags: featureFlags } }: IState) => featureFlags;

const selectFeatureFlag = createSelector(
    mapFeatureFlags,
    (_, flag: string) => flag,
    (featureFlags: Record<string, boolean>, flag: string) => !!featureFlags[flag],
);

const mapfeatureFlagsStatus = ({ featureFlags: { status } }: IState) => status;

const selectFeatureFlagsStatus = createSelector(mapfeatureFlagsStatus, (status) => status);

export default {
    selectFeatureFlag,
    selectFeatureFlagsStatus,
    selectFeatureFlags: mapFeatureFlags,
};
