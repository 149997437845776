import { Badge, Box, Breakpoints, Color, Icon, Palette, SignalColor, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "src/helpers/date";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";

import { getSeverityLevelTranslationKey } from "../../../helpers/SeverityLevelHelper";
import { HighlightsType } from "../../../models";
import { IHighlightsCard } from "../../../store/insights/InsightsModel";
import LocationsSelector from "../../../store/locations/LocationsSelectors";
import { getSeverityColor } from "../../Reports/SharedComponents/Badges";
import { CardDescriptionText, CardFooter, CriticalCardWrapper, LinkContainer, VerticalLine } from "./Cards.styled";

interface HighlightsCardProps {
    card: IHighlightsCard;
    highlightsType: HighlightsType;
    index: number;
    onHoverCard?: (card: IHighlightsCard) => void;
}

export const HighlightsCard = ({ card, highlightsType, index, onHoverCard }: HighlightsCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const siteObject = useSelector(LocationsSelector.getAuthorizedLocationById(card.siteId));

    const onClickHandler = () => {
        navigate(`/locations/${card.siteId}/reports/${card.id}`);
    };

    const onMouseEnter = useCallback(() => {
        if (!isMobile) {
            onHoverCard(card);
        }
    }, [onHoverCard, card, isMobile]);

    const onMouseLeave = useCallback(() => {
        if (!isMobile) {
            onHoverCard(null);
        }
    }, [onHoverCard, isMobile]);

    const handleKeyDown = useOnEnterSpaceKeyDown(onClickHandler);

    const headerTextColor: Color | SignalColor = card.isRead ? "secondary" : "primary";
    const contentTextColor: Color | SignalColor = card.isRead ? "tertiary" : "primary";

    return (
        <CriticalCardWrapper
            data-testid="critical-card-wrapper"
            direction="column"
            key={highlightsType + index}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isRead={card.isRead}
        >
            <Stack flex="1" direction="column">
                <Stack justifyContent="space-between" marginBottom="XXS" alignItems="center">
                    <Text micro bold uppercase truncate>
                        {highlightsType === "prevention_categories" && t("insights.highlights.preventionCardTitle")}
                        {highlightsType === "critical_categories" && t("insights.highlights.criticalCardTitle")}
                    </Text>
                    <Icon variant={highlightsType === "critical_categories" ? "DowntimeUnplanned" : "Armed"} size="S" />
                </Stack>
                <Stack justifyContent="space-between">
                    <Badge
                        mode="outlined"
                        data-testid="card-severity-level"
                        label={t(`${getSeverityLevelTranslationKey(card.severity)}`)}
                        color={getSeverityColor(card.severity)}
                    />
                    <Text data-testid="format-date" color={headerTextColor} micro bold uppercase>
                        {formatDate(card.reportDate)}
                    </Text>
                </Stack>
            </Stack>
            <Box marginBottom="XS">
                <Text data-testid="incident-category" color={headerTextColor} truncate bold small>
                    {t(`incident.category.${card.categoryLevel1}`)}
                </Text>
            </Box>
            <LinkContainer direction="column" justifyContent="flex-end" onKeyDown={handleKeyDown} onClick={onClickHandler} tabIndex={0} role="link">
                <Stack>
                    <VerticalLine color={card.isRead ? Palette.Navy300 : Palette.Gray100} />
                    <CardDescriptionText data-testid="card-description-text" isRead={card.isRead}>
                        <Text color={contentTextColor} small bold>
                            {t(`incident.category.${card.categoryLevel2}`)}{" "}
                        </Text>
                        <Text color={contentTextColor} small>
                            {t("common.with")}{" "}
                        </Text>
                        <Text color={contentTextColor} small bold>
                            {t(`incident.category.${card.categoryLevel3}`)}{" "}
                        </Text>
                        <Text color={contentTextColor} small>
                            {t("common.at").toLowerCase()}{" "}
                        </Text>
                        <Text color={contentTextColor} small bold>
                            {siteObject?.displayName}{" "}
                        </Text>
                    </CardDescriptionText>
                </Stack>
                <CardFooter>
                    <Text small bold>
                        {t("insights.highlights.viewFullReport")}
                    </Text>
                    <Icon variant="ArrowForward" size="S" />
                </CardFooter>
            </LinkContainer>
        </CriticalCardWrapper>
    );
};
