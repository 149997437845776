import { ISiteObject } from "../../models/SiteObjectModel";
import { IUser } from "../../models/UserModel";
import { RespondentTraits } from "./types";
import { checkIfSiteHasMobileServices } from "../index";

export const getSurvicateTraits = (user: IUser, sites: ISiteObject[]): RespondentTraits => {
    const mobileSitesCount = sites.reduce((mobileCounter, site) => {
        return mobileCounter + (checkIfSiteHasMobileServices(site) ? 1 : 0);
    }, 0);

    return {
        created_at: user.createdAt,
        has_mobile_services: !!mobileSitesCount,
        all_sites_with_mobile: !!mobileSitesCount && mobileSitesCount === sites.length,
        client_type: sites?.[0]?.clientType?.toLowerCase(),
    };
};
