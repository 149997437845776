import { Headline, Spacing } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";
import { getGraphMaxValue } from "src/helpers/graphs";
import { QaProps } from "src/models";

import { Graph, Widget } from "../shared/components";
import { DEVIANT_DATA_TYPE_OPTIONS } from "../shared/constants";
import { TOOLTIPS_MAP } from "./DeviantDayWidget.constants";
import { useDeviantDayWidget } from "./DeviantDayWidget.hooks";

type Props = QaProps;

export const DeviantDayWidget = (props: Props) => {
    const { t } = useTranslation();
    const { chartData, isLoading, maxDataValue, shouldHighlightMaxDataValue, changeReportType, selectedReportType, areCalloutsEnabled } = useDeviantDayWidget();
    const graphMaxValue = getGraphMaxValue(maxDataValue);
    const extraInfo = useMemo(() => {
        if (!chartData.length) {
            return null;
        }
        const maxValues = chartData.filter((d) => d.value === maxDataValue);
        // display additional info only when 1 data item is peak
        if (maxValues.length !== 1) {
            return null;
        }
        return maxValues[0].label;
    }, [chartData, maxDataValue]);

    return (
        <Widget
            {...props}
            data-testid="deviant-day-widget"
            loading={isLoading}
            empty={!isLoading && maxDataValue === 0}
            title={t("insights.summary.deviantDay")}
            tooltip={t(TOOLTIPS_MAP[selectedReportType])}
            headerActions={areCalloutsEnabled && <Switcher value={selectedReportType} options={DEVIANT_DATA_TYPE_OPTIONS} onChange={changeReportType} />}
            subtitle={
                <Headline bold color="secondary">
                    {extraInfo}
                </Headline>
            }
        >
            <Graph
                style={{ marginTop: Spacing.M }}
                tickCount={3}
                highlightedValue={shouldHighlightMaxDataValue ? maxDataValue : null}
                data={chartData}
                maxValue={graphMaxValue}
                barSize="sm"
            />
        </Widget>
    );
};
