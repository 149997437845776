import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RequestStatus } from "../RequestStatus";
import { TOUR_SESSIONS_INITIAL_STATE } from "./constants";
import { TourHistoricalCompareSummary } from "./types";

export const tourSessionsSlice = createSlice({
    name: "tourSessions",
    initialState: TOUR_SESSIONS_INITIAL_STATE,
    reducers: {
        requestLocationTourSessions: (state) => {
            state.queryStatus = RequestStatus.loading;
        },
        locationTourSessionsSuccess: (state, action: PayloadAction<{ key: string; result: TourHistoricalCompareSummary[] }>) => {
            state.queryStatus = RequestStatus.success;
            state.queryKey = action.payload.key;
            state.tours = action.payload.result;
        },
    },
});
