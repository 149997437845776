import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getIsFieldPresentByCatalogId,
    isNrsReport,
} from "src/helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "src/models/DynamicTemplate";
import { DynamicReportType } from "src/models/ReportModel";
import { newFieldCatalogIdMap, oldFieldCatalogIdMap, SiteInspectionCatalogIds } from "src/models/reportTemplates/SiteInspection";

import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { ImageField } from "../SharedComponents/ImageField";
import { PicturesSection } from "../SharedComponents/Pictures";
import { TextAreaSection } from "../SharedComponents/TextArea";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "./DynamicTemplateUtils";

export const getSiteInspectionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const isNrs = isNrsReport(report);

    const getShouldRender = (catalogId: string) => {
        return isNrs
            ? getIsFieldPresentByCatalogId(catalogId, report, oldFieldCatalogIdMap)
            : getFieldValueByCatalogId(catalogId, report, oldFieldCatalogIdMap);
    };

    return [
        ...getBaseReportConfig({ report, siteObject, sharedReportId }),
        conditionalRender(getShouldRender(SiteInspectionCatalogIds.ObservationsByTheInspector), () => ({
            component: DynamicTemplateSection,
            props: {
                label:
                    getFieldNameByCatalogId(SiteInspectionCatalogIds.ObservationsByTheInspector, report) ??
                    "reportdetails.siteInspection.inspectorObservations",
                fullyTranslated: true,
            },
            children: [
                {
                    component: TextAreaSection,
                    props: {
                        content: getFieldValueByCatalogId(SiteInspectionCatalogIds.ObservationsByTheInspector, report, oldFieldCatalogIdMap),
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        conditionalRender(getShouldRender(SiteInspectionCatalogIds.ActionTakenByTheTnspector), () => ({
            component: DynamicTemplateSection,
            props: {
                label: getFieldNameByCatalogId(SiteInspectionCatalogIds.ActionTakenByTheTnspector, report) ?? "reportdetails.siteInspection.actionTaken",
                fullyTranslated: true,
            },
            children: [
                {
                    component: TextAreaSection,
                    props: {
                        content: getFieldValueByCatalogId(SiteInspectionCatalogIds.ActionTakenByTheTnspector, report, oldFieldCatalogIdMap),
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        conditionalRender(report.images.length > 0, () => ({
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.pictures",
            },
            children: [
                {
                    component: PicturesSection,
                    props: {
                        sharingId: sharedReportId,
                        imagesData: report.images,
                    },
                },
            ],
        })),
        {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.siteInspection.summary.title",
                collapsedLabel: "reportdetails.summary.moreDetails",
                expandedLabel: "reportdetails.summary.lessDetails",
            },
            children: [
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.OfficerUniformsAndAppearance, report, oldFieldCatalogIdMap),
                    () => [
                        {
                            component: TextSummaryField,
                            props: {
                                fieldName:
                                    getFieldNameByCatalogId(SiteInspectionCatalogIds.OfficerUniformsAndAppearance, report) ??
                                    "reportdetails.siteInspection.summary.officersUniformsAndAppearance",
                                fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.OfficerUniformsAndAppearance, report, oldFieldCatalogIdMap),
                                mobileDirection: "column",
                                shouldAutoTranslate: true,
                            },
                        },
                    ],
                ),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.RegulatoryCompliant, report, oldFieldCatalogIdMap), () => [
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(SiteInspectionCatalogIds.RegulatoryCompliant, report) ??
                                "reportdetails.siteInspection.summary.regulatoryCompliant",
                            fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.RegulatoryCompliant, report, oldFieldCatalogIdMap),
                            mobileDirection: "column",
                            shouldAutoTranslate: true,
                        },
                    },
                ]),
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.OfficerHasReadAndAcknowledgedPostOrders, report, oldFieldCatalogIdMap),
                    () => [
                        {
                            component: TextSummaryField,
                            props: {
                                fieldName:
                                    getFieldNameByCatalogId(SiteInspectionCatalogIds.OfficerHasReadAndAcknowledgedPostOrders, report) ??
                                    "reportdetails.siteInspection.summary.postOrderAcknowledgment",
                                fieldValue: getFieldValueByCatalogId(
                                    SiteInspectionCatalogIds.OfficerHasReadAndAcknowledgedPostOrders,
                                    report,
                                    oldFieldCatalogIdMap,
                                ),
                                mobileDirection: "column",
                                shouldAutoTranslate: true,
                            },
                        },
                    ],
                ),
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.LastRevisionDateOfPostOrders, report, oldFieldCatalogIdMap),
                    () => [
                        {
                            component: TextSummaryField,
                            props: {
                                fieldName:
                                    getFieldNameByCatalogId(SiteInspectionCatalogIds.LastRevisionDateOfPostOrders, report) ??
                                    "reportdetails.siteInspection.summary.postOrdersLastRevisionDate",
                                fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.LastRevisionDateOfPostOrders, report, oldFieldCatalogIdMap),
                                mobileDirection: "column",
                                shouldAutoTranslate: true,
                            },
                        },
                    ],
                ),
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.InspectionTimeAndDate, report, oldFieldCatalogIdMap),
                    () => [
                        {
                            component: TextSummaryField,
                            props: {
                                fieldName:
                                    getFieldNameByCatalogId(SiteInspectionCatalogIds.InspectionTimeAndDate, report) ??
                                    "reportdetails.siteInspection.summary.inspectionDateTime",
                                fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.InspectionTimeAndDate, report, oldFieldCatalogIdMap),
                                mobileDirection: "column",
                                shouldAutoTranslate: true,
                            },
                        },
                    ],
                ),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.OfficerSignature, report, oldFieldCatalogIdMap), () => {
                    const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.OfficerSignature, report);
                    return [
                        {
                            component: ImageField,
                            props: {
                                fieldName: fieldNameByCatalogId ?? "reportdetails.fields.officersSignature",
                                fieldValue: report.properties.find(
                                    (p) => p.reportFieldName === (fieldNameByCatalogId ?? newFieldCatalogIdMap[SiteInspectionCatalogIds.OfficerSignature]),
                                )?.value,
                                sharingId: sharedReportId,
                            },
                        },
                    ];
                }),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.InspectorSignature, report, oldFieldCatalogIdMap), () => {
                    const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.InspectorSignature, report);
                    return [
                        {
                            component: ImageField,
                            props: {
                                fieldName: fieldNameByCatalogId ?? "reportdetails.fields.inspectorsSignature",
                                fieldValue: report.properties.find(
                                    (p) => p.reportFieldName === (fieldNameByCatalogId ?? newFieldCatalogIdMap[SiteInspectionCatalogIds.InspectorSignature]),
                                )?.value,
                                sharingId: sharedReportId,
                            },
                        },
                    ];
                }),
                ...getUnknownFieldsComponentsNRS({
                    report,
                    sharingId: sharedReportId,
                    excludedCatalogIds: Object.values(SiteInspectionCatalogIds),
                    oldFieldFallbackMap: oldFieldCatalogIdMap,
                }),
            ],
        },
    ];
};
