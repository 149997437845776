import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { featureFlags } from "../../../data/featureFlags";
import { endOfDay, formatToRawDate, startOfDay, subDays, subMonths } from "../../../helpers/date";
import { useFetchApi } from "../../../hooks/accessApi";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import { ISearchReportsRequestBody } from "../../../models/ReportFilterModels";
import { ReportTemplateEnum } from "../../../models/ReportModel";
import { IState } from "../../../Store";
import AccessSelectors from "../../../store/access/AccessSelectors";
import InsightsActions from "../../../store/insights/InsightsActions";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";

export const useIsSummaryMainTab = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const isOpeningInsights = ["/", "/insights"].includes(pathname);
    const isClientVerified = useSelector(({ insights }: IState) => insights.summaryState.isClientVerified);
    const clientSites = useSelector(LocationsSelectors.getSiteObjects);
    const isInsightsApiEnabled = useFeatureFlag(featureFlags.insightsReportSearch);

    const userId = useSelector(AccessSelectors.getUserId);
    const fetch = useFetchApi();

    const dateParams: Pick<ISearchReportsRequestBody, "from" | "to"> = useMemo(() => {
        const today = new Date();
        const threeMonthsAgo = startOfDay(subMonths(today, 3));
        const yesterday = endOfDay(subDays(today, 1));

        return {
            from: formatToRawDate(threeMonthsAgo),
            to: formatToRawDate(yesterday),
        };
    }, []);

    useEffect(() => {
        const checkClient = async () => {
            const urlPrefix = isInsightsApiEnabled ? "/v2/global/insights/search" : "/v2/global/reports/search";
            // size=1 is used to check if there are any reports for the client
            const response = await fetch<ISearchReportsRequestBody>(`${urlPrefix}?size=1`, "POST", {
                userId,
                locationIds: clientSites.map(({ id }) => id),
                excludedReportTypes: [ReportTemplateEnum.tourException, ReportTemplateEnum.tourMultiException],
                ...dateParams,
            });
            const clientData = (await response.json()) as { totalCount: number };

            if (clientData.totalCount === 0) {
                dispatch(InsightsActions.setSelectedTab(1));
            }

            dispatch(InsightsActions.confirmSummaryClientVerification(clientData.totalCount > 0));
        };

        if (clientSites.length && isOpeningInsights && !isClientVerified) {
            checkClient();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientSites, dateParams, isInsightsApiEnabled]);
};
