import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Breakpoints, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { PageWrapper } from "src/components/shared/PageWrapper";
import { useSetInsightFiltersByParams } from "src/store/insights/InsightHooks";
import { useTrackPageVisit } from "src/store/tracking/TrackingHooks";

import { SwitcherBanner } from "../../components/ClientSwitcher/SwitcherBanner";
import { InsightsDateFilter } from "../../components/Insights/Filter/InsightsDateFilter";
import { InsightsFilter } from "../../components/Insights/Filter/InsightsFilter";
import { SummaryChips } from "../../components/Insights/Filter/SummaryChips";
import { ExpandablePanel } from "../../components/shared/filters/FilterToolbar.styles";
import { PageNames } from "../../models/tracking/pageView";
import { useDisplaySwitcherBanner } from "../../store/clientSwitcher/ClientSwitcherHooks";
import { useFiltersCount } from "../../store/insights/FilterHooks";
import { InsightsWrapper, MobileFilterIconWrapper, PageHeaderWrapper, SwitcherBannerWrapper, TitleWrapper } from "./Common.styled";
import { useInsightsPageContext } from "./InsightsPageProvider";
import { InsightsTabs } from "./InsightsTabs";
import { InsightsFilterIcon } from "./shared/components/InsightsFilterIcon";

export const Insights = () => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isBannerVisible = useDisplaySwitcherBanner();
    const filtersCount = useFiltersCount();
    const { isMobileLayout, isFilterActive, setIsFilterActive } = useInsightsPageContext();

    useTrackPageVisit(PageNames.Insights);
    useSetInsightFiltersByParams();

    const closeFilter = () => setIsFilterActive(false);

    return (
        <PageWrapper>
            {!isMobile && !isFilterActive && <InsightsFilterIcon />}
            {isFilterActive &&
                (isMobile ? (
                    <ExpandablePanel>
                        <InsightsFilter onClose={closeFilter} />
                    </ExpandablePanel>
                ) : (
                    <InsightsFilter onClose={closeFilter} />
                ))}
            <InsightsWrapper data-testid="insigths-wrapper" direction="column">
                {isBannerVisible && (
                    <SwitcherBannerWrapper isFilterActive={isFilterActive}>
                        <SwitcherBanner isMobileLayout={isMobileLayout} />
                    </SwitcherBannerWrapper>
                )}
                <PageHeaderWrapper isFilterActive={isFilterActive}>
                    <TitleWrapper>
                        <Title data-testid="insights-title">{t("insights.title")}</Title>
                        {!isMobileLayout && <InsightsDateFilter onCalendarOpen={closeFilter} />}
                        {isMobile && !isFilterActive && (
                            <MobileFilterIconWrapper>
                                <InsightsFilterIcon />
                            </MobileFilterIconWrapper>
                        )}
                    </TitleWrapper>
                    {isMobileLayout && <InsightsDateFilter onCalendarOpen={closeFilter} />}
                    <SummaryChips sitesCount={filtersCount} />
                </PageHeaderWrapper>
                <InsightsTabs />
            </InsightsWrapper>
        </PageWrapper>
    );
};
