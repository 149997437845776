import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { getSeverityLevelTranslationKey } from "src/helpers/SeverityLevelHelper";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { useIsUserClientGuardingAdmin } from "src/store/clientSwitcher/ClientSwitcherHooks";
import { SeverityLevel } from "src/store/organization/types";
import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";
import { IReportCategoryLevel1 } from "src/store/reportCategories/types";
import { RequestStatus } from "src/store/RequestStatus";

import { CATEGORY_ORDER } from "./ClientSeverityLevel.constants";
import { ClientSeverityTableData, ClientSeverityTableRowData } from "./ClientSeverityLevel.types";
import { useClientSeverityLevelData } from "./hooks";

export const useClientSeverityLevel = () => {
    const { t } = useTranslation();
    const { reportCategories, fetchStatus } = useReportCategories();
    const [searchValue, setSearchValue] = useState("");
    const [searchTags, setSearchTags] = useState<string[]>([]);
    const [allCategories, setAllCategories] = useState<ClientSeverityTableData>([]);
    const [searchResults, setSearchResults] = useState<ClientSeverityTableData>([]);
    const isEditModeEnabled = useFeatureFlag(featureFlags.clientSeverityLevelEditMode);

    const { severityData, updateSeverityLevel } = useClientSeverityLevelData();

    const sortReportCategories = (categories: IReportCategoryLevel1[]): IReportCategoryLevel1[] =>
        [...categories].sort((a, b) => {
            const indexA = CATEGORY_ORDER.indexOf(a.key);
            const indexB = CATEGORY_ORDER.indexOf(b.key);

            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
        });

    const parsedCategories: ClientSeverityTableData = useMemo(() => {
        if (!reportCategories.length || !severityData) return [];

        return sortReportCategories(reportCategories).flatMap((level1Category) =>
            level1Category.level2Categories.flatMap((level2Category) =>
                level2Category.level3Categories.map((level3Category) => {
                    const severity = level3Category.severityLevel as SeverityLevel;

                    return {
                        name: [
                            t(`incident.category.${level1Category.key}`),
                            t(`incident.category.${level2Category.key}`),
                            t(`incident.category.${level3Category.key}`),
                        ].join(" . "),
                        category: {
                            code: level3Category.code,
                            level1: level1Category.key,
                            level2: level2Category.key,
                            level3: level3Category.key,
                        },
                        severity: severityData[level3Category.code]?.severity ?? severity,
                        defaultSeverity: severity,
                    } satisfies ClientSeverityTableRowData;
                }),
            ),
        );
    }, [reportCategories, severityData, t]);

    useEffect(() => {
        setAllCategories(parsedCategories);
        setSearchResults(parsedCategories);
    }, [parsedCategories]);

    const filterResults = useMemo(() => {
        let results = searchValue.length
            ? allCategories.filter((row) => {
                  return `${row.name} ${t(getSeverityLevelTranslationKey(row.severity))}`.toLowerCase().includes(searchValue.toLowerCase());
              })
            : allCategories;

        if (searchTags.length) {
            results = results.filter((row) => {
                return searchTags.every((tag) => `${row.name} ${t(getSeverityLevelTranslationKey(row.severity))}`.toLowerCase().includes(tag.toLowerCase()));
            });
        }

        return results;
    }, [allCategories, searchValue, searchTags, t]);

    useEffect(() => {
        setSearchResults(filterResults);
    }, [filterResults]);

    const isUserClientGuardingAdmin = useIsUserClientGuardingAdmin();

    return {
        tableData: searchResults,
        isLoading: fetchStatus === RequestStatus.loading,
        isEditable: isUserClientGuardingAdmin && isEditModeEnabled,
        onSeverityChange: updateSeverityLevel,
        searchValue,
        setSearchValue,
        searchTags,
        setSearchTags,
    };
};
