import i18next from "i18next";
import { getCombinedExceptionAndTourName, getReportTemplateShortTranslation } from "src/helpers/ReportHelper";
import { splitByLastChar, splitCamelCaseToSentence, trimCamelCaseString } from "src/helpers/StringHelper";
import {
    DynamicReportPropertyType,
    DynamicReportPropertyTypeEnum,
    DynamicReportType,
    EventReportTemplateName,
    ReportTemplateEnum,
} from "src/models/ReportModel";

export const getBooleanValue = (value: string | undefined): string | null => {
    if (value?.toLowerCase() === "true") {
        return "common.yes";
    }
    if (value?.toLowerCase() === "false") {
        return "common.no";
    }
    return null;
};

const getArrayValue = (value: string): string => {
    try {
        const array = JSON.parse(value);
        return array?.join(", ") ?? "";
    } catch {
        return "";
    }
};

export const getExceptionQuestionsAnswers = (fieldValue: string): string[][] => {
    return fieldValue?.split("\n")?.map((item) => splitByLastChar(item, ":").map((x) => x.trim())) || [];
};

export const getReportTemplateName = (report: DynamicReportType): string => {
    if (report.template === ReportTemplateEnum.tourException || report.template === ReportTemplateEnum.tourMultiException) {
        const tourName = getFieldValueByFieldName("tourName", report);
        return getCombinedExceptionAndTourName(report.template, tourName);
    }
    return getReportTemplateShortTranslation(report.template);
};

export const resolveReportSubheader = (rootTemplate: string | null, childTemplate: string | null): string | null => {
    if (!rootTemplate || !childTemplate) {
        return null;
    }

    const lowerCasedRootTemplate = rootTemplate.toLowerCase();
    const lowerCasedChildTemplate = childTemplate.toLowerCase();
    if (lowerCasedRootTemplate === lowerCasedChildTemplate) {
        return null;
    }

    const REPORT_SUFFIX = " report";
    const lowerCasedNoReportPostfixRootTemplate = lowerCasedRootTemplate.endsWith(REPORT_SUFFIX)
        ? lowerCasedRootTemplate.slice(0, -REPORT_SUFFIX.length)
        : lowerCasedRootTemplate;

    if (lowerCasedNoReportPostfixRootTemplate === lowerCasedChildTemplate) {
        return null;
    }

    if (rootTemplate === ReportTemplateEnum.incident && resolveReportSubheader(EventReportTemplateName, childTemplate) === null) {
        return null;
    }

    return childTemplate;
};

export const getSiteLocation = (report: DynamicReportType): string => {
    if (report.rootSiteLocation && report.siteLocationSubLevels) {
        return `${report.rootSiteLocation} / ${report.siteLocationSubLevels}`;
    }
    return `${report.rootSiteLocation ?? ""} ${report.siteLocationSubLevels ?? ""}`.trim();
};

export const getNormalizedString = (text: string): string => {
    if (!text?.replace) {
        return text;
    }
    return text.replace(/\r\n/g, "\n").replace(/\r/g, "\n").replace("\t", " ");
};

/**
 * @deprecated New Reporting Strategy
 * Use getFieldValueByCatalogId
 **/
export const getFieldValueByFieldName = (
    fieldName: string,
    {
        properties,
    }: {
        properties: DynamicReportPropertyType[];
    },
): string => {
    const property = properties.find((p) => p.reportFieldName === fieldName);
    if (property?.type === DynamicReportPropertyTypeEnum.array) {
        return getArrayValue(property?.value);
    }
    return getNormalizedString(property?.value);
};

// NOTE How to properly handle conditional return type?
// export const getFieldValueByCatalogId = <T extends object = never, R = T extends never ? string : T | undefined>(catId: string, {properties}: { properties: DynamicReportPropertyType[] }, oldFieldsFallbackMap?: Record<string, string>): R => {
export const getFieldValueByCatalogId = (
    catId: string,
    { properties }: { properties: DynamicReportPropertyType[] },
    oldFieldsFallbackMap?: Record<string, string>,
): string => {
    let reportProperty = properties.find(({ fieldTag }) => fieldTag === catId);

    if (!reportProperty) {
        const oldFieldNames = Object.keys(oldFieldsFallbackMap ?? {}).filter((key) => oldFieldsFallbackMap[key] === catId);
        reportProperty = properties.filter(({ fieldTag }) => !fieldTag).find((property) => oldFieldNames.includes(property.reportFieldName));
    }

    if (reportProperty?.type === DynamicReportPropertyTypeEnum.array) {
        return getArrayValue(reportProperty?.value);
    } else {
        return getNormalizedString(reportProperty?.value);
    }
};

export const getFieldNameByCatalogId = (
    catId: string,
    report: {
        properties: DynamicReportPropertyType[];
    },
): undefined | string => {
    const catalogField = findNrsField(catId, report);

    return catalogField ? parseCustomFieldName(catalogField?.reportFieldName) : undefined;
};

export const getIsFieldPresentByCatalogId = (
    catId: string,
    { properties }: { properties: DynamicReportPropertyType[] },
    oldFieldsFallbackMap: Record<string, string>,
): boolean => {
    if (hasNrsField(catId, { properties })) {
        return true;
    }

    const oldFieldNames = Object.keys(oldFieldsFallbackMap).filter((key) => oldFieldsFallbackMap[key] === catId);
    const oldField = properties.find((property) => !property.fieldTag && oldFieldNames.includes(property.reportFieldName));

    return !!oldField;
};

const findNrsField = (
    catalogId: string,
    {
        properties,
    }: {
        properties: DynamicReportPropertyType[];
    },
): DynamicReportPropertyType | undefined => {
    return properties.find(({ fieldTag }) => fieldTag === catalogId);
};

export const hasNrsField = (
    catalogId: string,
    report: {
        properties: DynamicReportPropertyType[];
    },
): boolean => {
    return !!findNrsField(catalogId, report);
};

export const isNrsReport = (report: Pick<DynamicReportType, "properties">): boolean => {
    return report.properties.some((property) => !!property.fieldTag);
};

const parseCustomFieldName = (fieldName: string): string => {
    return splitCamelCaseToSentence(fieldName)
        ?.replace(/([(])(.)/g, " $1$2")
        .trim();
};

export const getFieldLabel = (field: DynamicReportPropertyType): string => {
    return field.fieldTag ? parseCustomFieldName(field.reportFieldName) : getUnknownFieldLabel(field.reportFieldName);
};

export const getObjectFieldValueByFieldName = <T>(
    fieldName: string,
    {
        properties,
    }: {
        properties: DynamicReportPropertyType[];
    },
): T => {
    const property = properties.find((p) => p.reportFieldName === fieldName);
    try {
        return JSON.parse(property?.value);
    } catch {
        return {} as T;
    }
};

/**
 * @deprecated
 * With New Reporting Strategy, the logic behind field naming is changed
 * Use parseCustomFieldName instead
 **/
export const getUnknownFieldLabel = (fieldName: string): string => {
    const translationKey = `reportdetails.fields.${trimCamelCaseString(fieldName)}`;

    return i18next.exists(translationKey) ? translationKey : splitCamelCaseToSentence(fieldName);
};

export const getUnknownFieldValue = (value: string, type: DynamicReportPropertyTypeEnum): string => {
    try {
        if (type === DynamicReportPropertyTypeEnum.array) {
            return getArrayValue(value);
        }
        const booleanValue = getBooleanValue(value);
        return booleanValue ?? getNormalizedString(value);
    } catch {
        return getNormalizedString(value);
    }
};

// It might need some adjustments based on what we receive from integration services #NRS
export const getUniqueReportProperties = (report: DynamicReportType): DynamicReportPropertyType[] => {
    const uniqueProperties = report.properties.reduce(
        (processedProperties, currentProperty) => {
            const lowerCaseFieldName = currentProperty.reportFieldName?.toLowerCase();
            // NOTE: quick fix for https://secsisip.atlassian.net/browse/GLOB-6015
            // ignore duplicated image fields: currentProperty.type === DynamicReportPropertyTypeEnum.image
            // should be handled properly in the future
            if (currentProperty.type === DynamicReportPropertyTypeEnum.image) {
                processedProperties.result.push(currentProperty);
                return processedProperties;
            }

            if (!processedProperties.lowerCaseDict.has(lowerCaseFieldName)) {
                processedProperties.lowerCaseDict.add(lowerCaseFieldName);
                processedProperties.result.push(currentProperty);
                return processedProperties;
            }
            return processedProperties;
        },
        { result: [] as DynamicReportPropertyType[], lowerCaseDict: new Set() },
    );
    return uniqueProperties.result;
};
