export enum ActivityLogFieldsOld {
    comments = "comments",
    wasTheActivityLoggedIntoTheJournal = "wasTheActivityLoggedIntoTheJournal",
    additionalDescription = "additionalDescription",
    anyOpenItemsToBeAddressed = "anyOpenItemsToBeAddressed",
    passDownReportCompleted = "pass-downReportCompleted",
    debriefOfColleagueHappened = "debriefOfColleagueHappened",
    commanderPresenceOnSite = "commanderPresenceOnSite?",
    nameOfCustomer = "nameOfCustomer/Supervisor/Commander",
    activityLogJob = "activityLogJob",
    activityLogJobId = "activityLogJobId",
}

export enum ActivityLogFields {
    LoggedIntoTheJournal = "CAT_01",
    AdditionalDescription = "CAT_02",
    ColleagueDebrief = "CAT_03",
    PassDownReport = "CAT_04",
    OpenItems = "CAT_05",
    CommanderOnSite = "CAT_06",
    CustomerName = "CAT_07",
    Comments = "CAT_08",
}

export const oldFieldFallbackMap = {
    [ActivityLogFieldsOld.wasTheActivityLoggedIntoTheJournal]: ActivityLogFields.LoggedIntoTheJournal,
    [ActivityLogFieldsOld.additionalDescription]: ActivityLogFields.AdditionalDescription,
    [ActivityLogFieldsOld.debriefOfColleagueHappened]: ActivityLogFields.ColleagueDebrief,
    [ActivityLogFieldsOld.passDownReportCompleted]: ActivityLogFields.PassDownReport,
    [ActivityLogFieldsOld.anyOpenItemsToBeAddressed]: ActivityLogFields.OpenItems,
    [ActivityLogFieldsOld.commanderPresenceOnSite]: ActivityLogFields.CommanderOnSite,
    [ActivityLogFieldsOld.nameOfCustomer]: ActivityLogFields.CustomerName,
    [ActivityLogFieldsOld.comments]: ActivityLogFields.Comments,
    // special fields job and jobId dont have fieldTags but added here to be excluded from unknown fields
    [ActivityLogFieldsOld.activityLogJob]: undefined,
    [ActivityLogFieldsOld.activityLogJobId]: undefined,
};
