export const featureFlags = {
    autotranslations: "experiment-autotranslations",
    inMemoryDemoClient: "entitlement-in-memory-demo-client",
    viewSchedule: "release-view-schedule",
    trueDeviations: "release-true-deviations",
    clientSeverityLevel: "release-client-severity-level",
    clientSeverityLevelEditMode: "release-client-severity-level-edit-mode",
    insightsReportSearch: "release-insights-report-search",
    siteTagsTracking: "release-site-tags-tracking",
    devToolbar: "dev-toolbar",
    deviantCallouts: "release-callouts-deviant-day-hour",
    calloutsTab: "release-insights-callouts-tab",
};

export const contextKinds = {
    locationCountryCode: "location-country-code",
    user: "user",
    client: "client",
    multi: "multi",
};
