import { ComponentType } from "react";
import { RuleSet } from "styled-components";

export type TableCellProps<T, K> = {
    cell: T;
    row: K;
    index: number;
    isExpanded: boolean;
    column: Column;
    onClick: () => void;
};

export enum TableRowVariant {
    default,
    child,
    active,
    inactive,
}

export type Column = {
    id: string;
    header?: string | JSX.Element;
    headerStyle?: RuleSet<object>;
    key: string;
    Cell?: ComponentType<TableCellProps<any, any>>;
    HeaderCell?: ComponentType<any>;
    style?: RuleSet<object>;
};

export type TableRowData = { [k: string]: any };

export type TableData = TableRowData[];
