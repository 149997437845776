import { GridItem, ItemPlacement } from "../DraggableGrid.types";

type GridMaskValue = boolean;
type GridMask = GridMaskValue[][];

export const alignWidgetsForTheTop = (widgets: GridItem[], columns: number) => {
    const placements: (ItemPlacement & GridItem)[] = [];
    const sortedWidgets = sortWidgetsFromTheWidest(widgets);
    const gridMask: GridMask = [createEmptyRow(columns)];

    sortedWidgets.forEach((widget) => {
        let placed = false;
        let currentX = 0;
        let currentY = 0;

        while (!placed && currentY < gridMask.length) {
            while (!placed && currentX < columns) {
                if (isSpotAvailable(gridMask, widget, currentX, currentY)) {
                    placements.push(placeItem(gridMask, widget, currentX, currentY));
                    placed = true;
                }
                currentX += 1;
            }
            currentY += 1;
            currentX = 0;
        }

        if (!placed) {
            gridMask.push(...[...new Array(getWidgetLgHeight(widget))].map(() => createEmptyRow(columns)));
            placements.push(placeItem(gridMask, widget, 0, currentY));
        }
    });

    return placements;
};

const placeItem = (grid: GridMask, widget: GridItem, posX: number, posY: number): ItemPlacement & GridItem => {
    for (let y = 0; y < getWidgetLgHeight(widget); y++) {
        for (let x = 0; x < getWidgetLgWidth(widget); x++) {
            const currentY = posY + y;
            if (!grid[currentY]) {
                grid.push(createEmptyRow(grid[0].length));
            }
            grid[currentY][posX + x] = true;
        }
    }

    return { ...widget, id: widget.id, x: posX, y: posY };
};

const isSpotAvailable = (grid: boolean[][], widget: GridItem, posX: number, posY: number) => {
    if (posX + getWidgetLgWidth(widget) <= grid[0].length) {
        const widgetWidth = getWidgetLgWidth(widget);
        const widgetHeight = getWidgetLgHeight(widget);

        for (let x = 0; x < widgetWidth; x++) {
            for (let y = 0; y < widgetHeight; y++) {
                if (grid?.[posY + y]?.[posX + x]) {
                    return false;
                }
            }
        }
        return true;
    } else {
        return false;
    }
};

const getWidgetLgWidth = (widget: GridItem): number => widget.width ?? widget.responsiveSizing.lg.width;

const getWidgetLgHeight = (widget: GridItem): number => widget.height ?? widget.responsiveSizing.lg.height;

const createEmptyRow = (size: number): GridMaskValue[] => [...new Array(size)].map(() => false);

const sortWidgetsFromTheWidest = (widgets: GridItem[]): GridItem[] => [...widgets].sort((a, b) => (getWidgetLgWidth(a) < getWidgetLgWidth(b) ? 1 : -1));
