import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { DynamicReportType, isPatrolTour, PatrolTourType } from "../../models/ReportModel";
import { RequestStatus } from "../RequestStatus";
import { IState } from "./../../Store";
import { useCreateCommentMutation, useDeleteCommentMutation, useLazyGetReportCommentsQuery, useUpdateCommentMutation } from "./commentApi";
import CommentSelectors from "./CommentSelectors";
import { APP_ID, ICommentModel, IUpsertCommentDto } from "./models";

export const useReportComments = (report: DynamicReportType | PatrolTourType) => {
    const [getReportComments] = useLazyGetReportCommentsQuery();
    const [deleteCommentMutation] = useDeleteCommentMutation();
    const [createCommentMutation] = useCreateCommentMutation();
    const [updateCommentMutation] = useUpdateCommentMutation();

    const selector = useMemo(() => CommentSelectors.makeGetReportComments(), []);
    const reportComments = useSelector((state) => selector(state as IState, null, report.id));

    const isTourReport = useMemo(() => {
        return isPatrolTour(report);
    }, [report]);

    const deleteComment = useCallback(
        (comment: ICommentModel) => {
            const deleteBody = {
                id: comment.id,
                creatorId: comment.creatorId,
                appId: APP_ID,
            };
            deleteCommentMutation({ locationId: report.locationId, reportId: report.id, body: deleteBody, isTour: isTourReport });
        },
        [report, deleteCommentMutation, isTourReport],
    );

    const fetchComments = useCallback(() => {
        getReportComments({ locationId: report.locationId, reportId: report.id, isTour: isTourReport });
    }, [report, isTourReport, getReportComments]);

    const createComment = useCallback(
        (body: IUpsertCommentDto) => {
            createCommentMutation({ locationId: report.locationId, reportId: report.id, body, isTour: isTourReport });
        },
        [createCommentMutation, report, isTourReport],
    );

    const editComment = useCallback(
        (body: IUpsertCommentDto) => {
            updateCommentMutation({ locationId: report.locationId, reportId: report.id, body, isTour: isTourReport });
        },
        [updateCommentMutation, report, isTourReport],
    );

    useEffect(() => {
        if (report.commentsCount > 0) {
            if (reportComments.fetchStatus !== RequestStatus.loading && (!reportComments || reportComments.fetchStatus === RequestStatus.undefined)) {
                fetchComments();
            }
        }
    }, [report, reportComments]);

    return { commentState: reportComments, fetchComments, deleteComment, editComment, createComment };
};
