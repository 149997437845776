import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import { ITrendingEvent } from "src/store/insights/InsightsModel";

import { consolidateEvents } from "../helpers";
import { CustomizedCategories } from "../TrendingEvents.types";
import { useCategoriesWithLevel, useFetchDbEvents, useFetchDeviations } from "./";

export const useTrendingEvents = (
    selectedCategories: CustomizedCategories,
    monthsBack: number,
    isTotalEnabled: boolean,
): {
    isLoading: boolean;
    events: ITrendingEvent[];
} => {
    const { siteIds } = useFilteredSites();
    const [trendingEvents, setTrendingEvents] = useState<ITrendingEvent[]>([]);

    const categoriesWithLevel = useCategoriesWithLevel(selectedCategories);

    const { events: eventsFromDb, isLoading: isFetchDbEventsLoading } = useFetchDbEvents(siteIds, selectedCategories, monthsBack);
    const { deviations, isLoading: isFetchDeviationsLoading } = useFetchDeviations(siteIds, categoriesWithLevel, monthsBack);

    const updatedEvents = useMemo(() => {
        if (isFetchDbEventsLoading || isFetchDeviationsLoading) {
            return [];
        }

        const consolidated = consolidateEvents(eventsFromDb, deviations);

        if (!isTotalEnabled) {
            return consolidated;
        }

        return consolidated.map((event) => {
            const total = Object.entries(event)
                .filter(([key]) => key !== "month")
                .reduce((sum, [, val]) => sum + (typeof val === "number" ? val : 0), 0);
            const { month, ...rest } = event;
            return { month, total, ...rest };
        });
    }, [eventsFromDb, deviations, isFetchDbEventsLoading, isFetchDeviationsLoading, isTotalEnabled]);

    useEffect(() => {
        if (!isEqual(trendingEvents, updatedEvents)) {
            setTrendingEvents(updatedEvents);
        }
    }, [trendingEvents, updatedEvents]);

    const isLoading = isFetchDbEventsLoading || isFetchDeviationsLoading;

    return { isLoading, events: trendingEvents };
};
