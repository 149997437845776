import { Box, Breakpoints, ButtonCompact, ButtonIcon, ButtonText, Headline, Icon, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "src/components/shared/Tooltip";

import { useSummaryHeader } from "./SummaryHeader.hooks";

type Props = {
    isDisabled: boolean;
    isEditMode: boolean;
    isCustomized?: boolean;
    toggleEditMode: () => void;
    onSettingsSave: () => void;
    onSettingsReset: () => void;
};

export const SummaryHeader = ({ isEditMode, isCustomized, isDisabled, toggleEditMode, onSettingsSave, onSettingsReset }: Props) => {
    const { t } = useTranslation();
    const { comparePeriodLabel } = useSummaryHeader();
    const isTablet = useHasMaxWidth(Breakpoints.S);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isResetButtonVisible = isEditMode && isCustomized;

    const renderTitle = () => {
        return (
            <Stack gap="XS" alignItems="center">
                <Headline data-test-id="summary-headline">{t("insights.summary.title")}</Headline>
                <Tooltip
                    message={
                        <>
                            {t("insights.summary.customizationInfo")}
                            <div>{t("insights.summary.compareDateInfo", { period: comparePeriodLabel })}</div>
                        </>
                    }
                >
                    <Icon variant="Info" />
                </Tooltip>
            </Stack>
        );
    };

    const renderEditButton = () =>
        isMobile ? (
            <ButtonIcon disabled={isDisabled} icon="Edit" mode="outlined" onClick={toggleEditMode} />
        ) : (
            <ButtonCompact disabled={isDisabled} icon="Edit" mode="outlined" onClick={toggleEditMode}>
                {t("filters.customize")}
            </ButtonCompact>
        );

    const renderEditControls = () =>
        isEditMode ? (
            <Stack gap={isTablet ? "XS" : "S"} alignItems="center">
                <ButtonCompact disabled={isDisabled} color="accent" mode="contained" onClick={onSettingsSave}>
                    {t("common.save")}
                </ButtonCompact>
                <ButtonCompact disabled={isDisabled} mode="outlined" onClick={toggleEditMode}>
                    {t("common.cancel")}
                </ButtonCompact>
            </Stack>
        ) : (
            renderEditButton()
        );

    return (
        <>
            <Box mb="XS" pb="XXS">
                {renderTitle()}
            </Box>

            <Stack mb="S" gap="S" justifyContent="space-between" alignItems="center">
                {renderEditControls()}
                <Stack alignItems="center" gap="M">
                    {isResetButtonVisible && (
                        <ButtonText icon="Reload" onClick={onSettingsReset}>
                            {t("insights.summary.settings.resetButton")}
                        </ButtonText>
                    )}
                </Stack>
            </Stack>
        </>
    );
};
