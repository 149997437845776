import { Breakpoints, Headline, Icon, Palette, Skeleton, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Bar, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { TileContent } from "src/components/Insights/common/Tile.styled";
import { Tooltip } from "src/components/shared/Tooltip";
import { formatToRawDate } from "src/helpers/date";
import { ReportTemplateEnum } from "src/models/ReportModel";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";
import { TextStyled } from "../InsightsSitePage.styled";
import { SelectedCategories } from "../InsightsSitePage.types";
import { BarchartStyled, LegendContainerStyled } from "./Barchart.styles";
import { CategoryIncidentCountPerSiteBarchart } from "./Barchart.types";
import { CustomizedLabel } from "./components/CustomizedLabel";
import { EmptyStateBarchart } from "./components/EmptyStateBarchart";
import { TooltipContent } from "./TooltipContent";

const LINE_WITH_MARGIN_HEIGHT = 48;
const TICK_HEIGHT = 29;
const BAR_HEIGHT = 28;

const SITE_PAGE_REPORT_TYPES = [
    ReportTemplateEnum.alarmResponse,
    ReportTemplateEnum.incident,
    ReportTemplateEnum.callout,
    ReportTemplateEnum.tourException,
    ReportTemplateEnum.tourMultiException,
];
interface BarchartProps {
    categoryLevel1?: string;
    categoryLevel2?: string;
    isLoading?: boolean;
    levelChangeHandler: (selectedCategories: SelectedCategories) => void;
    data: CategoryIncidentCountPerSiteBarchart[];
}

export const Barchart = ({ categoryLevel1, categoryLevel2, levelChangeHandler, data, isLoading }: BarchartProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [activeCategoryKey, setActiveCategoryKey] = useState<string>("");
    const { siteId } = useParams<{ siteId?: string }>();

    const startDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate);

    const generateRedirectUrl = (categoryLevel3) =>
        [
            `/reports?selectedReportTypes=${SITE_PAGE_REPORT_TYPES.join(",")}`,
            categoryLevel3 ? `selectedCategoryThree=${categoryLevel3}` : "",
            `selectedRegions=${siteId}`,
            `selectedStartDate=${formatToRawDate(startDate)}`,
            `selectedEndDate=${formatToRawDate(endDate)}`,
        ].join("&");

    const navigationHandler = (data) => {
        if (categoryLevel1 && categoryLevel2) navigate(generateRedirectUrl(data?.categoryKey));
        levelChangeHandler({
            level1: categoryLevel1 ?? data?.categoryKey,
            level2: categoryLevel1 ? data?.categoryKey : null,
        });
    };

    const containerHeight = data.length * LINE_WITH_MARGIN_HEIGHT + TICK_HEIGHT;

    return (
        <TileContent data-testid="overview-events-bar-chart-container">
            <Stack gap="XS" mb="L" alignItems="center">
                <Headline bold>{t("incidentMatrix.header.title")}</Headline>
                <Tooltip message={<TooltipContent categoryLevel={categoryLevel2 ? 2 : categoryLevel1 ? 1 : undefined} />}>
                    <Icon variant="Info" />
                </Tooltip>
            </Stack>
            {isLoading ? (
                <Skeleton height={250} mode="rectangular" width="100%" />
            ) : (
                <>
                    {data.length === 0 || !data.some((x) => x.count > 0) ? (
                        <EmptyStateBarchart />
                    ) : (
                        <Stack direction="row">
                            <LegendContainerStyled direction="column">
                                {data.map((entry, index) => (
                                    <Stack
                                        key={entry.categoryKey + index}
                                        alignItems={"center"}
                                        gap={"XS"}
                                        style={{ height: "48px" }}
                                        data-testid="overview-events-bar-chart-legend-item"
                                    >
                                        <Icon variant={REPORT_CATEGORY_ICON_MAP[entry.categoryKey]} />
                                        {(!isMobile || categoryLevel1) && (
                                            <Stack alignItems={"center"}>
                                                <TextStyled small color="secondary">
                                                    {t("incident.category." + entry.categoryKey)}
                                                </TextStyled>
                                            </Stack>
                                        )}
                                    </Stack>
                                ))}
                            </LegendContainerStyled>
                            <ResponsiveContainer width="100%" height={containerHeight}>
                                <BarchartStyled data={data} layout="vertical" margin={{ right: 50, left: isMobile ? -30 : 0, top: 0, bottom: 0 }}>
                                    <CartesianGrid horizontal={false} opacity={0.3} />
                                    <XAxis
                                        type="number"
                                        dataKey="count"
                                        tickLine={false}
                                        axisLine={false}
                                        tick={{ fill: Palette.Navy100 }}
                                        allowDecimals={false}
                                    />
                                    <YAxis type="category" dataKey="categoryKey" axisLine={false} tickLine={false} tick={false} allowDecimals={false} />
                                    <Bar
                                        dataKey="count"
                                        radius={[0, 4, 4, 0]}
                                        label={<CustomizedLabel x y width value />}
                                        onClick={navigationHandler}
                                        onMouseOver={(e) => setActiveCategoryKey(e.categoryKey)}
                                        onMouseLeave={() => setActiveCategoryKey("")}
                                        isAnimationActive={false}
                                        barSize={BAR_HEIGHT}
                                        minPointSize={10}
                                    >
                                        {data.map((entry, index) => (
                                            <Cell
                                                cursor="pointer"
                                                fill={entry.categoryKey === activeCategoryKey ? Palette.Navy200 : Palette.Navy100}
                                                key={`cell-${index}`}
                                            />
                                        ))}
                                    </Bar>
                                </BarchartStyled>
                            </ResponsiveContainer>
                        </Stack>
                    )}
                </>
            )}
        </TileContent>
    );
};
