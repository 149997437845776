import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { GraphPalette, Palette, Spacing } from "@secuis/ccp-react-components";
import { ErrorPdf, FilePdf, LeaveBuildingPdf, MobileGuardingPdf, WarningHighPdf } from "src/components/PdfReports/shared/Icons";
import { useAutoTranslationWhenNotTranslated } from "src/helpers/autoTranslations/hooks";

type Props = {
    iconType: string;
    dateTime: string;
    status: string;
    lineColor?: GraphPalette | Palette;
    shouldAutoTranslate: boolean;
};
const CIRCLE_RADIUS = `${Spacing.M}px`;
const styles = StyleSheet.create({
    container: {
        display: "flex",
        columnGap: Spacing.XXS,
        flexDirection: "row",
    },
    circle: {
        borderRadius: 50,
        border: "1px solid #2E4869",
        width: CIRCLE_RADIUS,
        height: CIRCLE_RADIUS,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
    },
    hirozontalLine: {
        height: "2px",
        width: "15px",
        backgroundColor: "#2E4869",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        marginRight: Spacing.XXS,
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        zIndex: 100,
    },
    eventContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
    },
    dateTime: {
        display: "flex",
        fontWeight: 700,
        fontSize: "12px",
        alignSelf: "center",
    },
    lineWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        width: CIRCLE_RADIUS,
    },
    line: {
        width: "6px",
        height: 48,
        marginBottom: -Spacing.M,
    },
    statusText: {
        fontSize: "10px",
        marginTop: "1px",
    },
    timeLineText: {
        columnGap: Spacing.XXS,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    timeLinePoint: {
        flexDirection: "column",
    },
});

const iconMap = {
    MobileGuarding: MobileGuardingPdf,
    File: FilePdf,
    WarningHigh: WarningHighPdf,
    LeaveBuilding: LeaveBuildingPdf,
    Error: ErrorPdf,
};

export const PdfTimelineEvent = ({ status, shouldAutoTranslate, iconType, dateTime, lineColor }: Props) => {
    const { translatedText } = useAutoTranslationWhenNotTranslated(status, shouldAutoTranslate);
    const IconComponent = iconMap[iconType];

    return (
        <View style={{ width: "100%" }}>
            <View style={styles.container}>
                <View style={styles.timeLinePoint}>
                    <View style={styles.iconContainer}>
                        <View style={styles.circle}>
                            <IconComponent />
                        </View>
                        <View style={styles.hirozontalLine} />
                    </View>
                    {lineColor && (
                        <View style={styles.lineWrapper}>
                            <View style={{ ...styles.line, backgroundColor: lineColor }} />
                        </View>
                    )}
                </View>
                <View style={{ paddingTop: "4px" }}>
                    <View style={styles.timeLineText}>
                        {dateTime && <Text style={styles.dateTime}>{dateTime}</Text>}
                        <Text style={styles.statusText}>{translatedText}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};
