import { getFieldNameByCatalogId, getFieldValueByCatalogId, hasNrsField } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { oldFieldFallbackMap, TaskReportFields } from "../../../models/reportTemplates/Task";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "./DynamicTemplateUtils";

export const getTaskReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const unknownFields = getUnknownFieldsComponentsNRS({
        report,
        sharingId: sharedReportId,
        excludedCatalogIds: Object.values(TaskReportFields),
        oldFieldFallbackMap,
    });
    // Check isRequired to show NRS fields and reqired fields and check fieldValue
    const shouldRenderUnknownFields = unknownFields.some((field) => field.props?.isRequired || field.props?.fieldValue);
    const shouldRenderDescription =
        getFieldValueByCatalogId(TaskReportFields.Description, report, oldFieldFallbackMap) ||
        hasNrsField(TaskReportFields.Description, report) ||
        report.images.length > 0;

    return [
        ...getBaseReportConfig({ report, siteObject, sharedReportId }),
        conditionalRender(shouldRenderDescription, () => ({
            component: DynamicTemplateSection,
            props: {
                label: getFieldNameByCatalogId(TaskReportFields.Description, report) || "reportdetails.detailedInfo.taskReportDescription",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByCatalogId(TaskReportFields.Description, report, oldFieldFallbackMap),
                        sharedReportId,
                        imagesData: report.images,
                        isRequired: hasNrsField(TaskReportFields.Description, report),
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        conditionalRender(shouldRenderUnknownFields, () => {
            return {
                component: DynamicTemplateSection,
                props: {
                    label: "reportdetails.detailedInfo.taskReportSummary",
                    collapsedLabel: "reportdetails.summary.moreDetails",
                    expandedLabel: "reportdetails.summary.lessDetails",
                },
                children: unknownFields,
            };
        }),
    ];
};
