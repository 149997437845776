import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Widget } from "../shared/components";
import { ValueTileStatic } from "../shared/components/ValueTileStatic";

export const CalloutResponseTimeWidgetStatic = () => {
    const { t } = useTranslation();
    const label = useMemo(() => t("common.minutes", { count: 0 }).replace("0", "").trim(), [t]);

    return (
        <Widget title={t("insights.summary.calloutAverageResponseWidget.title")} hasHeaderSeparator={false}>
            <ValueTileStatic label={label} />
        </Widget>
    );
};
