import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { featureFlags } from "../../data/featureFlags";
import FeatureFlagsActions from "../../store/featureFlags/FeatureFlagsActions";
import FeatureFlagsSelectors from "../../store/featureFlags/FeatureFlagsSelectors";
import { RequestStatus } from "../../store/RequestStatus";
import { Loading } from "../shared/Loading";

// useBooleanFlagValue tends to overregister event listeners when reused in hooks
// in such cases, feature flag can be stored in store and accessed with selector in hooks
export const FeatureFlagResolver = ({ children }: { children?: ReactNode }) => {
    const dispatch = useDispatch();
    const isViewScheduleEnabled = useBooleanFlagValue(featureFlags.viewSchedule, false);
    const featureFlagsStatus = useSelector(FeatureFlagsSelectors.selectFeatureFlagsStatus);
    const isTrueDeviationsEnabled = useBooleanFlagValue(featureFlags.trueDeviations, false);
    const isClientSeverityLevelEnabled = useBooleanFlagValue(featureFlags.clientSeverityLevel, false);
    const isClientSeverityLevelEditModeEnabled = useBooleanFlagValue(featureFlags.clientSeverityLevelEditMode, false);
    const isInsightsReportSearchEnabled = useBooleanFlagValue(featureFlags.insightsReportSearch, false);
    const isDemoEnabled = useBooleanFlagValue(featureFlags.inMemoryDemoClient, false) || process.env.REACT_APP_INMEMORY_DEMO_ENABLED === "true";
    const isSiteTagsTrackingEnabled = useBooleanFlagValue(featureFlags.siteTagsTracking, false);
    const isDevToolbarEnabled = useBooleanFlagValue(featureFlags.devToolbar, false);
    const isDeviantCalloutsEnabled = useBooleanFlagValue(featureFlags.deviantCallouts, false);
    const isCalloutsTabEnabled = useBooleanFlagValue(featureFlags.calloutsTab, false);

    useEffect(() => {
        dispatch(
            FeatureFlagsActions.setFeatureFlags({
                [featureFlags.inMemoryDemoClient]: isDemoEnabled,
                [featureFlags.trueDeviations]: isTrueDeviationsEnabled,
                [featureFlags.viewSchedule]: isViewScheduleEnabled,
                [featureFlags.clientSeverityLevel]: isClientSeverityLevelEnabled,
                [featureFlags.clientSeverityLevelEditMode]: isClientSeverityLevelEditModeEnabled,
                [featureFlags.insightsReportSearch]: isInsightsReportSearchEnabled,
                [featureFlags.siteTagsTracking]: isSiteTagsTrackingEnabled,
                [featureFlags.devToolbar]: isDevToolbarEnabled,
                [featureFlags.deviantCallouts]: isDeviantCalloutsEnabled,
                [featureFlags.calloutsTab]: isCalloutsTabEnabled,
            }),
        );
    }, [
        dispatch,
        isDemoEnabled,
        isTrueDeviationsEnabled,
        isViewScheduleEnabled,
        isClientSeverityLevelEnabled,
        isClientSeverityLevelEditModeEnabled,
        isInsightsReportSearchEnabled,
        isSiteTagsTrackingEnabled,
        isDevToolbarEnabled,
        isDeviantCalloutsEnabled,
        isCalloutsTabEnabled,
    ]);

    if (featureFlagsStatus !== RequestStatus.success) {
        return <Loading size="L" />;
    }

    return children;
};
