import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CategoryKeys, ChartCategoryKeyType, ChartCategoryType, chartConfig, exceptionTrendsChartCategories } from "src/components/Insights/common/ChartConfig";
import { addDays, addMilliseconds, differenceInDays } from "src/helpers/date";
import { getScannedCheckpointsPercentage } from "src/pages/Insights/PatrolTasks/PatrolTasks.helpers";
import InsightsSelectors from "src/store/insights/InsightsSelectors";
import { useTotalDeviations } from "src/store/insights/PatrolDeviations/PatrolDeviationsHooks";

import { UNGROUPED_ITEMS_VALUE } from "../../../components/Reports/ReportsFilter";
import { countriesByRegions, regionsByCountry } from "../../../data/regions";
import { startOfMonth, subMonths } from "../../../helpers/date";
import { useFilteredSites } from "../../../store/insights/FilterHooks";
import { TABLE_TOTAL_KEY } from "../../../store/insights/InsightsModel";
import { CompareTableParent } from "../../../store/insights/InsightsPatrolTasksModel";
import { generateQueryKey } from "../../../store/insights/keys";
import * as PatrolDeviationsSelectors from "../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";
import { RequestStatus } from "../../../store/RequestStatus";
import { useLazyRequestTourSessionsQuery } from "../../../store/tourSessions/tourSessionsApi";
import { TourSessions } from "../../../store/tourSessions/types";
import { EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS } from "./PatrolTasks.constants";
import { countTotal, formatDeviations, generateRedirectUrl, getMonths } from "./PatrolTasks.helpers";

const allCountries = Object.keys(regionsByCountry);
const allRegions = countriesByRegions.map((r) => r.name);

const useTourSessions = () => {
    const { siteId } = useParams<{ siteId?: string }>();
    const { siteIds: filteredSiteIds } = useFilteredSites();
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const [requestTourSessions] = useLazyRequestTourSessionsQuery();
    const histDateRange = useMemo(() => {
        const dateDifference = differenceInDays(addMilliseconds(selectedEndDate, 1), selectedStartDate);
        return [addDays(selectedStartDate, -dateDifference), addMilliseconds(selectedStartDate, -1)];
    }, [selectedStartDate, selectedEndDate]);
    const [queryKey, setQueryKey] = useState<string>(null);
    const [tourSessions, setTourSessions] = useState<TourSessions[]>([]);
    const [historicalTourSessions, setHistoricalTourSessions] = useState<TourSessions[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingHistorical, setIsLoadingHistorical] = useState<boolean>(true);

    useEffect(() => {
        const siteIds = siteId ? [siteId] : filteredSiteIds;
        const newQueryKey = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        const ayncGetTourSessions = async (queryKey: string) => {
            const { data, isLoading } = await requestTourSessions({
                body: {
                    siteIds,
                    fromDateTime: selectedStartDate,
                    toDateTime: selectedEndDate,
                },
            });
            const { data: historicalData = [], isLoading: isLoadingHistorical } = await requestTourSessions({
                body: {
                    siteIds,
                    fromDateTime: histDateRange[0],
                    toDateTime: histDateRange[1],
                },
            });
            setQueryKey(queryKey);
            setTourSessions(data);
            setHistoricalTourSessions(historicalData);
            setIsLoading(isLoading);
            setIsLoadingHistorical(isLoadingHistorical);
        };
        if (newQueryKey !== queryKey && siteIds.length) {
            ayncGetTourSessions(newQueryKey);
        }
    }, [selectedStartDate, selectedEndDate, histDateRange, siteId, filteredSiteIds, queryKey]);

    return {
        tourSessions,
        historicalTourSessions,
        isLoading: isLoading || isLoadingHistorical,
    };
};

const useAggregatedTourSessionsSummary = (): {
    exceptionsCount: number;
    toursCount: number;
    checkpointsScanned: number;
    checkpointsMissed: number;
    scannedCheckpointsPercent: number;
    historicalExceptionsCount: number;
    historicalToursCount: number;
    historicalCheckpointsScanned: number;
    historicalCheckpointsMissed: number;
    historicalScannedCheckpointsPercent: number;
    isLoading: boolean;
    tourSessions: TourSessions[];
    historicalTourSessions: TourSessions[];
} => {
    const { tourSessions, historicalTourSessions, isLoading } = useTourSessions();

    const reduceSummary = (tourSessions: TourSessions[]) => {
        return tourSessions.reduce(
            (summary, details) => ({
                exceptionsCount: summary.exceptionsCount + details.exceptionsCount,
                toursCount: summary.toursCount + details.count,
                checkpointsScanned: summary.checkpointsScanned + details.checkpointsScanned,
                checkpointsMissed: summary.checkpointsMissed + details.checkpointsMissed,
            }),
            { exceptionsCount: 0, toursCount: 0, checkpointsScanned: 0, checkpointsMissed: 0 },
        );
    };

    const { exceptionsCount, toursCount, checkpointsScanned, checkpointsMissed } = useMemo(() => reduceSummary(tourSessions), [tourSessions]);
    const {
        exceptionsCount: historicalExceptionsCount,
        toursCount: historicalToursCount,
        checkpointsScanned: historicalCheckpointsScanned,
        checkpointsMissed: historicalCheckpointsMissed,
    } = useMemo(() => reduceSummary(historicalTourSessions), [historicalTourSessions]);

    const scannedCheckpointsPercent = useMemo(
        () => getScannedCheckpointsPercentage(checkpointsMissed, checkpointsScanned),
        [checkpointsMissed, checkpointsScanned],
    );
    const historicalScannedCheckpointsPercent = useMemo(
        () => getScannedCheckpointsPercentage(historicalCheckpointsMissed, historicalCheckpointsScanned),
        [historicalCheckpointsMissed, historicalCheckpointsScanned],
    );

    return {
        exceptionsCount,
        toursCount,
        checkpointsScanned,
        checkpointsMissed,
        scannedCheckpointsPercent,
        historicalExceptionsCount,
        historicalToursCount,
        historicalCheckpointsScanned,
        historicalCheckpointsMissed,
        historicalScannedCheckpointsPercent,
        isLoading,
        tourSessions,
        historicalTourSessions,
    };
};

const useTourSessionsSummaryByLocations = () => {
    const { t } = useTranslation();
    const {
        exceptionsCount,
        toursCount,
        checkpointsScanned,
        checkpointsMissed,
        historicalExceptionsCount,
        historicalToursCount,
        historicalCheckpointsScanned,
        historicalCheckpointsMissed,
        tourSessions,
        historicalTourSessions,
    } = useAggregatedTourSessionsSummary();
    const authorizedLocations = useSelector(LocationsSelectors.getAuthorizedLocations);

    const result = useMemo(() => {
        const locationSessionSummary = [];
        const allLocations = new Set([...tourSessions, ...historicalTourSessions].map((obj) => obj.locationId));

        [...allLocations].forEach((locationId, index) => {
            const locationSummary = tourSessions.find((obj) => obj.locationId === locationId);
            const historicalLocationSummary = historicalTourSessions.find((obj) => obj.locationId === locationId);
            const locationDetails = authorizedLocations.siteObjects.find((obj) => obj.id === locationId);

            locationSessionSummary.push({
                id: locationDetails.displayName,
                siteId: locationId,
                group: t(`country.${locationDetails.countryCode}`),
            });
            locationSessionSummary[index]["tours"] = {
                count: locationSummary?.count || 0,
                histCount: historicalLocationSummary?.count || 0,
            };
            locationSessionSummary[index]["deviations"] = {
                count: locationSummary?.exceptionsCount || 0,
                histCount: historicalLocationSummary?.exceptionsCount || 0,
            };
            locationSessionSummary[index]["checkpoints"] = {
                count: {
                    scanned: locationSummary?.checkpointsScanned || 0,
                    missed: locationSummary?.checkpointsMissed || 0,
                },
                histCount: {
                    scanned: historicalLocationSummary?.checkpointsScanned || 0,
                    missed: historicalLocationSummary?.checkpointsMissed || 0,
                },
            };
        });
        locationSessionSummary.sort((a, b) => a.id.localeCompare(b.id));
        const aggregatedData = locationSessionSummary.reduce((result, item) => {
            if (!result[item.group]) {
                result[item.group] = {
                    id: item.group,
                    children: [],
                    tours: { count: 0, histCount: 0 },
                    deviations: { count: 0, histCount: 0 },
                    checkpoints: {
                        count: {
                            missed: 0,
                            scanned: 0,
                        },
                        histCount: {
                            missed: 0,
                            scanned: 0,
                        },
                    },
                };
            }
            result[item.group].children.push(item);
            result[item.group].tours.count += item.tours.count;
            result[item.group].tours.histCount += item.tours.histCount;
            result[item.group].deviations.count += item.deviations.count;
            result[item.group].deviations.histCount += item.deviations.histCount;
            result[item.group].checkpoints.count.scanned += item.checkpoints.count.scanned;
            result[item.group].checkpoints.histCount.scanned += item.checkpoints.histCount.scanned;
            result[item.group].checkpoints.count.missed += item.checkpoints.count.missed;
            result[item.group].checkpoints.histCount.missed += item.checkpoints.histCount.missed;
            return result;
        }, {});
        const aggregatedArray: any[] = Object.values(aggregatedData);
        aggregatedArray.sort((a, b) => a.id.localeCompare(b.id));

        const locationsWithTotal: CompareTableParent[] = [
            ...aggregatedArray,
            {
                id: TABLE_TOTAL_KEY,
                tours: {
                    count: toursCount,
                    histCount: historicalToursCount,
                },
                deviations: {
                    count: exceptionsCount,
                    histCount: historicalExceptionsCount,
                },
                checkpoints: {
                    count: {
                        missed: checkpointsMissed,
                        scanned: checkpointsScanned,
                    },
                    histCount: {
                        missed: historicalCheckpointsMissed,
                        scanned: historicalCheckpointsScanned,
                    },
                },
            },
        ];

        return locationsWithTotal;
    }, [
        tourSessions,
        historicalTourSessions,
        toursCount,
        historicalToursCount,
        exceptionsCount,
        historicalExceptionsCount,
        checkpointsMissed,
        checkpointsScanned,
        historicalCheckpointsMissed,
        historicalCheckpointsScanned,
        authorizedLocations.siteObjects,
        t,
    ]);

    return { tourSessionsSummaryByLocation: result };
};

const useSelectedCategories = ({ siteIds, currentDateRange }: { siteIds: string[]; currentDateRange: Date[] }) => {
    const { t } = useTranslation();
    const [selectedCategories, setSelectedCategories] = useState<(ChartCategoryKeyType & { label: string })[]>([]);
    const translatedChartCategories = useMemo(
        () =>
            exceptionTrendsChartCategories.map((c) => ({
                ...c,
                label: t(`insights.exceptionTrend.${c.key}`),
            })),
        [t],
    );
    const { totalDeviations, isLoading } = useTotalDeviations(siteIds, currentDateRange, EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS);
    const currentMonths = useMemo(() => getMonths(currentDateRange[0], currentDateRange[1]), [currentDateRange]);
    // for prev period we dont need all months, just those from data
    const prevMonths = Object.keys(totalDeviations[1] || {});

    const exceptions = useMemo(() => formatDeviations(totalDeviations[0], currentMonths), [totalDeviations, currentMonths]);
    const previousExceptions = useMemo(() => formatDeviations(totalDeviations[1], prevMonths), [totalDeviations, prevMonths]);

    useEffect(() => {
        setSelectedCategories(
            [
                translatedChartCategories.at(0),
                ...translatedChartCategories.slice(1, -1).sort((a, b) => a.label.localeCompare(b.label)),
                translatedChartCategories.at(-1),
            ].filter((cat) => countTotal(exceptions, cat.key) > 0),
        );
    }, [exceptions, translatedChartCategories]);

    const dropdownItems = useMemo(
        () =>
            selectedCategories
                .filter((c) => c.key !== CategoryKeys.total)
                .map((c) => ({
                    ...c,
                    value: c.key,
                })),
        [selectedCategories],
    );

    const activeCategories = useMemo(
        () => selectedCategories.filter((c) => c.isActive).map((value, index) => ({ ...value, ...chartConfig[index] }) as ChartCategoryType),
        [selectedCategories],
    );

    const toggleCategory = (cat: string) => {
        setSelectedCategories(selectedCategories.map((c) => (c.key === cat ? { ...c, isActive: !c.isActive } : c)));
    };

    return {
        activeCategories,
        selectedCategories,
        dropdownItems,
        toggleCategory,
        isLoading,
        exceptions,
        previousExceptions,
    };
};

export const usePatrolTasks = (siteIds: string[]) => {
    const locations = useSelector(LocationsSelectors.getAuthorizedLocations);
    const { isLoading: areToursLoading, toursCount, exceptionsCount, ...rest } = useAggregatedTourSessionsSummary();
    const { tourSessionsSummaryByLocation } = useTourSessionsSummaryByLocations();
    const filteredSiteIds = useMemo(() => {
        return siteIds.filter(
            (item) =>
                !(allCountries.includes(item) || allRegions.includes(item) || locations.groups.some((x) => x.id === item) || item === UNGROUPED_ITEMS_VALUE),
        );
    }, [siteIds, locations.groups]);
    const patrolDeviations = useSelector(PatrolDeviationsSelectors.getPatrolDeviations);
    const deviationsQueryStatus = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryStatus);
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const currentDateRange = useMemo(() => {
        const startDate = startOfMonth(subMonths(new Date(), EXCEPTIONS_CONTROL_PERIOD_IN_MONTHS));
        return [startDate, new Date()];
    }, []);

    const { exceptions, previousExceptions, activeCategories, ...otherProps } = useSelectedCategories({ siteIds: filteredSiteIds, currentDateRange });

    const isEmptyPage =
        !siteIds?.length ||
        (!toursCount &&
            !exceptionsCount &&
            !rest.historicalExceptionsCount &&
            !rest.historicalToursCount &&
            deviationsQueryStatus === RequestStatus.success &&
            !exceptions.length &&
            !previousExceptions.length);
    const isLoadingPage = siteIds.length && (areToursLoading || deviationsQueryStatus === RequestStatus.loading);

    return {
        isEmptyPage,
        isLoadingPage,
        exceptionTrends: {
            exceptions,
            previousExceptions,
            redirectUrl: generateRedirectUrl(
                currentDateRange,
                selectedRegions,
                activeCategories?.map((categoryParamKey) => categoryParamKey.categoryUrlParameterKey).filter(Boolean),
            ),
            activeCategories,
            ...otherProps,
        },
        patrolDeviations,
        tourSessionsSummaryByLocation,
        tourSessionsSummary: {
            toursCount,
            exceptionsCount,
            ...rest,
        },
    };
};
