import { Palette, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

const LOGO_COLOR = "#d7052c";
const BACKGROUND_COLOR = Palette.Purple600;

export const StackWrapperStyled = styled(Stack).attrs({
    alignItems: "center",
    direction: "row",
    justifyContent: "space-between",
    gap: "L",
})`
    padding: 0.375rem 1.5rem;
    background-color: ${BACKGROUND_COLOR};
    font-size: 0.875rem;
    position: sticky;
    top: 0;
    z-index: 1000;
`;

export const TextStyled = styled(Text).attrs({
    small: true,
})``;

export const DropdownStyled = styled.div`
    position: relative;
    z-index: 1001;
`;

export const DropdownContentStyled = styled.div`
    padding: 0.75rem 1rem 0.5rem;
    background-color: ${BACKGROUND_COLOR};
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1002;
`;

export const DlRow = styled.div`
    margin: 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    align-items: flex-top;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &:last-child {
        border-bottom: none;
    }
`;

export const DlStyled = styled.dl<{ width?: string }>`
    width: ${({ width }) => width || "400px"};

    & *::selection {
        background: ${Palette.Purple300};
    }
`;

export const DtStyled = styled.dt`
    font-weight: bold;
`;

export const DdStyled = styled.dd`
    overflow: hidden;
    text-align: right;
    word-wrap: break-word;
`;

export const ButtonStyled = styled.button`
    transition: color 100ms ease-in-out;

    & svg,
    & svg path {
        transition: fill 100ms ease-in-out;
    }

    &:hover {
        color: ${Palette.Purple100};

        & svg,
        & svg path {
            fill: ${Palette.Purple100};
        }
    }
`;

export const LogoStyled = styled.span`
    color: ${LOGO_COLOR};
    margin-right: 0.25rem;
    font-size: 1.5rem;
    line-height: 0;
    letter-spacing: -0.07em;
    display: inline-block;
    transform: translateY(2px);
`;
