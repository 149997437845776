import { Breakpoints, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { ComponentType } from "react";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";
import { QaProps } from "src/models";
import { RuleSet } from "styled-components";

import { CellStyled, RowStyled } from "./Table.styles";
import { TableRowData, TableRowVariant } from "./Table.types";

type Props = {
    data: TableRowData;
    columns: {
        id: string;
        header?: string | JSX.Element;
        key: string;
        Cell?: ComponentType<any>;
        style?: RuleSet<object>;
    }[];
    index: number;
    isParent: boolean;
    isExpanded?: boolean;
    getRowVariant?: (data: unknown) => TableRowVariant;
    onRowClick?: (index: number, data: TableRowData) => void;
} & QaProps;

const defaultGetRowVariant = () => TableRowVariant.default;

export const TableRow = ({ data, index, isParent, isExpanded, columns, getRowVariant = defaultGetRowVariant, onRowClick, ...otherProps }: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const handleRowClick = onRowClick
        ? () => {
              onRowClick(index, data);
          }
        : null;

    const handleRowKeyDown = useOnEnterSpaceKeyDown(handleRowClick);

    return isParent ? (
        <RowStyled
            role="row"
            variant={getRowVariant(data)}
            onClick={handleRowClick}
            tabIndex={handleRowClick ? 1 : -1}
            onKeyDown={handleRowClick ? handleRowKeyDown : null}
            {...otherProps}
        >
            {columns.map(({ id, key, Cell, style, ...rest }) => (
                <CellStyled role="cell" data-testid={`${key}-${index}`} styles={style} key={`cell-${id}`}>
                    {Cell ? (
                        <Cell index={index} isExpanded={isExpanded} row={data} cell={data[key]} column={{ id, key, Cell, style, ...rest }} />
                    ) : (
                        <Text truncate={isMobile} key={`cell-${id}`}>
                            {data[key]}
                        </Text>
                    )}
                </CellStyled>
            ))}
        </RowStyled>
    ) : (
        <RowStyled
            role="row"
            variant={TableRowVariant.child}
            onClick={handleRowClick}
            tabIndex={handleRowClick ? 1 : -1}
            onKeyDown={handleRowClick ? handleRowKeyDown : null}
            {...otherProps}
        >
            {columns.map(({ id, key, Cell, style, ...rest }) => (
                <CellStyled role="cell" data-testid={`${key}-${index}-child`} styles={style} key={`cell-${id}`}>
                    {Cell ? <Cell row={data} cell={data[key]} column={{ id, key, Cell, style, ...rest }} /> : <Text truncate={isMobile}>{data[key]}</Text>}
                </CellStyled>
            ))}
        </RowStyled>
    );
};
