import { LaunchDarklyClientProvider } from "@openfeature/launchdarkly-client-provider";
import { OpenFeature, OpenFeatureProvider } from "@openfeature/react-sdk";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { contextKinds } from "src/data/featureFlags";

import { ChildrenProps } from "../../models/ChildrenPropModel";
import ClientSwitcherSelectors from "../../store/clientSwitcher/ClientSwitcherSelectors";
import UserSelectors from "../../store/user/UserSelectors";
import { FeatureFlagResolver } from "./FeatureFlagResolver";

export const ldClientName = "ld-client";

const FeatureFlagProvider = ({ children }: ChildrenProps) => {
    const userId = useSelector(UserSelectors.getUserId);
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const initAsync = async () => {
            const ldOpenFeatureProvider = new LaunchDarklyClientProvider(process.env.REACT_APP_LD_KEY, { logger: null });
            await ldOpenFeatureProvider.initialize(context);
            OpenFeature.setProvider(ldClientName, ldOpenFeatureProvider);
            setInitialized(true);
        };

        if (!userId) {
            return;
        }

        const context = selectedClient
            ? {
                  kind: contextKinds.multi,
                  user: {
                      key: userId,
                  },
                  client: {
                      key: selectedClient.id,
                  },
              }
            : {
                  kind: contextKinds.user,
                  key: userId,
              };

        initAsync();
    }, [userId, selectedClient]);

    if (!initialized) {
        return null;
    }

    return (
        <OpenFeatureProvider clientName={ldClientName}>
            <FeatureFlagResolver>{children}</FeatureFlagResolver>
        </OpenFeatureProvider>
    );
};

export default FeatureFlagProvider;
