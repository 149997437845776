import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getIsFieldPresentByCatalogId,
    isNrsReport,
} from "src/helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "src/models/DynamicTemplate";
import { DynamicReportType } from "src/models/ReportModel";
import { oldFieldCatalogIdMap, SafetyInspectionFields } from "src/models/reportTemplates/SafetyInspection";

import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getSafetyInspectionReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const isNrs = isNrsReport(report);

    return [
        ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
        conditionalRender(
            isNrs
                ? getIsFieldPresentByCatalogId(SafetyInspectionFields.Description, report, oldFieldCatalogIdMap) || !!images?.length
                : getFieldValueByCatalogId(SafetyInspectionFields.Description, report, oldFieldCatalogIdMap),
            () => {
                return {
                    component: DescriptionSectionPdf,
                    props: {
                        sectionTitle: getFieldNameByCatalogId(SafetyInspectionFields.Description, report) ?? "reportdetails.safetyInspection.description",
                    },
                    children: [
                        {
                            component: PdfReportText,
                            props: {
                                description: getFieldValueByCatalogId(SafetyInspectionFields.Description, report, oldFieldCatalogIdMap),
                                shouldAutoTranslate: true,
                                images,
                            },
                        },
                    ],
                };
            },
        ),
        {
            component: SummarySectionPdf,
            props: {
                label: "reportdetails.safetyInspection.summary.title",
            },
            children: [
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SafetyInspectionFields.TypeOfInspection, report, oldFieldCatalogIdMap), () => [
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(SafetyInspectionFields.TypeOfInspection, report) ??
                                "reportdetails.safetyInspection.summary.typeOfInspection",
                            fieldValue: getFieldValueByCatalogId(SafetyInspectionFields.TypeOfInspection, report, oldFieldCatalogIdMap),
                            shouldAutoTranslate: true,
                        },
                    },
                ]),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SafetyInspectionFields.WasEverythingInOrder, report, oldFieldCatalogIdMap), () => [
                    {
                        component: TextSummaryFieldPdf,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(SafetyInspectionFields.WasEverythingInOrder, report) ??
                                "reportdetails.safetyInspection.summary.wasEverythingInOrder",
                            fieldValue: getFieldValueByCatalogId(SafetyInspectionFields.WasEverythingInOrder, report, oldFieldCatalogIdMap),
                            shouldAutoTranslate: true,
                        },
                    },
                ]),
                ...getUnknownFieldsComponentsNRS({
                    report,
                    sharingId: sharedReportId,
                    excludedCatalogIds: Object.values(SafetyInspectionFields),
                    oldFieldFallbackMap: oldFieldCatalogIdMap,
                    images,
                    forPdf: true,
                }),
            ],
        },
    ];
};
