import { Stack, Tooltip } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getSeverityLevelTranslationKey } from "src/helpers/SeverityLevelHelper";
import { SeverityLevel } from "src/store/organization/types";

import { SEVERITY_LEVELS } from "../../ClientSeverityLevel.constants";
import { SeverityIcon } from "../SeverityIcon";
import { SeverityLevelButtonContentStyled, SeverityLevelButtonStyled, SeverityLevelInfoTextStyled } from "./SeverityLevelSwitcher.styles";

type Props = {
    severityLevel: SeverityLevel;
    defaultSeverityLevel: SeverityLevel;
    onSeverityChange: (severityLevel: SeverityLevel) => void;
};

export const SeverityLevelSwitcher = ({ severityLevel, defaultSeverityLevel, onSeverityChange }: Props) => {
    const [activeLevel, setActiveLevel] = useState<SeverityLevel>(severityLevel);
    const { t } = useTranslation();

    const handleClick = (severityLevel: SeverityLevel) => {
        setActiveLevel(severityLevel);
        onSeverityChange(severityLevel);
    };

    return (
        <Stack alignItems="center" gap="L">
            <Stack>
                {SEVERITY_LEVELS.map((level) => (
                    <SeverityLevelButtonStyled key={level} isActive={level === activeLevel} onClick={() => handleClick(level)}>
                        <Tooltip key={level} message={t(getSeverityLevelTranslationKey(level))} place="top">
                            <SeverityLevelButtonContentStyled>
                                <SeverityIcon severityLevel={level} />
                            </SeverityLevelButtonContentStyled>
                        </Tooltip>
                    </SeverityLevelButtonStyled>
                ))}
            </Stack>
            <SeverityLevelInfoTextStyled>
                ({t("common.default")}: {t(getSeverityLevelTranslationKey(defaultSeverityLevel))})
            </SeverityLevelInfoTextStyled>
        </Stack>
    );
};
