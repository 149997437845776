import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { HorizontalLine } from "../../../../components/shared/HorizontalLine";

export const GridLayoutStyled = styled.div<{
    childSize?: number;
}>`
    align-content: flex-start;
    width: 100%;
    display: grid;
    column-gap: ${Spacing.S}px;
    grid-template-columns: repeat(auto-fill, minmax(${({ childSize = 360 }) => childSize}px, 1fr));
    grid-template-rows: auto;

    ${HorizontalLine} {
        display: none;
    }

    @media (min-width: ${Breakpoints.XL}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: ${Breakpoints.XS}) {
        display: flex;
        flex-direction: column;

        ${HorizontalLine} {
            display: block;
        }
    }
`;
