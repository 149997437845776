import { Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

import { formatTime, set } from "../../../../helpers/date";

type Props = {
    label?: string;
    payload?: Payload<number, string>[];
};

export const DeviantHourTooltipContent = ({ label, payload }: Props) => {
    const datasetProps = payload[0];
    const { t } = useTranslation();

    if (datasetProps) {
        const timeLabel = formatTime(set(new Date(), { hours: parseInt(label ?? "0"), minutes: 0 }));
        const eventsCount = datasetProps.value ?? 0;

        return (
            <Stack gap="M" direction="column" key={label}>
                <Text>
                    {t("insights.summary.deviantHourWidget.chartTooltipTitle")}: {timeLabel}
                </Text>
                <Text>{`${eventsCount} ${t("common.event", { count: eventsCount })}`}</Text>
            </Stack>
        );
    }
};
