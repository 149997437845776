import { Headline, Icon, Skeleton, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { TranslatableEmptyInboxComponent } from "../../../../components/shared/NoContent/EmptyInboxContent";
import { Tooltip } from "../../../../components/shared/Tooltip";
import { RequestStatus } from "../../../../store/RequestStatus";
import { getLocationTours, getLocationToursQueryStatus } from "../../../../store/tourSessions/selectors";
import { SectionWrapper, TooltipContainer } from "../PatrolTasks.styled";
import { TooltipContent } from "./TooltipContent";
import { ToursTable } from "./ToursTable";

export const Tours = () => {
    const { t } = useTranslation();
    const queryStatus = useSelector(getLocationToursQueryStatus);
    const tours = useSelector(getLocationTours);

    return (
        <SectionWrapper data-testid="insights-tasks-sites-page-tours-section" display="flex" direction="column">
            <TooltipContainer>
                <Headline bold>{t("insights.siteTasks.category.tours")}</Headline>
                <Tooltip title={t("insights.siteTasks.category.tours")} message={<TooltipContent />} place="top-start">
                    <Icon variant={"Info"} size="M"></Icon>
                </Tooltip>
            </TooltipContainer>
            {queryStatus === RequestStatus.loading && (
                <Stack direction="column" gap="XS" marginTop="M">
                    <Skeleton height={48} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={48} mode="rectangular" width="100%" />
                </Stack>
            )}
            {queryStatus === RequestStatus.success && !!tours.length && <ToursTable />}
            {queryStatus === RequestStatus.success && !tours.length && (
                <TranslatableEmptyInboxComponent subtitle={"insights.patrolTours.noDataSubtitle"} title={"insights.patrolTours.noDataTitle"} />
            )}
        </SectionWrapper>
    );
};
