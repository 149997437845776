import { SwitcherOptions } from "src/components/shared/Switcher/Switcher.types";

import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";
import { DeviantDataType } from "./types";

export const SUMMARY_EVENT_CATEGORIES = [
    CategoryLevel1Keys.criminalActOrSuspiciousBehavior,
    CategoryLevel1Keys.facility,
    CategoryLevel1Keys.healthAndSafety,
    CategoryLevel1Keys.peopleAndAssets,
];

export const DEVIANT_DATA_TYPE_OPTIONS: SwitcherOptions = [
    {
        value: DeviantDataType.Events,
        labelTranslationKey: "common.event_plural",
    },
    {
        value: DeviantDataType.Callouts,
        labelTranslationKey: "common.callouts_plural",
    },
];
