import { Spacing, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { HorizontalLine } from "src/components/shared/HorizontalLine";
import { StyleProps } from "src/models";

import { Tooltip } from "../../../../components/shared/Tooltip";
import { SummaryValueTile } from "../shared/components/SummaryValueTile";
import { SEPARATOR_COLOR } from "../Summary.constants";
import { useCalloutsPerformanceWidget } from "./CalloutsPerformanceWidget.hooks";
import { GridLayoutStyled, WidgetStyled } from "./CalloutsPerformanceWidget.styles";

type Props = StyleProps;

export const CalloutsPerformanceWidget = ({ style }: Props) => {
    const { t } = useTranslation();
    const { indicators, isLoading } = useCalloutsPerformanceWidget();

    return (
        <WidgetStyled
            data-testid="callouts-performance-indicators-widget"
            title={t("insights.summary.calloutsPerformanceWidget.title")}
            hasHeaderSeparator={false}
            style={style}
            loading={isLoading}
        >
            <GridLayoutStyled>
                {indicators.map(({ value, prevValue, title, tooltip }, index) => (
                    <Stack direction="column" alignItems="stretch" key={title}>
                        {!!index && <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={Spacing.M} />}
                        <SummaryValueTile
                            value={value}
                            previousValue={prevValue}
                            title={
                                tooltip ? (
                                    <Tooltip place="right" message={tooltip} noSizeLimit>
                                        {title}
                                    </Tooltip>
                                ) : (
                                    title
                                )
                            }
                            label={t("common.callouts", { count: value })}
                            icon="MobileGuarding"
                        />
                    </Stack>
                ))}
            </GridLayoutStyled>
        </WidgetStyled>
    );
};
