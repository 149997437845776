import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { customReportFilterOption, ReportTemplateEnum } from "../models/ReportModel";
import FilterActions from "../store/filter/FilterActions";

export const useFilterableReportTypes = () => {
    const dispatch = useDispatch();

    const filterableReportTypes = [
        ReportTemplateEnum.alarmResponse,
        ReportTemplateEnum.incident,
        ReportTemplateEnum.patrolTour,
        ReportTemplateEnum.taskReport,
        ReportTemplateEnum.callout,
        ReportTemplateEnum.vehicleInspection,
        ReportTemplateEnum.maintenance,
        ReportTemplateEnum.safetyInspection,
        ReportTemplateEnum.siteInspection,
        ReportTemplateEnum.tourException,
        ReportTemplateEnum.tourMultiException,
        ReportTemplateEnum.activityLog,
    ];

    const defaultInsightsReportTypes = useMemo(
        () => [
            ReportTemplateEnum.alarmResponse,
            ReportTemplateEnum.incident,
            customReportFilterOption,
            ReportTemplateEnum.maintenance,
            ReportTemplateEnum.siteInspection,
            ReportTemplateEnum.safetyInspection,
            ReportTemplateEnum.taskReport,
            ReportTemplateEnum.activityLog,
        ],
        [],
    );

    const defaultReportListReportTypes = useMemo(
        () => [...defaultInsightsReportTypes, ReportTemplateEnum.patrolTour, ReportTemplateEnum.callout, ReportTemplateEnum.vehicleInspection],
        [defaultInsightsReportTypes],
    );

    const disabledReportTypes = [];

    const setDefaultReportListReportTypeSelection = useCallback(() => {
        dispatch(FilterActions.selectReportTypes(defaultReportListReportTypes));
    }, [dispatch, defaultReportListReportTypes]);

    return {
        filterableReportTypes,
        defaultInsightsReportTypes,
        defaultReportListReportTypes,
        disabledReportTypes,
        setDefaultReportListReportTypeSelection,
    };
};
