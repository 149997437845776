export enum SafetyInspectionFieldsOld {
    description = "description",
    wasEverythingInOrder = "wasEverythingInOrder?",
    typeOfInspection = "typeOfInspection",
}

export enum SafetyInspectionFields {
    Description = "CAT_204",
    WasEverythingInOrder = "CAT_203",
    TypeOfInspection = "CAT_202",
}

export const oldFieldCatalogIdMap: Record<SafetyInspectionFieldsOld, SafetyInspectionFields> = {
    [SafetyInspectionFieldsOld.description]: SafetyInspectionFields.Description,
    [SafetyInspectionFieldsOld.wasEverythingInOrder]: SafetyInspectionFields.WasEverythingInOrder,
    [SafetyInspectionFieldsOld.typeOfInspection]: SafetyInspectionFields.TypeOfInspection,
};
