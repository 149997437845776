import { UserResponse } from "src/store/user/types";

import { IsoStringDate } from "../helpers/date";
import { NonEmptyArray, notNullGuard } from "./index";

export interface IUserName {
    first: string;
    middle?: string;
    last?: string;
}

export interface IUserEmail {
    address: string;
    isVerified: boolean;
    isPrimary: boolean;
}

export enum UserPreference {
    PrivacyPolicyDateAcceptedV4 = "privacyPolicyDateAcceptedV4",
    TermsOfUseDateAcceptedV3 = "termsOfUseDateAcceptedV3",
    OptInForNonAnonymizedAnalitycs = "optInForNonAnonymizedAnalytics",
    Language = "language",
    OnboardingCompletedDate = "onboardingCompletedDate",
    AppGuideProgress = "appGuideProgress",
    Top5IncidentsCategories = "top5IncidentsCategories",
    ReadSiteTimezoneInfo = "readSiteTimezoneInfo",
    SiteTimezoneDatepickerStatus = "siteTimezoneDatepickerStatus",
    SiteEventsSummaryCategories = "siteEventsSummaryCategories",
    IncidentsSummaryCategories = "eventsSummaryCategories",
    IsReportsAutoTranslationActive = "isReportsAutoTranslationActive",
    //@deprecated
    InsightsSummarySettingsMonth = "insightsSummarySettingsMonth",
    DeviantHoursDataType = "deviantHoursDataType",
    DeviantDayDataType = "deviantDayReportTypes",
    InsightsSummarySettings = "insightsSummarySettings",
}

export type IUserPreferences = Partial<{
    [UserPreference.PrivacyPolicyDateAcceptedV4]: string;
    [UserPreference.TermsOfUseDateAcceptedV3]: string;
    [UserPreference.OptInForNonAnonymizedAnalitycs]: boolean;
    [UserPreference.Language]: string;
    [UserPreference.OnboardingCompletedDate]: string;
    [UserPreference.AppGuideProgress]: string;
    [UserPreference.Top5IncidentsCategories]: string;
    [UserPreference.ReadSiteTimezoneInfo]: boolean;
    [UserPreference.SiteTimezoneDatepickerStatus]: string;
    [UserPreference.SiteEventsSummaryCategories]: string;
    [UserPreference.IncidentsSummaryCategories]: string;
    [UserPreference.IsReportsAutoTranslationActive]: boolean;
    //@deprecated
    [UserPreference.InsightsSummarySettingsMonth]: string;
    [UserPreference.DeviantHoursDataType]: string;
    [UserPreference.DeviantDayDataType]: string;
    [UserPreference.InsightsSummarySettings]: string;
    [UserPreference.DeviantHoursDataType]: string;
    [UserPreference.DeviantDayDataType]: string;
}>;

export interface IUser {
    id: string;
    status: string;
    name: IUserName;
    fullName?: string;
    emails: NonEmptyArray<IUserEmail>;
    emailPrimary?: string;
    preferences: IUserPreferences;
    isEmployee: boolean;
    createdAt: IsoStringDate;
}

export const getUserPrimaryEmail = ({ emailPrimary, emails }: Pick<IUser, "emailPrimary" | "emails">): string => {
    return emailPrimary || (emails.find(({ isPrimary }) => isPrimary)?.address ?? emails[0].address);
};

export const getUserFullName = ({ name }: Pick<IUser, "name">): string | undefined => {
    if (name) {
        return [name.first, name.last, name.middle].filter(notNullGuard).join(" ") || undefined;
    }
};

export const getUserLabel = (user: IUser): string => {
    // if name is set then return name
    let label = getUserFullName(user);
    if (!label) {
        // otherwise return email
        label = getUserPrimaryEmail(user);
    }
    return label;
};

const resolveType = (value) => {
    // Cast boolean strings to boolean
    if (value === "true" || value === "false") {
        value = value === "true";
    }

    return value;
};

const mapKeyValueToUserPreference = (pairs: { key: string; value: any }[]) => {
    const result = {};

    if (pairs && pairs.length > 0) {
        pairs.forEach(({ key, value }) => {
            result[key] = resolveType(value);
        });
    }

    return result as IUserPreferences;
};

export const mapToUser = (userDTO: UserResponse): IUser => {
    return {
        id: userDTO.id,
        status: userDTO.Status ?? userDTO.status,
        createdAt: userDTO.createdTime,
        name: userDTO.name,
        fullName: getUserFullName(userDTO),
        emails: userDTO.emails,
        emailPrimary: getUserPrimaryEmail({ emails: userDTO.emails, emailPrimary: userDTO.primaryEmailAddress }),
        isEmployee: userDTO.isEmployee,
        preferences: mapKeyValueToUserPreference(userDTO.preferences),
    };
};

export interface IUserListItem {
    id: string;
    image?: any;
    canEdit: boolean;
    isEditing?: boolean;
    subTitle?: string;
    title?: string;
    isPending: boolean;
    descendantSitesCount?: string;
}
