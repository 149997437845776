import { Breakpoints, Separator, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { getVehicleInspectionReportProperties } from "../../../helpers/reportTemplates/vehicleInspectionHelper";
import { DynamicReportType } from "../../../models/ReportModel";
import { CarInpsectionIcon } from "./CarInspectionIcon";

type Props = {
    report: DynamicReportType;
};

const StyledStack = styled(Stack)`
    row-gap: ${Spacing.XS}px;
    flex-wrap: wrap;
`;

const StyledCarInspectionIcon = styled(CarInpsectionIcon)`
    margin-top: 1px;
    flex-shrink: 0;
    align-self: flex-start;

    @media (max-width: ${Breakpoints.L}) {
        margin-top: 0px;
    }
`;

export const CarHeaderLine = ({ report }: Props) => {
    // NOTE: Consider moving the fetching data logic outside of the component,
    // and make it dump, fully presentational
    const { licensePlate, vehicleModel, inspectionCount } = getVehicleInspectionReportProperties(report.properties);

    const shouldShowRow = !!licensePlate || !!vehicleModel || !!inspectionCount;

    return (
        shouldShowRow && (
            <>
                <StyledStack direction="row">
                    {licensePlate && (
                        <>
                            <Text micro color="primary" bold>
                                {licensePlate}
                            </Text>
                            <Separator variant="vertical" ml="S" mr="S" />
                        </>
                    )}
                    {vehicleModel && (
                        <Text micro color="secondary">
                            {vehicleModel}
                        </Text>
                    )}
                    {inspectionCount && (
                        <Stack ml="S" gap="XXS" alignItems="center">
                            <StyledCarInspectionIcon width={16} height={16} />
                            <Text micro color="secondary" uppercase>
                                {inspectionCount}
                            </Text>
                        </Stack>
                    )}
                </StyledStack>
                <Separator mv="XS" />
            </>
        )
    );
};
