import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getIsFieldPresentByCatalogId,
    isNrsReport,
} from "src/helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "src/models/DynamicTemplate";
import { DynamicReportType } from "src/models/ReportModel";
import { oldFieldCatalogIdMap, SafetyInspectionFields } from "src/models/reportTemplates/SafetyInspection";

import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "./DynamicTemplateUtils";

export const getSafetyInspectionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const isNrs = isNrsReport(report);

    return [
        ...getBaseReportConfig({ report, siteObject, sharedReportId }),
        conditionalRender(
            isNrs
                ? getIsFieldPresentByCatalogId(SafetyInspectionFields.Description, report, oldFieldCatalogIdMap) || !!report.images?.length
                : getFieldValueByCatalogId(SafetyInspectionFields.Description, report, oldFieldCatalogIdMap),
            () => {
                return {
                    component: DynamicTemplateSection,
                    props: {
                        label: getFieldNameByCatalogId(SafetyInspectionFields.Description, report) ?? "reportdetails.safetyInspection.description",
                        fullyTranslated: true,
                    },
                    children: [
                        {
                            component: Description,
                            props: {
                                description: getFieldValueByCatalogId(SafetyInspectionFields.Description, report, oldFieldCatalogIdMap),
                                sharedReportId,
                                imagesData: report.images,
                                shouldAutoTranslate: true,
                            },
                        },
                    ],
                };
            },
        ),
        {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.safetyInspection.summary.title",
                collapsedLabel: "reportdetails.summary.moreDetails",
                expandedLabel: "reportdetails.summary.lessDetails",
            },
            children: [
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SafetyInspectionFields.TypeOfInspection, report, oldFieldCatalogIdMap), () => [
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(SafetyInspectionFields.TypeOfInspection, report) ??
                                "reportdetails.safetyInspection.summary.typeOfInspection",
                            fieldValue: getFieldValueByCatalogId(SafetyInspectionFields.TypeOfInspection, report, oldFieldCatalogIdMap),
                            mobileDirection: "column",
                            shouldAutoTranslate: true,
                        },
                    },
                ]),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SafetyInspectionFields.WasEverythingInOrder, report, oldFieldCatalogIdMap), () => [
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(SafetyInspectionFields.WasEverythingInOrder, report) ??
                                "reportdetails.safetyInspection.summary.wasEverythingInOrder",
                            fieldValue: getFieldValueByCatalogId(SafetyInspectionFields.WasEverythingInOrder, report, oldFieldCatalogIdMap),
                            mobileDirection: "column",
                            shouldAutoTranslate: true,
                        },
                    },
                ]),
                ...getUnknownFieldsComponentsNRS({
                    report,
                    sharingId: sharedReportId,
                    excludedCatalogIds: Object.values(SafetyInspectionFields),
                    oldFieldFallbackMap: oldFieldCatalogIdMap,
                }),
            ],
        },
    ];
};
