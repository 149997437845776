import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled, { RuleSet } from "styled-components";

import { TableRowVariant } from "./Table.types";

export const TableStyled = styled.div<{ columnCount: number; rowGroupsEnabled: boolean }>`
    display: ${({ rowGroupsEnabled }) => (!rowGroupsEnabled ? "grid" : null)};
    grid-template-columns: ${({ rowGroupsEnabled, columnCount }) => (!rowGroupsEnabled ? `repeat(${columnCount}, 1fr)` : null)};
`;

export const RowGroupStyled = styled.div.attrs({ role: "rowgroup" })<{ columnCount: number }>`
    display: grid;
    grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);
`;

export const CellStyled = styled(Stack)<{ styles: RuleSet<object> }>`
    align-items: center;
    padding-right: ${Spacing.XS}px;
    @media (max-width: ${Breakpoints.S}) {
        padding-right: ${Spacing.XXS}px;
    }
    @media (max-width: ${Breakpoints.XS}) {
        padding-right: 0;
    }
    ${({ styles }) => styles}
`;

const colorVariantMap = {
    [TableRowVariant.default]: Palette.Navy750,
    [TableRowVariant.active]: Palette.Navy750,
    [TableRowVariant.inactive]: Palette.Navy650,
    [TableRowVariant.child]: Palette.Navy700,
};
const hoverColorVariantMap = {
    [TableRowVariant.default]: Palette.Navy800,
    [TableRowVariant.active]: Palette.Navy800,
    [TableRowVariant.inactive]: Palette.Navy700,
    [TableRowVariant.child]: Palette.Navy800,
};
const activeColorVariantMap = {
    [TableRowVariant.default]: Palette.Navy850,
    [TableRowVariant.active]: Palette.Navy850,
    [TableRowVariant.inactive]: Palette.Navy750,
    [TableRowVariant.child]: Palette.Navy850,
};

export const RowStyled = styled.div<{ variant: TableRowVariant; onClick?: () => void }>`
    position: relative;
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
    padding: 20px;
    border-top: 1px solid rgb(117 153 191 / 30%);
    border-left: ${({ variant }) => (variant === TableRowVariant.active ? `4px solid ${Palette.Purple300}` : "none")};
    background-color: ${({ variant }) => colorVariantMap[variant]};
    &:active {
        background-color: ${({ variant, onClick }) => (onClick ? activeColorVariantMap[variant] : null)};
    }
    &:focus-visible {
        outline: ${FocusStyles.Outline};
        outline-offset: -${Spacing.XXS}px;
    }
    @media (hover: hover) {
        &:hover {
            cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
            background-color: ${({ variant }) => hoverColorVariantMap[variant]};
        }
    }
`;

export const HeaderRowStyled = styled.div`
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
    padding: 0 20px;

    @media (max-width: ${Breakpoints.S}) {
        padding: 0 ${Spacing.S}px;
    }
`;
