import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/api/baseQuery";

import { CreateCommentParamsType, CreateCommentResponse, DeleteCommentParamsType, GetCommentResponse, GetCommentsRequestParamsType } from "./types";

const getBaseUrl = (locationId: string, reportId: string, isTour: boolean) => {
    if (isTour) {
        return `/v1/global/sites/${locationId}/tour-sessions/${reportId}/comments`;
    }
    return `/v2/global/sites/${locationId}/reports/${reportId}/comments`;
};

export const commentApi = createApi({
    reducerPath: "commentApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getReportComments: builder.query<GetCommentResponse, GetCommentsRequestParamsType>({
            query: ({ locationId, reportId, isTour }) => getBaseUrl(locationId, reportId, isTour),
        }),
        createComment: builder.mutation<CreateCommentResponse, CreateCommentParamsType>({
            query: ({ locationId, reportId, body, isTour }) => ({
                url: getBaseUrl(locationId, reportId, isTour),
                method: "PUT",
                body,
            }),
        }),
        updateComment: builder.mutation<CreateCommentResponse, CreateCommentParamsType>({
            query: ({ locationId, reportId, body, isTour }) => ({
                url: getBaseUrl(locationId, reportId, isTour),
                method: "PUT",
                body,
            }),
        }),
        deleteComment: builder.mutation<void, DeleteCommentParamsType>({
            query: ({ locationId, reportId, body, isTour }) => ({
                url: getBaseUrl(locationId, reportId, isTour),
                method: "DELETE",
                body,
            }),
        }),
    }),
});

export const { useLazyGetReportCommentsQuery, useDeleteCommentMutation, useUpdateCommentMutation, useCreateCommentMutation } = commentApi;
