import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getIsFieldPresentByCatalogId,
    isNrsReport,
} from "src/helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "src/models/DynamicTemplate";
import { DynamicReportType } from "src/models/ReportModel";
import { newFieldCatalogIdMap, oldFieldCatalogIdMap, SiteInspectionCatalogIds } from "src/models/reportTemplates/SiteInspection";

import { DynamicTemplateSectionPdf } from "../../../PdfReports/shared/PdfDynamicTemplateSection";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { ImageSummaryFieldPdf, TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";
import { getPdfImagesSection } from "./imageConfigPdf";

export const getSiteInspectionReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const isNrs = isNrsReport(report);

    const getShouldRender = (catalogId: string) => {
        return isNrs
            ? getIsFieldPresentByCatalogId(catalogId, report, oldFieldCatalogIdMap)
            : getFieldValueByCatalogId(catalogId, report, oldFieldCatalogIdMap);
    };

    return [
        ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
        conditionalRender(getShouldRender(SiteInspectionCatalogIds.ObservationsByTheInspector), () => ({
            component: DynamicTemplateSectionPdf,
            props: {
                label:
                    getFieldNameByCatalogId(SiteInspectionCatalogIds.ObservationsByTheInspector, report) ??
                    "reportdetails.siteInspection.inspectorObservations",
                fullyTranslated: true,
                marginTop: "S",
            },
            children: [
                {
                    component: TextAreaSummaryFieldPdf,
                    props: {
                        fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.ObservationsByTheInspector, report, oldFieldCatalogIdMap),
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        conditionalRender(getShouldRender(SiteInspectionCatalogIds.ObservationsByTheInspector), () => ({
            component: DynamicTemplateSectionPdf,
            props: {
                label: getFieldNameByCatalogId(SiteInspectionCatalogIds.ActionTakenByTheTnspector, report) ?? "reportdetails.siteInspection.actionTaken",
                fullyTranslated: true,
                marginTop: "S",
            },
            children: [
                {
                    component: TextAreaSummaryFieldPdf,
                    props: {
                        fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.ActionTakenByTheTnspector, report, oldFieldCatalogIdMap),
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        conditionalRender(images.length > 0, () => getPdfImagesSection("reportdetails.pictures", images, "S")),
        {
            component: SummarySectionPdf,
            props: {
                label: "reportdetails.siteInspection.summary.title",
                collapsedLabel: "reportdetails.summary.moreDetails",
                expandedLabel: "reportdetails.summary.lessDetails",
            },
            children: [
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.OfficerUniformsAndAppearance, report, oldFieldCatalogIdMap),
                    () => {
                        const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.OfficerUniformsAndAppearance, report);
                        return [
                            {
                                component: TextSummaryFieldPdf,
                                props: {
                                    fieldName: fieldNameByCatalogId ?? "reportdetails.siteInspection.summary.officersUniformsAndAppearance",
                                    fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.OfficerUniformsAndAppearance, report, oldFieldCatalogIdMap),
                                    isRequired: true,
                                    shouldAutoTranslate: true,
                                },
                            },
                        ];
                    },
                ),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.RegulatoryCompliant, report, oldFieldCatalogIdMap), () => {
                    const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.RegulatoryCompliant, report);
                    return [
                        {
                            component: TextSummaryFieldPdf,
                            props: {
                                fieldName: fieldNameByCatalogId ?? "reportdetails.siteInspection.summary.regulatoryCompliant",
                                fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.RegulatoryCompliant, report, oldFieldCatalogIdMap),
                                isRequired: true,
                                shouldAutoTranslate: true,
                            },
                        },
                    ];
                }),
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.OfficerHasReadAndAcknowledgedPostOrders, report, oldFieldCatalogIdMap),
                    () => {
                        const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.OfficerHasReadAndAcknowledgedPostOrders, report);
                        return [
                            {
                                component: TextSummaryFieldPdf,
                                props: {
                                    fieldName: fieldNameByCatalogId ?? "reportdetails.siteInspection.summary.postOrderAcknowledgment",
                                    fieldValue: getFieldValueByCatalogId(
                                        SiteInspectionCatalogIds.OfficerHasReadAndAcknowledgedPostOrders,
                                        report,
                                        oldFieldCatalogIdMap,
                                    ),
                                    isRequired: true,
                                    shouldAutoTranslate: true,
                                },
                            },
                        ];
                    },
                ),
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.LastRevisionDateOfPostOrders, report, oldFieldCatalogIdMap),
                    () => {
                        const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.LastRevisionDateOfPostOrders, report);
                        return [
                            {
                                component: TextSummaryFieldPdf,
                                props: {
                                    fieldName: fieldNameByCatalogId ?? "reportdetails.siteInspection.summary.postOrdersLastRevisionDate",
                                    fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.LastRevisionDateOfPostOrders, report, oldFieldCatalogIdMap),
                                    isRequired: true,
                                    shouldAutoTranslate: false,
                                },
                            },
                        ];
                    },
                ),
                ...conditionalRender(
                    !isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.InspectionTimeAndDate, report, oldFieldCatalogIdMap),
                    () => {
                        const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.InspectionTimeAndDate, report);
                        return [
                            {
                                component: TextSummaryFieldPdf,
                                props: {
                                    fieldName: fieldNameByCatalogId ?? "reportdetails.siteInspection.summary.inspectionDateTime",
                                    fieldValue: getFieldValueByCatalogId(SiteInspectionCatalogIds.InspectionTimeAndDate, report, oldFieldCatalogIdMap),
                                    isRequired: true,
                                    shouldAutoTranslate: false,
                                },
                            },
                        ];
                    },
                ),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.OfficerSignature, report, oldFieldCatalogIdMap), () => {
                    const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.OfficerSignature, report);
                    return [
                        {
                            component: ImageSummaryFieldPdf,
                            props: {
                                fieldName: fieldNameByCatalogId ?? "reportdetails.fields.officersSignature",
                                fieldValue: images?.find(
                                    (i) => i.reportFieldName === (fieldNameByCatalogId ?? newFieldCatalogIdMap[SiteInspectionCatalogIds.OfficerSignature]),
                                ),
                                isRequired: true,
                            },
                        },
                    ];
                }),
                ...conditionalRender(!isNrs || getIsFieldPresentByCatalogId(SiteInspectionCatalogIds.InspectorSignature, report, oldFieldCatalogIdMap), () => {
                    const fieldNameByCatalogId = getFieldNameByCatalogId(SiteInspectionCatalogIds.InspectorSignature, report);
                    return [
                        {
                            component: ImageSummaryFieldPdf,
                            props: {
                                fieldName: fieldNameByCatalogId ?? "reportdetails.fields.inspectorsSignature",
                                fieldValue: images?.find(
                                    (i) => i.reportFieldName === (fieldNameByCatalogId ?? newFieldCatalogIdMap[SiteInspectionCatalogIds.InspectorSignature]),
                                ),
                                isRequired: true,
                            },
                        },
                    ];
                }),
                ...getUnknownFieldsComponentsNRS({
                    report,
                    sharingId: sharedReportId,
                    excludedCatalogIds: Object.values(SiteInspectionCatalogIds),
                    oldFieldFallbackMap: oldFieldCatalogIdMap,
                    images,
                    forPdf: true,
                }),
            ],
        },
    ];
};
