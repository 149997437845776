import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { ChangeEvent, MouseEvent, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazySearchAvailableClientsQuery } from "src/store/authorisation/authorizationApi";
import { clientSwitcherSlice } from "src/store/clientSwitcher/clientSwitcherSlice";
import { useDebouncedCallback } from "use-debounce";

import { useInfiniteScroll } from "../../hooks/CommonHooks";
import AccessSelectors from "../../store/access/AccessSelectors";
import { setStoredSelectedClient } from "../../store/clientSwitcher/ClientSwitcherHooks";
import ClientSwitcherSelectors from "../../store/clientSwitcher/ClientSwitcherSelectors";
import { IAvailableClient } from "../../store/clientSwitcher/types";
import { RequestStatus } from "../../store/RequestStatus";

export const useClientSwitcherPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        results: availableClients,
        totalCount: availableClientsCount,
        skip,
        status: requestStatus,
    } = useSelector(ClientSwitcherSelectors.getAvailableClients);
    const { initialClientCount } = useSelector(ClientSwitcherSelectors.getClientTotalCount);
    const userId = useSelector(AccessSelectors.getUserId);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const listRef = useRef<HTMLDivElement>(null);

    const [inputValue, setInputValue] = useState("");
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [selectedClient, setSelectedClient] = useState<IAvailableClient>();
    const [searchAvailableClients] = useLazySearchAvailableClientsQuery();
    const selectClient = (e: MouseEvent) => {
        e.preventDefault();
        dispatch(clientSwitcherSlice.actions.selectClient({ client: selectedClient }));
        setStoredSelectedClient(selectedClient);
    };

    const resetClient = (e: MouseEvent) => {
        e.preventDefault();
        setSelectedClient(undefined);
    };

    const getClientsDebounced = useDebouncedCallback((value: string) => searchAvailableClients({ userId, searchQuery: value, skip: 0 }), 500);

    const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value.length && availableClients.length > 0) {
            setSelectedClient(undefined);
        }
        if (availableClients.length > 0) {
            dispatch(clientSwitcherSlice.actions.clearFilteredClients());
        }
        if (event.target.value.length > 1 && requestStatus !== RequestStatus.loading) {
            getClientsDebounced[0](event.target.value);
            setIsLoadingMore(false);
        }
        setInputValue(event.target.value);
    };
    const isInputTooShort = inputValue?.length < 2;
    const canDisplayList = initialClientCount <= 10 || (!isInputTooShort && availableClients.length > 0 && initialClientCount > 10);

    const clearSearchBox = () => {
        setInputValue("");
        setSelectedClient(undefined);
    };

    const counterText = useMemo(() => {
        if (initialClientCount <= 10) {
            return t("switcher.result.yourClients", { count: initialClientCount });
        }
        if (initialClientCount > 10 && availableClients.length === 0 && !isInputTooShort && requestStatus === RequestStatus.success) {
            return t("switcher.result.noResults");
        }
        if (initialClientCount > 10 && !isInputTooShort && availableClients.length > 0) {
            return t("switcher.result.searchResult", { count: availableClientsCount });
        }
        return "";
    }, [requestStatus, initialClientCount, isInputTooShort, availableClients, availableClientsCount, t]);

    useInfiniteScroll(listRef, () => {
        setIsLoadingMore(true);
        if (skip <= availableClients.length && requestStatus !== RequestStatus.loading) {
            searchAvailableClients({ userId, searchQuery: inputValue, skip: skip + 20 });
        }
    });

    return {
        handleOnSearchChange,
        clearSearchBox,
        selectClient,
        resetClient,
        setSelectedClient,
        selectedClient,
        initialClientCount,
        inputValue,
        counterText,
        listRef,
        availableClients,
        canDisplayList,
        isMobile,
        isLoadingMore,
        isInputTooShort,
        isRequestLoading: requestStatus === RequestStatus.loading,
        isRequestSuccess: requestStatus === RequestStatus.success,
    };
};
