import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/api/baseQuery";

import { Configuration, ConfigurationResponse, SeverityLevel } from "./types";

export const organizationApi = createApi({
    reducerPath: "organizationApi",
    baseQuery: baseQuery,
    tagTypes: ["ReportCategoriesConfiguration"],
    endpoints: (builder) => ({
        //TODO Define proper type
        getLocationClient: builder.query<any, { locationId: string }>({
            query: ({ locationId }) => ({
                url: `/v1/organization/ancestorVerticesUntilTargetVertexLabel/${locationId}/client/site,parentOf`,
                method: "GET",
                headers: {
                    "Cache-Control": "no-cache",
                },
            }),
        }),

        getReportCategoriesConfig: builder.query<ConfigurationResponse, { clientId: string }>({
            query: ({ clientId }) => ({
                url: `/v1/organization/${clientId}/configurations/guarding.reportCategories`,
                method: "GET",
            }),
            providesTags: ["ReportCategoriesConfiguration"],
        }),

        createReportCategoriesConfig: builder.mutation<void, { clientId: string; configuration: Configuration }>({
            query: ({ clientId, configuration }) => ({
                url: `/v1/organization/${clientId}/configurations/guarding.reportCategories`,
                method: "POST",
                body: configuration,
            }),
            invalidatesTags: ["ReportCategoriesConfiguration"],
        }),

        updateReportCategorySeverity: builder.mutation<void, { clientId: string; code: string; newSeverity: SeverityLevel }>({
            query: ({ clientId, code, newSeverity }) => ({
                url: `/v1/organization/${clientId}/configurations/guarding.reportCategories/configData/${code}`,
                method: "PATCH",
                body: {
                    severity: newSeverity,
                },
            }),
            invalidatesTags: ["ReportCategoriesConfiguration"],
        }),
    }),
});

export const {
    useLazyGetLocationClientQuery,
    useGetReportCategoriesConfigQuery,
    useCreateReportCategoriesConfigMutation,
    useUpdateReportCategorySeverityMutation,
} = organizationApi;
