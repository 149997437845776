import { DeviantDataType } from "../shared/types";

export const MAX_PEAK_DAYS_COUNT = 3;

export const WEEKDAYS_ORDER = ["1", "2", "3", "4", "5", "6", "0"];

export const TOOLTIPS_MAP: Record<DeviantDataType, string> = {
    [DeviantDataType.Callouts]: "insights.summary.deviantDayWidget.calloutsTooltip",
    [DeviantDataType.Events]: "insights.summary.deviantDayWidget.tooltip",
};
