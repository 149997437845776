import { WorkerExecResult } from "src/models/DbModel";
import { CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import { ReportTemplateEnum } from "src/models/ReportModel";
import { generateStringForQuery, getReportQueryTimeRangeCondition } from "src/sql/scripts/queryUtilities";

import { CategoryIncidentCountPerSiteBarchart } from "./Barchart.types";

type FetchIncidentsBarchartQueryProps = {
    startDate: Date;
    endDate: Date;
    siteId: string;
    level: number;
    parentCategory: string;
};

const LOCATION_ID_COLUMN_NAME = "location_id";
const BARCHART_TEMPLATE_TYPES = [ReportTemplateEnum.alarmResponse, ReportTemplateEnum.incident, ReportTemplateEnum.callout];

export const getIncidentsBarchartQuery = ({ startDate, endDate, siteId, level, parentCategory }: FetchIncidentsBarchartQueryProps) => {
    return {
        sql: `
            SELECT
                category_level${level},
                COUNT(*) AS count
            FROM reports
            WHERE ${getReportQueryTimeRangeCondition(startDate, endDate)}
                AND ${LOCATION_ID_COLUMN_NAME} = "${siteId}"
                ${level > 1 && parentCategory ? `AND category_level${level - 1} = "${parentCategory}"` : ""}
                AND category_level${level} IS NOT NULL
                AND category_level1 != "${CategoryLevel1Keys.internalSecuritas}"
                AND template IN (${generateStringForQuery(BARCHART_TEMPLATE_TYPES)})
            GROUP BY category_level${level}
        `,
        params: {},
    };
};

export const getIncidentCountQuery = ({
    startDate,
    endDate,
    categories,
    level,
    siteId,
}: {
    startDate: Date;
    endDate: Date;
    categories: string[];
    level: number;
    siteId: string;
}) => {
    return {
        sql: `
            SELECT
                COUNT(*) AS total_incidents
            FROM reports
            WHERE
                ${getReportQueryTimeRangeCondition(startDate, endDate)}
                AND ${LOCATION_ID_COLUMN_NAME} = "${siteId}"
                AND category_level${level} IN (${generateStringForQuery(categories)})
        `,
        params: {},
    };
};

export const parseIncidentsBarchartQueryResult = (execResult: WorkerExecResult | null): CategoryIncidentCountPerSiteBarchart[] => {
    if (execResult?.results?.[0]?.values?.length) {
        const { values } = execResult.results[0];

        return values.reduce((siteCategoriesMap, valueRow) => {
            siteCategoriesMap.push({
                categoryKey: valueRow[0],
                count: valueRow[1],
            });

            return siteCategoriesMap;
        }, []);
    }

    return [];
};

export const parseIncidentsCountQueryResult = (execResult: WorkerExecResult | null): number => {
    let parsedData = 0;

    if (execResult?.results?.[0]?.values?.length) {
        const { values } = execResult.results[0];
        parsedData = values[0][0];
    }

    return parsedData;
};
