import { Breakpoints, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { HorizontalLine } from "../../../../components/shared/HorizontalLine";
import { Widget } from "../shared/components";

export const WidgetStyled = styled(Widget)<{ withMinHeight?: boolean }>`
    container-type: inline-size;
`;

export const GridLayoutStyled = styled.div`
    align-content: flex-start;
    width: 100%;
    display: grid;
    column-gap: ${Spacing.S}px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: auto;

    ${HorizontalLine} {
        display: none;
    }

    @container (max-width: ${parseInt(Breakpoints.S) - 32}px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @container (max-width: ${parseInt(Breakpoints.XS) - 32}px) {
        display: flex;
        flex-direction: column;

        ${HorizontalLine} {
            display: block;
        }
    }
`;
