import { Text } from "@secuis/ccp-react-components";
import { formatTime } from "src/helpers/date";

type Props = {
    cell: string;
};

export const StartTimeCell = ({ cell }: Props) => {
    if (!cell) {
        return <Text small>-</Text>;
    }
    // This is a dummy date string, the time is the only thing that matters. Unfortunately, formatting the time without a date is not possible with the current implementation.
    const formattedTime = formatTime(`2000-10-10T${cell}`);
    return <Text small>{formattedTime}</Text>;
};
