import { skipToken } from "@reduxjs/toolkit/query";
import { ToasterContext } from "@secuis/ccp-react-components";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { featureFlags } from "src/data/featureFlags";
import { getSeverityLevelTranslationKey } from "src/helpers/SeverityLevelHelper";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { Databases } from "src/models/DbModel";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import ClientSwitcherSelectors from "src/store/clientSwitcher/ClientSwitcherSelectors";
import { useDatabase } from "src/store/insights/InsightsLoadHooks";
import {
    useCreateReportCategoriesConfigMutation,
    useGetReportCategoriesConfigQuery,
    useUpdateReportCategorySeverityMutation,
} from "src/store/organization/organizationApi";
import { Configuration, SeverityData, SeverityLevel } from "src/store/organization/types";
import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";
import { useReportSearch } from "src/store/reports/ReportsHooks";
import { reportsSlice } from "src/store/reports/reportsSlice";
import TrackingActions from "src/store/tracking/TrackingActions";

import { SeverityUpdateProps } from "../ClientSeverityLevel.types";

export const useClientSeverityLevelData = (): {
    severityData: SeverityData | undefined;
    isLoading: boolean;
    updateSeverityLevel: ({ category, previousSeverity, newSeverity }: SeverityUpdateProps) => Promise<void>;
} => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { execute } = useDatabase(Databases.Reports);
    const isClientSeverityLevelEditModeEnabled = useFeatureFlag(featureFlags.clientSeverityLevelEditMode);
    const { addToast } = useContext(ToasterContext);
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const { reportCategories } = useReportCategories();
    const [severityData, setSeverityData] = useState<SeverityData | undefined>(undefined);

    const [createReportCategoriesConfig] = useCreateReportCategoriesConfigMutation();
    const [updateReportCategorySeverity] = useUpdateReportCategorySeverityMutation();

    const { loadReports } = useReportSearch();

    const {
        data: configuration,
        isLoading: isConfigurationLoading,
        refetch,
    } = useGetReportCategoriesConfigQuery(selectedClient && isClientSeverityLevelEditModeEnabled ? { clientId: selectedClient.id } : skipToken);

    const createDefaultSeverityData = async (configuration: Configuration) => {
        if (!selectedClient || !isClientSeverityLevelEditModeEnabled) return;

        try {
            await createReportCategoriesConfig({ clientId: selectedClient.id, configuration }).unwrap();
        } catch (error) {
            console.error("Error creating configuration:", error);
        }
    };

    const defaultConfiguration = useMemo<Configuration | null>(() => {
        if (!reportCategories || !selectedClient) return null;

        const configData = reportCategories.reduce<SeverityData>((acc, level1Category) => {
            level1Category.level2Categories.forEach((level2Category) =>
                level2Category.level3Categories.forEach((level3Category) => {
                    acc[level3Category.code] = { severity: level3Category.severityLevel as SeverityLevel };
                }),
            );
            return acc;
        }, {});

        const timestamp = new Date().toISOString();

        return {
            entityId: selectedClient.id,
            entityType: "client",
            configData,
            createdAt: timestamp,
            updatedAt: timestamp,
        };
    }, [selectedClient, reportCategories]);

    useEffect(() => {
        if (!isConfigurationLoading) {
            if (configuration?.data?.configData) {
                setSeverityData(configuration.data.configData);
            } else if (defaultConfiguration) {
                setSeverityData(defaultConfiguration.configData);
                createDefaultSeverityData(defaultConfiguration);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuration, isConfigurationLoading, defaultConfiguration]);

    const showSeverityUpdateToastNotification = (categoryName: string, previousSeverity: string, newSeverity: string) => {
        addToast({
            title: t("settings.clientSeverityLevel.toast.severityLevelSaved.title"),
            type: "success",
            message: t("settings.clientSeverityLevel.toast.severityLevelSaved.message", {
                categoryName,
                previousSeverity,
                newSeverity,
            }),
        });
    };

    const trackUpdateSeverityLevelAction = (categoryKey: string, previousSeverity: string, newSeverity: string) => {
        dispatch(
            TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsLevelUpdate, {
                CategoryKey: categoryKey,
                PreviousSeverity: previousSeverity,
                NewSeverity: newSeverity,
            }),
        );
    };

    const updateSeverityLevelInDb = (categoryKey: string, newSeverity: string) => {
        const severityIndex = Object.values(SeverityLevel).indexOf(newSeverity as SeverityLevel) ?? null;

        execute("UPDATE reports SET severity_level = ? WHERE category_level3 = ?", [severityIndex, categoryKey]);
    };

    const updateSeverityLevel = async ({ category, previousSeverity, newSeverity }: SeverityUpdateProps) => {
        const { level2, level3, code } = category;

        const categoryName = [t(`incident.category.${level2}`), t(`incident.category.${level3}`)].filter(Boolean).join(" . ");

        try {
            await updateReportCategorySeverity({ clientId: selectedClient.id, code, newSeverity }).unwrap();

            refetch();
            dispatch(reportsSlice.actions.resetCurrentReport());
            updateSeverityLevelInDb(level3, newSeverity);

            setTimeout(() => {
                loadReports({ pageNumber: 0 });
            }, 800);

            const previousSeverityTranslation = t(getSeverityLevelTranslationKey(previousSeverity));
            const newSeverityTranslation = t(getSeverityLevelTranslationKey(newSeverity));

            trackUpdateSeverityLevelAction(level3, previousSeverityTranslation, newSeverityTranslation);

            showSeverityUpdateToastNotification(categoryName, previousSeverityTranslation, newSeverityTranslation);
        } catch (error) {
            console.error("Error updating severity:", error);
        }
    };

    return {
        severityData,
        isLoading: isConfigurationLoading,
        updateSeverityLevel,
    };
};
