import { getChartDomainMax } from "@secuis/ccp-react-components";
import { formatToRawDate } from "src/helpers/date";
import { CustomizedCategories } from "src/pages/Insights/Events/TrendingEvents/TrendingEvents.types";
import { ITrendingEvent } from "src/store/insights/InsightsModel";

export const buildRedirectUrl = (
    events: ITrendingEvent[],
    selectedCategories: CustomizedCategories,
    selectedRegions: string[],
    trendingEventsReportTypes: string[],
    startDate: Date,
) => {
    const selectedCategoriesLevel1 =
        selectedCategories?.level1Categories?.length > 0 ? `selectedCategoryOne=${selectedCategories.level1Categories.map((x) => x.value).join(",")}` : "";
    let selectedCategoriesLevel2 =
        selectedCategories?.level2Categories?.length > 0 ? `selectedCategoryTwo=${selectedCategories.level2Categories.map((x) => x.value).join(",")}` : "";
    const selectedCategoriesLevel3 =
        selectedCategories?.level3Categories?.length > 0 ? `selectedCategoryThree=${selectedCategories.level3Categories.map((x) => x.value).join(",")}` : "";
    if (
        !selectedCategories ||
        [...selectedCategories.level1Categories, ...selectedCategories.level2Categories, ...selectedCategories.level3Categories].length === 0
    ) {
        const fieldNames = Object.keys(events[0]).filter((k) => k !== "month");
        selectedCategoriesLevel2 = `selectedCategoryTwo=${fieldNames.join(",")}`;
    }

    return [
        `/reports?selectedRegions=${selectedRegions.toString()}`,
        `selectedReportTypes=${trendingEventsReportTypes.join(",")}`,
        `selectedStartDate=${formatToRawDate(startDate)}`,
        `selectedEndDate=${formatToRawDate(new Date())}`,
        selectedCategoriesLevel1,
        selectedCategoriesLevel2,
        selectedCategoriesLevel3,
    ].join("&");
};

export const getEventDomain = (events: ITrendingEvent[]) => {
    if (!events?.length) {
        return [0, 0];
    }

    const highestValue = Math.max(
        ...events.flatMap((event) => {
            const { month, ...dataPoints } = event;
            return Object.values(dataPoints);
        }),
    );

    const maxValue = getChartDomainMax(highestValue);

    return [0, maxValue];
};
