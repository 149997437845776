import {
    getFieldNameByCatalogId,
    getFieldValueByCatalogId,
    getFieldValueByFieldName,
    hasNrsField,
    isNrsReport,
} from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { ActivityLogFields, ActivityLogFieldsOld, oldFieldFallbackMap } from "../../../models/reportTemplates/ActivityLog";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { TextAreaSummaryField } from "../SharedComponents/TextArea";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "./DynamicTemplateUtils";

export const shouldRenderActivitiesSummary = (report: DynamicReportType, shouldRenderUnknownFields: boolean) => {
    const requiredFields = [
        ActivityLogFields.LoggedIntoTheJournal,
        ActivityLogFields.AdditionalDescription,
        ActivityLogFields.ColleagueDebrief,
        ActivityLogFields.PassDownReport,
        ActivityLogFields.OpenItems,
        ActivityLogFields.CommanderOnSite,
        ActivityLogFields.CustomerName,
    ];
    const hasNrsFields = requiredFields.some((catalogId) => hasNrsField(catalogId, report));

    return (
        hasNrsFields ||
        !!(
            shouldRenderUnknownFields ||
            requiredFields.find((catalogId) => getFieldValueByCatalogId(catalogId, report, oldFieldFallbackMap)) ||
            // NOTE: Job and JobId fileds do not have fieldTag
            getFieldValueByFieldName(ActivityLogFieldsOld.activityLogJob, report) ||
            getFieldValueByFieldName(ActivityLogFieldsOld.activityLogJobId, report)
        )
    );
};

export const shouldRenderDescription = (report: DynamicReportType) =>
    !isNrsReport(report) || hasNrsField(ActivityLogFields.Comments, report) || report.images?.length;

export const getActivityLogReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const unknownFields = getUnknownFieldsComponentsNRS({
        report,
        sharingId: sharedReportId,
        excludedCatalogIds: Object.values(ActivityLogFields),
        oldFieldFallbackMap,
    });
    const shouldRenderUnknownFields = unknownFields.some((field) => field.props?.isRequired || field.props?.fieldValue);
    return [
        ...getBaseReportConfig({ report, siteObject, sharedReportId }),
        conditionalRender(shouldRenderDescription(report), () => ({
            component: DynamicTemplateSection,
            props: {
                label: getFieldNameByCatalogId(ActivityLogFields.Comments, report) || "reportdetails.activities",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByCatalogId(ActivityLogFields.Comments, report, oldFieldFallbackMap),
                        sharedReportId: sharedReportId,
                        imagesData: report.images,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        })),
        conditionalRender(shouldRenderActivitiesSummary(report, shouldRenderUnknownFields), () => {
            return {
                component: DynamicTemplateSection,
                props: {
                    label: "reportdetails.detailedInfo.activitiesSummary",
                    collapsedLabel: "reportdetails.summary.moreDetails",
                    expandedLabel: "reportdetails.summary.lessDetails",
                },
                children: [
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(ActivityLogFields.LoggedIntoTheJournal, report) ||
                                "reportdetails.activityLog.wasTheActivityLoggedIntoTheJournal",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.LoggedIntoTheJournal, report, oldFieldFallbackMap),
                            mobileDirection: "column",
                            isRequired: hasNrsField(ActivityLogFields.LoggedIntoTheJournal, report),
                        },
                    },
                    {
                        component: TextAreaSummaryField,
                        props: {
                            title:
                                getFieldNameByCatalogId(ActivityLogFields.AdditionalDescription, report) || "reportdetails.activityLog.additionalDescription",
                            content: getFieldValueByCatalogId(ActivityLogFields.AdditionalDescription, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.AdditionalDescription, report),
                        },
                    },
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(ActivityLogFields.ColleagueDebrief, report) || "reportdetails.activityLog.debriefOfColleagueHappened",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.ColleagueDebrief, report, oldFieldFallbackMap),
                            mobileDirection: "column",
                            isRequired: hasNrsField(ActivityLogFields.ColleagueDebrief, report),
                        },
                    },
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName: getFieldNameByCatalogId(ActivityLogFields.PassDownReport, report) || "reportdetails.activityLog.passDownReportCompleted",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.PassDownReport, report, oldFieldFallbackMap),
                            mobileDirection: "column",
                            isRequired: hasNrsField(ActivityLogFields.PassDownReport, report),
                        },
                    },
                    {
                        component: TextAreaSummaryField,
                        props: {
                            title: getFieldNameByCatalogId(ActivityLogFields.OpenItems, report) || "reportdetails.activityLog.anyOpenItemsToBeAddressed",
                            content: getFieldValueByCatalogId(ActivityLogFields.OpenItems, report, oldFieldFallbackMap),
                            isRequired: hasNrsField(ActivityLogFields.OpenItems, report),
                        },
                    },
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName:
                                getFieldNameByCatalogId(ActivityLogFields.CommanderOnSite, report) || "reportdetails.activityLog.commanderPresenceOnSite",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.CommanderOnSite, report, oldFieldFallbackMap),
                            mobileDirection: "column",
                            isRequired: hasNrsField(ActivityLogFields.CommanderOnSite, report),
                        },
                    },
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName: getFieldNameByCatalogId(ActivityLogFields.CustomerName, report) || "reportdetails.activityLog.nameOfCustomer",
                            fieldValue: getFieldValueByCatalogId(ActivityLogFields.CustomerName, report, oldFieldFallbackMap),
                            mobileDirection: "column",
                            isRequired: hasNrsField(ActivityLogFields.CustomerName, report),
                        },
                    },
                    // NOTE: Job and JobId fileds do not have fieldTag
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName: "reportdetails.activityLog.job",
                            fieldValue: getFieldValueByFieldName(ActivityLogFieldsOld.activityLogJob, report),
                            mobileDirection: "column",
                            isRequired: false,
                        },
                    },
                    {
                        component: TextSummaryField,
                        props: {
                            fieldName: "reportdetails.activityLog.jobId",
                            fieldValue: getFieldValueByFieldName(ActivityLogFieldsOld.activityLogJobId, report),
                            mobileDirection: "column",
                            isRequired: false,
                        },
                    },
                    ...unknownFields,
                ],
            };
        }),
    ];
};
