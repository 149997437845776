export enum SeverityLevel {
    Info = "Info",
    Low = "Low",
    Moderate = "Moderate",
    High = "High",
    Severe = "Severe",
}

export type SeverityData = Record<string, { severity: SeverityLevel }>;

export type Configuration = {
    entityId: string;
    entityType: string;
    configType?: string;
    configData: SeverityData;
    createdAt: string;
    updatedAt: string;
};

export type ConfigurationResponse = {
    data: Configuration;
};
