import { Breakpoints, EmptyState, LoadingIndicator, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Modal } from "src/components/shared/Modal/Modal";
import { DataStatusProvider } from "src/helpers/dataStatus";

import { DraggableGrid } from "./DraggableGrid/DraggableGrid";
import { useSummary, useSurvicateInSummary } from "./Summary.hooks";
import { DraggableGridWrapper } from "./Summary.styles";
import { SummaryHeader } from "./SummaryHeader";

const SummaryBase = () => {
    const {
        gridDefaultLayouts,
        knownWidgetIds,
        isGridReady,
        isCustomized,
        isEditMode,
        isResetModalOpen,
        isLoading,
        hasData,
        toggleResetModal,
        toggleEditMode,
        handleSettingsSave,
        handleSettingsReset,
        handleGridLayoutChange,
        widgets,
        defaultLayoutConfig,
    } = useSummary();
    const { handleUserInteraction } = useSurvicateInSummary();
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <div onMouseMove={isMobile ? undefined : handleUserInteraction} onTouchStart={isMobile ? handleUserInteraction : undefined}>
            <SummaryHeader
                isEditMode={isEditMode}
                isCustomized={isCustomized}
                toggleEditMode={toggleEditMode}
                onSettingsSave={handleSettingsSave}
                onSettingsReset={toggleResetModal}
                isDisabled={!hasData || isLoading}
            />
            {isLoading && hasData !== true && (
                <Stack flex="1" justifyContent="center" pv="XXXL">
                    <LoadingIndicator size="L" />
                </Stack>
            )}
            {!isLoading && hasData === false && (
                <Stack flex="1" justifyContent="center" pv="XL">
                    <EmptyState icon="GraphIndicator" title={t("common.noDataToDisplay")} subtitle={t("insights.summary.emptyState.label")} />
                </Stack>
            )}
            {isGridReady && (
                <DraggableGridWrapper isVisible={hasData === true}>
                    <DraggableGrid
                        isEditable={isEditMode}
                        knownWidgetIds={knownWidgetIds}
                        widgets={widgets}
                        defaultLayoutConfig={defaultLayoutConfig}
                        layouts={gridDefaultLayouts}
                        onLayoutChange={handleGridLayoutChange}
                    />
                </DraggableGridWrapper>
            )}
            {isResetModalOpen && (
                <Modal
                    actions={[
                        {
                            children: t("insights.trendingEvents.resetModal.resetToDefault"),
                            onClick: handleSettingsReset,
                        },
                        {
                            children: t("common.cancel"),
                            onClick: toggleResetModal,
                        },
                    ]}
                    isOpen={isResetModalOpen}
                    onClose={toggleResetModal}
                    size="S"
                    title={t("insights.summary.settings.resetModal.title")}
                >
                    <Text>
                        <p>{t("insights.summary.settings.resetModal.description.1")}</p>
                        <p>{t("insights.summary.settings.resetModal.description.2")}</p>
                    </Text>
                </Modal>
            )}
        </div>
    );
};

export const Summary = () => (
    <DataStatusProvider>
        <SummaryBase />
    </DataStatusProvider>
);
