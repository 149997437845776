import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { getMonthName, setMonth } from "src/helpers/date";

import { LineChartTooltip } from "../../../../components/Insights/common/LineChartTooltip";
import { ChartLegendConfig } from "../../Events/TrendingEvents/Chart/Chart";

type Props = {
    label?: number;
    payload?: any[];
    chartLegendConfig: ChartLegendConfig[];
};

export const ExceptionTrendsChartTooltip = ({ label: monthNumber, payload, chartLegendConfig }: Props) => {
    const { t } = useTranslation();
    if (!payload || payload.length === 0) {
        return null;
    }

    const monthOffset = setMonth(new Date(), monthNumber - 1);
    const year = monthOffset.getFullYear();
    const month = capitalize(getMonthName(monthNumber));

    const items = payload
        .sort((a, b) => b.value - a.value)
        .map((data) => {
            const item = chartLegendConfig.find((x) => x.key === data.name);

            return {
                label: data.value,
                icon: item.iconVariant,
                color: item.color,
                value: t(`insights.exceptionTrend.${data.name}`),
            };
        });

    return <LineChartTooltip title={`${month}, ${year}`} data={items} />;
};
