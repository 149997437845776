import { ISiteObject, mapToSiteObject } from "./SiteObjectModel";

export interface IBaseLocation {
    id: string;
    label: string;
    name: string;
    sourceId: string;
    isDeleted: boolean;
}

export enum BaseLocationLabel {
    SiteObject = "siteObject",
    Client = "client",
    Group = "group",
}

export function mapToGraphNode(dto: any): ISiteObject | null {
    if (dto.label === BaseLocationLabel.SiteObject) {
        return mapToSiteObject(dto);
    }
    return null;
}
