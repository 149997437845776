import i18next from "i18next";

import { getWeekDayLabel } from "../../../../helpers/date/date";
import { STYLES } from "../shared/components/Graph/Graph.constants";
import { GraphData } from "../shared/components/Graph/Graph.types";
import { MAX_PEAK_DAYS_COUNT, WEEKDAYS_ORDER } from "./DeviantDayWidget.constants";

export const parseToChartData = (data: Record<string, number>, maxValue: number, tooltipSecondLine: string): GraphData => {
    if (!data) {
        return [];
    }

    const shouldHighlight = WEEKDAYS_ORDER.filter((item) => data[item] === maxValue).length <= MAX_PEAK_DAYS_COUNT;

    return WEEKDAYS_ORDER.map((dayKey) => {
        const totalEvents = data[dayKey] || 0;
        const label = getWeekDayLabel(dayKey, false);
        const name = getWeekDayLabel(dayKey);

        return {
            key: name,
            label: label,
            name: name,
            value: totalEvents,
            color: shouldHighlight && maxValue === totalEvents ? STYLES.barColor.highlight.default : STYLES.barColor.default.default,
            tooltip: [`${i18next.t("insights.summary.deviantDay")}: ${label}`, `${totalEvents} ${tooltipSecondLine}`],
        };
    });
};
