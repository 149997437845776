import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import i18next from "i18next";
import { createContext, FC, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { hasTokenExpired } from "../helpers/TokenHelper";
import { useSearchParam } from "../hooks/CommonHooks";
import { ChildrenProps } from "../models/ChildrenPropModel";
import { IUser } from "../models/UserModel";
import FailurePage from "../pages/Error/FailurePage";
import NoAccessPage from "../pages/Error/NoAccessPage";
import { useAccessToken } from "../store/access/AccessHooks";
import AppActions from "../store/app/AppActions";
import AppSelectors from "../store/app/AppSelectors";
import { useClientSwitcher } from "../store/clientSwitcher/ClientSwitcherHooks";
import { isFinished, isFinishedUnsuccessful, RequestStatus } from "../store/RequestStatus";
import { useHandleAppLanguage, useUserInfo } from "../store/user/UserHooks";
import { Loading } from "./shared/Loading";

interface IAuthContextValue {
    isAuthDataFetched: boolean;
    isError: boolean;
    isAccessDenied: boolean;
    isLegalNotAccepted: boolean;
    authorizationExpired: boolean;
    availableClientsCount: number;
    user: IUser | null;
}

const AuthContext = createContext({} as IAuthContextValue);

// this component is responsible for authorization: i.e. loading and verifying access token
// as well as loading user and user locations, which is needed in App Switcher
let AuthGate = ({ children }: ChildrenProps) => {
    const dispatch = useDispatch();
    const { errorInfo: appErrors } = useSelector(AppSelectors.getErrors);
    const { error: authError } = useAuth0();

    const hubType = useSearchParam("a");
    useEffect(() => {
        if (hubType) {
            const nativeHubType = "mys_app";
            const isHubNative = hubType === nativeHubType;
            dispatch(AppActions.setIsEmbeddedInNativeHub(isHubNative));
        }
    }, [hubType, dispatch]);

    // fetch available clients (client switcher)
    const { initialStatus: availableClientsStatus, initialClientCount: availableClientsCount, isReady: isSwitcherReady } = useClientSwitcher();
    // fetch token
    const { accessToken, status: accessRequestStatus } = useAccessToken();
    // fetch user
    const { info, invalidated: userInvalidated, isError: isUserRequestError } = useUserInfo();

    useHandleAppLanguage();

    const isError = useMemo(() => {
        return (
            !!authError ||
            isFinishedUnsuccessful(accessRequestStatus) ||
            isUserRequestError ||
            isFinishedUnsuccessful(accessRequestStatus) ||
            isFinishedUnsuccessful(availableClientsStatus)
        );
    }, [authError, accessRequestStatus, isUserRequestError, availableClientsStatus]);

    const isAuthDataFetched = useMemo(() => {
        const finishedLoadingUser = !userInvalidated;
        const clientsInitialized = isFinished(availableClientsStatus);
        return finishedLoadingUser && clientsInitialized && isSwitcherReady;
    }, [userInvalidated, availableClientsStatus, isSwitcherReady]);

    // when API call fails it may be due to Access Token being expired
    // we need to check for that and redirect to NoAccess
    const authorizationExpired = useMemo(() => {
        if (appErrors?.length && accessToken) {
            const lastError = appErrors[appErrors.length - 1];
            if (lastError.status === 401) {
                return hasTokenExpired(accessToken);
            }
        }
        return false;
    }, [accessToken, appErrors]);

    const isAccessDenied = useMemo((): boolean => {
        if (isAuthDataFetched) {
            const hasNotBeenInvited = !accessToken; // when token is null it means logged in user was not invited to use the application
            const hasNoAuthorizations = availableClientsStatus === RequestStatus.success && availableClientsCount === 0;
            const hasUserFailed = !info;

            return hasNotBeenInvited || hasNoAuthorizations || authorizationExpired || hasUserFailed;
        } else {
            return false;
        }
    }, [isAuthDataFetched, accessToken, authorizationExpired, info, availableClientsStatus, availableClientsCount]);

    const isLegalNotAccepted = useMemo((): boolean => {
        if (info) {
            const { termsOfUseDateAcceptedV3, privacyPolicyDateAcceptedV4 } = info.preferences;
            return !termsOfUseDateAcceptedV3 || !privacyPolicyDateAcceptedV4;
        } else {
            return false;
        }
    }, [info]);

    const contextValue: IAuthContextValue = useMemo(() => {
        return {
            isAuthDataFetched,
            isError,
            isAccessDenied,
            isLegalNotAccepted,
            authorizationExpired,
            availableClientsCount,
            user: info,
        };
    }, [isAuthDataFetched, isError, isAccessDenied, isLegalNotAccepted, authorizationExpired, info, availableClientsCount]);

    if (isError) {
        return <FailurePage />;
    }
    if (isAccessDenied) {
        return <NoAccessPage authorizationExpired={authorizationExpired} />;
    }
    if (!info?.id) {
        return <Loading size="L" />;
    }
    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);

if (process.env.REACT_APP_RUN_STANDALONE === "true") {
    (AuthGate as FC<ChildrenProps>) = withAuthenticationRequired(AuthGate, {
        returnTo: () => window.location.pathname,
        loginOptions: {
            language: i18next.language,
        },
    });
}

export default AuthGate;
