import { Segment } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { QaProps } from "src/models";

import { SegmentedControlStyled } from "./Switcher.styles";
import type { SwitcherOptions } from "./Switcher.types";

type Props = QaProps & {
    value?: string;
    options: SwitcherOptions;
    onChange: (value: string) => void;
};

export const Switcher = ({ value, options, onChange, "data-testid": dataTestId }: Props) => {
    const { t } = useTranslation();
    const activeOptionIndex = value ? options.findIndex((x) => x.value === value) : 0;

    const handleChange = (index: number) => {
        const activeOption = options[index];
        onChange(activeOption.value);
    };

    return (
        <SegmentedControlStyled data-testid={dataTestId} onChange={handleChange} value={activeOptionIndex}>
            {options.map((option) => (
                <Segment key={option.value} label={t(option.labelTranslationKey)} />
            ))}
        </SegmentedControlStyled>
    );
};
