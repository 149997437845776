import { useCallback, useEffect, useState } from "react";
import { PatrolTourType } from "src/models/ReportModel";
import { useLazyGetTourSessionQuery } from "src/store/reports/reportsApi";
import { useLazyGetTasksQuery } from "src/store/schedule/scheduleApi";
import { TaskSchedule } from "src/store/schedule/types";

export const useTasks = () => {
    const [getTasks, { isFetching, data: tasksData, isSuccess: isTaskSuccess }] = useLazyGetTasksQuery();
    const [getTourSession, { isFetching: isTourSessionFetching, isSuccess: isTourSuccess }] = useLazyGetTourSessionQuery();

    const [data, setData] = useState<(TaskSchedule & { children?: PatrolTourType[] })[]>([]);
    const [locationId, setLocationId] = useState("");

    const requestTasks = (date: string, locationId: string) => {
        setLocationId(locationId);
        getTasks({ date, locationId });
    };

    const updateTaskWithTour = useCallback(
        async (task: TaskSchedule) => {
            if (task.tourSessionId) {
                const { data: tour } = await getTourSession({ siteObjectId: locationId, reportId: task.tourSessionId });
                if (tour) {
                    return { ...task, children: [{ ...tour }] as PatrolTourType[] };
                }
                return task;
            }
            return task;
        },
        [getTourSession, locationId],
    );

    useEffect(() => {
        const asyncTourRequest = async () => {
            const result = await Promise.all(tasksData.map(updateTaskWithTour));
            setData(result);
        };
        if (tasksData) {
            asyncTourRequest();
        }
    }, [tasksData, updateTaskWithTour]);

    return {
        getTasks: requestTasks,
        isLoading: isFetching || isTourSessionFetching,
        isSuccess: isTaskSuccess || isTourSuccess,
        data,
    };
};
