export enum SiteInspectionFields {
    observationsByTheInspector = "observationsByTheInspector",
    actionsTaken = "actionTakenByTheInspector",
    officersUniformsAndAppearance = "officer’sUniform&Appearance",
    regulatoryCompliant = "regulatoryCompliant",
    postOrderAcknowledgment = "officerHasReadAndAcknowledgedPostOrders",
    postOrdersLastRevisionDate = "lastRevisionDateOfPostOrders",
    inspectionDate = "inspectionTimeAndDate",
    officerSignature = "officer'sSignature",
    inspectorSignature = "inspector'sSignature",
}

export enum SiteInspectionCatalogIds {
    ObservationsByTheInspector = "CAT_261",
    ActionTakenByTheTnspector = "CAT_262",
    OfficerUniformsAndAppearance = "CAT_257",
    RegulatoryCompliant = "CAT_258",
    OfficerHasReadAndAcknowledgedPostOrders = "CAT_259",
    LastRevisionDateOfPostOrders = "CAT_260",
    InspectionTimeAndDate = "CAT_256",
    OfficerSignature = "CAT_264",
    InspectorSignature = "CAT_265",
}

export const oldFieldCatalogIdMap: Record<SiteInspectionFields, SiteInspectionCatalogIds> = {
    [SiteInspectionFields.observationsByTheInspector]: SiteInspectionCatalogIds.ObservationsByTheInspector,
    [SiteInspectionFields.actionsTaken]: SiteInspectionCatalogIds.ActionTakenByTheTnspector,
    [SiteInspectionFields.officersUniformsAndAppearance]: SiteInspectionCatalogIds.OfficerUniformsAndAppearance,
    [SiteInspectionFields.regulatoryCompliant]: SiteInspectionCatalogIds.RegulatoryCompliant,
    [SiteInspectionFields.postOrderAcknowledgment]: SiteInspectionCatalogIds.OfficerHasReadAndAcknowledgedPostOrders,
    [SiteInspectionFields.postOrdersLastRevisionDate]: SiteInspectionCatalogIds.LastRevisionDateOfPostOrders,
    [SiteInspectionFields.inspectionDate]: SiteInspectionCatalogIds.InspectionTimeAndDate,
    [SiteInspectionFields.officerSignature]: SiteInspectionCatalogIds.OfficerSignature,
    [SiteInspectionFields.inspectorSignature]: SiteInspectionCatalogIds.InspectorSignature,
};

export const newFieldCatalogIdMap: Record<SiteInspectionCatalogIds, SiteInspectionFields> = Object.fromEntries(
    Object.entries(oldFieldCatalogIdMap).map(([key, value]) => [value, key]),
) as Record<SiteInspectionCatalogIds, SiteInspectionFields>;
