import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getLettersFromNumber } from "src/helpers/graphs";
import { compactNumber } from "src/helpers/NumberHelper";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";
import { SitePageTabLabel } from "src/pages/Insights/shared/types";

import { SiteCellStyled, SiteIconStyled, TextSiteNameStyled, TextTotalStyled } from "./SiteCell.styles";

export type SiteCellProps = {
    index: number;
    id: string;
    name: string;
    total: number;
};

export const SiteCell = ({ index, id, name, total }: SiteCellProps) => {
    const { t } = useTranslation();
    const isNotLargeScreen = useHasMaxWidth(Breakpoints.L);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const alphabeticalIndex = getLettersFromNumber(index);
    const { isFilterActive } = useInsightsPageContext();
    const navigate = useNavigate();

    const cellActionProps = {
        onClick: () =>
            navigate(`/sites/site/${id}`, {
                state: {
                    originActiveTab: SitePageTabLabel.Events,
                },
            }),
    };

    return (
        <SiteCellStyled className="siteCell" {...cellActionProps}>
            <Stack alignItems="center" justifyContent="space-between" direction={isNotLargeScreen ? "column" : "row"}>
                <Stack alignItems="center">
                    <SiteIconStyled color="white" size="M" variant="BuildingFilled" />
                    <TextSiteNameStyled
                        data-site-name={name}
                        data-site-index={alphabeticalIndex}
                        truncate
                        data-testid="matrix-table-site-cell-name"
                        isFilterActive={isFilterActive}
                    >
                        {isMobile ? alphabeticalIndex : name}
                    </TextSiteNameStyled>
                </Stack>
                <TextTotalStyled color="secondary" small data-testid="matrix-table-site-cell-total">
                    {t("incidentMatrix.table.total")}: {compactNumber(total)}
                </TextTotalStyled>
            </Stack>
        </SiteCellStyled>
    );
};
