import { useCallback, useEffect, useMemo, useState } from "react";
import type { Layouts } from "react-grid-layout";
import { useDataStatus } from "src/helpers/dataStatus";
import { SURVICATE_EVENTS, useSurvicate } from "src/helpers/survicate";
import { useTrackEvent } from "src/hooks/TrackingHooks";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import { UserPreference } from "src/models/UserModel";
import { useSetUserPreference, useUserPreference } from "src/store/user/UserHooks";

import { featureFlags } from "../../../data/featureFlags";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import { useHasMobileServices } from "../../../store/siteObjects/SiteObjectsHooks";
import {
    DEVIANT_DAY_WIDGET,
    DEVIANT_HOUR_WIDGET,
    OLD_WIDGETS_IDS,
    SUMMARY_WIDGETS,
    SUMMARY_WIDGETS_PLACEMENT,
    SUMMARY_WITH_MOBILE_WIDGETS,
    WIDGETS_WITH_CALLOUTS_PLACEMENT,
} from "./Summary.constants";
import { deserializeSettings, serializeSettings } from "./Summary.helpers";
import type { SummaryGridItem, SummaryItemPlacement, SummarySettings } from "./Summary.types";

export const useSummarySettings = () => {
    const [isReady, setIsReady] = useState(false);
    const [summarySettings, setSummarySettings] = useState<SummarySettings | undefined>();
    const [userSummarySettingsMonth] = useUserPreference(UserPreference.InsightsSummarySettingsMonth);
    const [userSummarySettings] = useUserPreference(UserPreference.InsightsSummarySettings);
    const setUserPreference = useSetUserPreference();

    useEffect(() => {
        // NOTE: We switched from view mode to datepicker, hence we don't need to store settings per month and day,
        // and we can simplify the implementation.
        // Nevertheless, for some time we need to be backwards compatible and handle user settings stored the old way.
        const userSettings = deserializeSettings(userSummarySettings);
        const oldWayUserSettings = deserializeSettings(userSummarySettingsMonth);

        setSummarySettings(userSettings ?? oldWayUserSettings);
        setIsReady(true);
    }, [userSummarySettingsMonth, userSummarySettings]);

    const saveSummarySettings = useCallback(() => {
        setUserPreference(UserPreference.InsightsSummarySettings, serializeSettings(summarySettings));
        setTimeout(() => setUserPreference(UserPreference.InsightsSummarySettingsMonth, ""), 1000);
    }, [setUserPreference, summarySettings]);

    return {
        summarySettings,
        setSummarySettings,
        saveSummarySettings,
        isReady,
    };
};

export const useSummary = () => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false);
    const [summarySettingsSnapshot, setSummarySettingsSnapshot] = useState<SummarySettings>();
    const { isLoading, hasData } = useDataStatus();
    const { summarySettings, setSummarySettings, saveSummarySettings, isReady } = useSummarySettings();
    const trackEvent = useTrackEvent();
    const { widgets, defaultLayoutConfig } = useSummaryWidgets();

    const toggleEditMode = useCallback(() => {
        if (isEditMode) {
            setSummarySettings(summarySettingsSnapshot);
            setIsEditMode(false);
        } else {
            trackEvent(MixpanelEvent.KPISummaryCustomizeClick);
            setSummarySettingsSnapshot(summarySettings);
            setIsEditMode(true);
        }
    }, [trackEvent, isEditMode, summarySettingsSnapshot, summarySettings, setSummarySettings]);

    const toggleResetModal = useCallback(() => {
        setIsResetModalOpen((prevValue) => !prevValue);
    }, []);

    const handleSettingsSave = useCallback(() => {
        saveSummarySettings();
        setIsEditMode(false);
        trackEvent(MixpanelEvent.KPISummaryCustomizeSave);
    }, [trackEvent, saveSummarySettings]);

    const handleSettingsReset = useCallback(() => {
        setSummarySettings(undefined);
        toggleResetModal();
        trackEvent(MixpanelEvent.KPISummaryCustomizeReset);
    }, [trackEvent, setSummarySettings, toggleResetModal]);

    const handleGridLayoutChange = useCallback((layouts: Layouts, widgetIds: string[]) => setSummarySettings({ layouts, widgetIds }), [setSummarySettings]);

    const knownWidgetIds = useMemo(() => {
        if (summarySettings?.layouts) {
            return summarySettings?.widgetIds ?? OLD_WIDGETS_IDS;
        }
    }, [summarySettings]);

    return {
        gridDefaultLayouts: summarySettings?.layouts,
        knownWidgetIds,
        isGridReady: isReady,
        isCustomized: !!summarySettings,
        isEditMode,
        isResetModalOpen,
        toggleResetModal,
        toggleEditMode,
        handleSettingsSave,
        handleSettingsReset,
        handleGridLayoutChange,
        isLoading,
        hasData,
        defaultLayoutConfig,
        widgets,
    };
};

export const useSurvicateInSummary = () => {
    const [shouldOpenSurvey, setShouldOpenSurvey] = useState(false);
    const survicate = useSurvicate();

    const handleUserInteraction = useMemo(() => (shouldOpenSurvey ? undefined : () => setShouldOpenSurvey(true)), [setShouldOpenSurvey, shouldOpenSurvey]);

    useEffect(() => {
        if (survicate && shouldOpenSurvey) {
            survicate.invokeEvent(SURVICATE_EVENTS.insights.enterSummary);
        }
    }, [survicate, shouldOpenSurvey]);

    return { handleUserInteraction };
};

const useSummaryWidgets = (): { widgets: SummaryGridItem[]; defaultLayoutConfig: SummaryItemPlacement[] } => {
    const { hasMobileServices } = useHasMobileServices();
    const deviantCalloutsEnabled = useFeatureFlag(featureFlags.deviantCallouts);

    return useMemo(() => {
        let widgets!: SummaryGridItem[];
        let defaultLayoutConfig!: SummaryItemPlacement[];

        // Workaround way of modifying the version of the widget conditionally
        if (hasMobileServices) {
            widgets = [
                ...SUMMARY_WITH_MOBILE_WIDGETS.slice(0, -2),
                {
                    ...DEVIANT_DAY_WIDGET,
                    version: deviantCalloutsEnabled ? 2 : 1,
                },
                {
                    ...DEVIANT_HOUR_WIDGET,
                    version: deviantCalloutsEnabled ? 2 : 1,
                },
            ];
            defaultLayoutConfig = WIDGETS_WITH_CALLOUTS_PLACEMENT;
        } else {
            widgets = SUMMARY_WIDGETS;
            defaultLayoutConfig = SUMMARY_WIDGETS_PLACEMENT;
        }
        return { widgets, defaultLayoutConfig };
    }, [deviantCalloutsEnabled, hasMobileServices]);
};
