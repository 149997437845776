import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import _union from "lodash/union";
import { parseStringToInteger } from "src/helpers/NumberHelper";
import { mapDbReport } from "src/helpers/ReportHelper";
import { DynamicReportType } from "src/models/ReportModel";

import { commentApi } from "../reportComments/commentApi";
import { RequestStatus } from "../RequestStatus";
import { REPORTS_INITIAL_STATE } from "./constants";
import { reportsApi } from "./reportsApi";
import { IReportSearchState } from "./types";

const isGetReportPending = isAnyOf(
    reportsApi.endpoints.getReport.matchPending,
    reportsApi.endpoints.getTourSession.matchPending,
    reportsApi.endpoints.getSharedReport.matchPending,
    reportsApi.endpoints.getSharedTourSession.matchPending,
);
const isGetReportFulfilled = isAnyOf(
    reportsApi.endpoints.getReport.matchFulfilled,
    reportsApi.endpoints.getTourSession.matchFulfilled,
    reportsApi.endpoints.getSharedReport.matchFulfilled,
    reportsApi.endpoints.getSharedTourSession.matchFulfilled,
);
const isGetReportRejected = isAnyOf(
    reportsApi.endpoints.getReport.matchRejected,
    reportsApi.endpoints.getTourSession.matchRejected,
    reportsApi.endpoints.getSharedReport.matchRejected,
    reportsApi.endpoints.getSharedTourSession.matchRejected,
);

export const reportsSlice = createSlice({
    name: "reports",
    initialState: REPORTS_INITIAL_STATE,
    reducers: {
        setReportTranslationPending: (state, action: PayloadAction<{ id: string; translationLoadingPending: boolean }>) => {
            state.translationLoadingPending[action.payload.id] = action.payload.translationLoadingPending;
        },
        setLoadingMultiple: (state, action: PayloadAction<boolean>) => {
            state.reportImages.loadingMultiple = action.payload;
        },
        setReportAutotranslationsSection: (state, action: PayloadAction<{ sectionId: string; isAnythingAutoTranslated?: boolean }>) => {
            const { sectionId, ...params } = action.payload;
            const currentState = state.autoTranslations.sections[sectionId];
            state.autoTranslations.sections[sectionId] = { ...currentState, ...params };
        },
        setIsSingleReportPage: (state, action: PayloadAction<boolean>) => {
            state.isSingleReportPage = action.payload;
        },
        resetCurrentReport: (state) => {
            state.currentReport = {
                id: null,
                report: null,
                status: RequestStatus.undefined,
                error: null,
                relatedReports: [],
                relatedReportsStatus: [],
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(commentApi.endpoints.createComment.matchFulfilled, (state, action) => {
                Object.keys(state.reportSearchByKey).forEach((key) => {
                    state.reportSearchByKey[key].reports.forEach((r) => {
                        if (r.id === action.meta.arg.originalArgs.reportId) {
                            const commentsCount = parseStringToInteger(r.commentsCount);
                            r.commentsCount = `${commentsCount + 1}`;
                        }
                    });
                });
            })
            .addMatcher(commentApi.endpoints.deleteComment.matchFulfilled, (state, action) => {
                Object.keys(state.reportSearchByKey).forEach((key) => {
                    state.reportSearchByKey[key].reports.forEach((r) => {
                        if (r.id === action.meta.arg.originalArgs.reportId) {
                            const commentsCount = parseStringToInteger(r.commentsCount);
                            r.commentsCount = `${Math.max(commentsCount - 1, 0)}`;
                        }
                    });
                });
            })
            .addMatcher(isGetReportPending, (state, action) => {
                state.currentReport.id = action.meta.arg.originalArgs.reportId;
                state.currentReport.report = null;
                state.currentReport.status = RequestStatus.loading;
                state.currentReport.error = null;
            })
            .addMatcher(isGetReportFulfilled, (state, action) => {
                state.currentReport.report = action.payload.shareId
                    ? { ...action.payload, report: mapDbReport(action.payload.report) }
                    : mapDbReport(action.payload as DynamicReportType);
                state.currentReport.id = state.currentReport.report?.id;
                state.currentReport.status = RequestStatus.success;
                state.error = null;
                state.translationLoadingPending[state.currentReport.report.id] = true;
                Object.keys(state.reportSearchByKey).forEach((key) => {
                    state.reportSearchByKey[key].reports.forEach((r) => {
                        if (r.id === action.payload.id) {
                            r.visitorIds = _union(action.payload.visitorIds, r.visitorIds);
                            r.commentsCount = `${action.payload.commentsCount}`;
                        }
                    });
                });
            })
            .addMatcher(isGetReportRejected, (state, action) => {
                state.currentReport.status = RequestStatus.error;
                state.currentReport.error = action.payload?.status ?? action.error;
            })
            .addMatcher(reportsApi.endpoints.fetchReportsFromSearch.matchPending, (state, action) => {
                const current: IReportSearchState = state.reportSearchByKey[action.meta.arg.originalArgs.key] || { reports: [] };
                current.status = RequestStatus.loading;
                current.timestampRequested = action.meta.arg.originalArgs.timestampRequested;
                current.refreshing = true;
                current.size = action.meta.arg.originalArgs.pageSize;
                current.page = action.meta.arg.originalArgs.pageNumber;
                state.reportSearchByKey = {
                    [action.meta.arg.originalArgs.key]: current,
                };
            })
            .addMatcher(reportsApi.endpoints.fetchReportsFromSearch.matchFulfilled, (state, action) => {
                const current: IReportSearchState = state.reportSearchByKey[action.meta.arg.originalArgs.key];
                if (!current || action.meta.arg.originalArgs.timestampRequested < current.timestampRequested) {
                    // received a response from some previous request, eg. caused by slow internet & change of selected objects or refreshing in the meantime. So disregard this response
                    return;
                }

                if (action.payload.page === 0) {
                    current.reports = [...action.payload.reports];
                } else {
                    const filteredReports = action.payload.reports.filter((a) => !current.reports.find((b) => a.id === b.id));
                    const reports = current.reports.concat(filteredReports);

                    current.reports = reports;
                }

                current.status = RequestStatus.success;
                current.refreshing = false;
                current.page = action.payload.page;
                current.size = action.payload.size;
                current.totalCount = action.payload.totalCount;
                state.reportSearchByKey[action.meta.arg.originalArgs.key] = { ...current };
                state.totalCount = action.payload.totalCount;
            })
            .addMatcher(reportsApi.endpoints.fetchReportsFromSearch.matchRejected, (state, action) => {
                const current: IReportSearchState = state.reportSearchByKey[action.meta.arg.originalArgs.key];
                if (!current || action.meta.arg.originalArgs.timestampRequested < current.timestampRequested) {
                    // received a response from some previous request, eg. caused by slow internet & change of selectedobjects or refreshing in the meantime. So disregard this response
                    return;
                }
                current.status = RequestStatus.error;
                current.refreshing = false;
                state.reportSearchByKey[action.meta.arg.originalArgs.key] = current;
            })
            .addMatcher(reportsApi.endpoints.getRelatedReport.matchPending, (state, action) => {
                state.currentReport.relatedReportsStatus = [
                    ...state.currentReport.relatedReportsStatus,
                    { status: RequestStatus.loading, error: undefined, exceptionReportId: action.meta.arg.originalArgs.reportId },
                ];
                if (state.currentReport?.report?.id !== action.meta.arg.originalArgs.reportId) {
                    state.currentReport.report.relatedReports = [];
                    state.currentReport.relatedReportsStatus = [];
                }
            })
            .addMatcher(reportsApi.endpoints.getRelatedReport.matchFulfilled, (state, action) => {
                if (state?.currentReport?.report?.relatedReports) {
                    state.currentReport.report.relatedReports = [...(state.currentReport?.report?.relatedReports ?? null), action.payload];
                    state.currentReport.relatedReportsStatus = [
                        ...state.currentReport.relatedReportsStatus.filter((x) => x.exceptionReportId !== action.payload.id),
                        { status: RequestStatus.success, error: undefined, exceptionReportId: action.payload.id },
                    ];
                    state.translationLoadingPending[action.payload.id] = true;
                    Object.keys(state.reportSearchByKey).forEach((key) => {
                        state.reportSearchByKey[key].reports.forEach((r) => {
                            if (r.id === action.payload.id) {
                                r.visitorIds = _union(action.payload.visitorIds, r.visitorIds);
                                r.commentsCount = `${action.payload.commentsCount}`;
                            }
                        });
                    });
                }
            })
            .addMatcher(reportsApi.endpoints.getRelatedReport.matchRejected, (state, action) => {
                state.currentReport.relatedReportsStatus = [
                    ...state.currentReport.relatedReportsStatus.filter((x) => x.exceptionReportId !== action.meta.arg.originalArgs.reportId),
                    { status: RequestStatus.error, error: action.payload, exceptionReportId: action.meta.arg.originalArgs.reportId },
                ];
            })
            .addMatcher(reportsApi.endpoints.asyncGeneratePieChart.matchFulfilled, (state, action) => {
                if (state.currentReport.report) {
                    state.currentReport.report.pdfPieChart = action.payload;
                }
            })
            .addMatcher(reportsApi.endpoints.getImageByPath.matchPending, (state, action) => {
                const imagePath = action.meta.arg.originalArgs.imageData[action.meta.arg.originalArgs.size];
                state.reportImages.byImagePath[imagePath] = {
                    reportFieldName: action.meta.arg.originalArgs.imageData.reportFieldName,
                    image: null,
                    loading: true,
                    error: null,
                    imageId: action.meta.arg.originalArgs.imageData.imageId,
                };
            })
            .addMatcher(reportsApi.endpoints.getImageByPath.matchFulfilled, (state, action) => {
                state.reportImages.byImagePath[action.payload.imagePath].loading = false;
                state.reportImages.byImagePath[action.payload.imagePath].image = action.payload.imageUri;
            })
            .addMatcher(reportsApi.endpoints.getImageByPath.matchRejected, (state, action) => {
                const imagePath = action.meta.arg.originalArgs.imageData[action.meta.arg.originalArgs.size];
                state.reportImages.byImagePath[imagePath].loading = false;
                state.reportImages.byImagePath[imagePath].error = action.payload;
            })
            .addMatcher(reportsApi.endpoints.trackReportVisit.matchPending, (state, action) => {
                Object.keys(state.reportSearchByKey).forEach((key) => {
                    state.reportSearchByKey[key].reports.forEach((r) => {
                        if (r.id === action.meta.arg.originalArgs.reportId) {
                            if (!r.visitorIds.includes(action.meta.arg.originalArgs.userId)) {
                                r.visitorIds.push(action.meta.arg.originalArgs.userId);
                            }
                        }
                    });
                });
            })
            .addMatcher(
                (action) => reportsApi.endpoints.trackReportUnvisit.matchPending(action) || reportsApi.endpoints.trackTourSessionUnvisit.matchPending(action),
                (state, action) => {
                    Object.keys(state.reportSearchByKey).forEach((key) => {
                        state.reportSearchByKey[key].reports.forEach((r) => {
                            if (r.id === action.meta.arg.originalArgs.reportId && r.visitorIds.includes(action.meta.arg.originalArgs.userId)) {
                                r.visitorIds = r.visitorIds.filter((id) => id !== action.meta.arg.originalArgs.userId);
                            }
                        });
                    });
                },
            )
            .addMatcher(reportsApi.endpoints.trackTourSessionVisit.matchPending, (state, action) => {
                Object.keys(state.reportSearchByKey).forEach((key) => {
                    state.reportSearchByKey[key].reports.forEach((r) => {
                        if (r.id === action.meta.arg.originalArgs.reportId) {
                            if (!r.visitorIds.includes(action.meta.arg.originalArgs.userId)) {
                                r.visitorIds.push(action.meta.arg.originalArgs.userId);
                            }
                        }
                    });
                });
            })
            .addMatcher(reportsApi.endpoints.getTranslatedText.matchPending, (state, action) => {
                state.dynamicTranslations[action.meta.arg.originalArgs.text] = {
                    translation: null,
                    loading: true,
                    error: null,
                };
            })
            .addMatcher(reportsApi.endpoints.getTranslatedText.matchFulfilled, (state, action) => {
                let translation: string | null = null;
                const sourceText = action.meta.arg.originalArgs.text;
                const targetLanguage = action.meta.arg.originalArgs.targetLanguage;
                const detectedLanguage = action.payload.detectedLanguage?.language;
                const detectionScore = action.payload.detectedLanguage?.score;

                if (detectedLanguage && targetLanguage !== detectedLanguage && detectionScore >= 0.99) {
                    translation = action.payload.translations[0].text;
                }

                state.dynamicTranslations[sourceText].translation = translation;
                state.dynamicTranslations[sourceText].loading = false;

                for (const key in state.translationLoadingPending) {
                    state.translationLoadingPending[key] = false;
                }
            })
            .addMatcher(reportsApi.endpoints.getTranslatedText.matchRejected, (state, action) => {
                state.dynamicTranslations[action.meta.arg.originalArgs.text].loading = false;
                state.dynamicTranslations[action.meta.arg.originalArgs.text].error = true;
                for (const key in state.translationLoadingPending) {
                    state.translationLoadingPending[key] = false;
                }
            });
    },
});
