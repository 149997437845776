import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "../RequestStatus";
import { commentApi } from "./commentApi";
import { DEFAULT_COMMENTS_ENTRY_STATE, DEFAULT_COMMENTS_STATE } from "./constants";
import { mapComment } from "./models";

export const commentSlice = createSlice({
    name: "reportComments",
    initialState: DEFAULT_COMMENTS_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(commentApi.endpoints.getReportComments.matchPending, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const entry = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                entry.fetchStatus = RequestStatus.loading;
                state.byReportKey[key] = entry;
            })
            .addMatcher(commentApi.endpoints.getReportComments.matchFulfilled, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const entry = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                try {
                    entry.comments = action.payload.map(mapComment);
                    entry.fetchStatus = RequestStatus.success;
                } catch {
                    entry.comments = [];
                    entry.fetchStatus = RequestStatus.invalid;
                }
                state.byReportKey[key] = entry;
            })
            .addMatcher(commentApi.endpoints.getReportComments.matchRejected, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const entry = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                entry.fetchStatus = RequestStatus.error;
                entry.comments = [];
                state.byReportKey[key] = entry;
            })
            .addMatcher(commentApi.endpoints.createComment.matchPending, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const reportComments = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                reportComments.createState = { status: RequestStatus.loading };
                state.byReportKey[key] = reportComments;
            })
            .addMatcher(commentApi.endpoints.createComment.matchFulfilled, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const reportComments = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                reportComments.createState = { status: RequestStatus.success, id: action.payload.id };
                const commentContent = action.meta.arg.originalArgs.body.comment; //the BE returns escaped content by default and we don't want it
                reportComments.comments = [...reportComments.comments, { ...mapComment(action.payload), content: commentContent }];
                state.byReportKey[key] = reportComments;
            })
            .addMatcher(commentApi.endpoints.createComment.matchRejected, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const reportComments = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                reportComments.createState = { status: RequestStatus.error, id: action.meta.arg.originalArgs.body.id };
                state.byReportKey[key] = reportComments;
            })
            .addMatcher(commentApi.endpoints.deleteComment.matchFulfilled, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const reportComments = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                if (!reportComments || !reportComments?.comments?.length) {
                    //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
                    return;
                }
                const comments = reportComments.comments.filter((c) => c.id !== action.meta.arg.originalArgs.body.id);
                reportComments.comments = comments;
            })
            .addMatcher(commentApi.endpoints.updateComment.matchPending, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const reportComments = state.byReportKey[key];
                if (!reportComments || !reportComments?.comments?.length) {
                    //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
                    return;
                }
                const commentId = action.meta.arg.originalArgs.body.id;
                const comment = reportComments?.comments?.find((c) => c.id === commentId);
                if (!comment) {
                    return;
                }
                comment.updateStatus = RequestStatus.loading;
            })
            .addMatcher(commentApi.endpoints.updateComment.matchFulfilled, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const reportComments = state.byReportKey[key];
                if (!reportComments || !reportComments?.comments?.length) {
                    //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
                    return;
                }
                const commentId = action.meta.arg.originalArgs.body.id;
                const comment = reportComments.comments.find((c) => c.id === commentId);
                if (!comment) {
                    return;
                }
                const commentContent = action.meta.arg.originalArgs.body.comment; //the BE returns escaped content by default and we don't want it
                const updated = mapComment(action.payload);
                updated.content = commentContent;
                updated.updateStatus = RequestStatus.success;
                Object.keys(updated).forEach(function (key) {
                    comment[key] = updated[key];
                });
            })
            .addMatcher(commentApi.endpoints.updateComment.matchRejected, (state, action) => {
                const key = action.meta.arg.originalArgs.reportId;
                const reportComments = state.byReportKey[key] || { ...DEFAULT_COMMENTS_ENTRY_STATE };
                if (!reportComments || !reportComments?.comments?.length) {
                    //the updated comment is not in the store - shouldn't happen but disregard the response, comments will be reloaded
                    return;
                }
                const commentId = action.meta.arg.originalArgs.body.id;
                const comment = reportComments?.comments?.find((c) => c.id === commentId);
                if (!comment) {
                    return;
                }
                comment.updateStatus = RequestStatus.error;
            });
    },
});
