import { Breakpoints, Color, Spacing, Stack, Theme } from "@secuis/ccp-react-components";
import { ReactComponent as CheckpointIcon } from "src/assets/icons/checkpoints_icon.svg";
import { TableComponent } from "src/components/shared/Table/Table";
import styled, { css } from "styled-components";

export const LocationCellStyled = styled(Stack)`
    max-width: 100%;
    padding-right: ${Spacing.XS}px;
    @media (max-width: ${Breakpoints.XS}) {
        max-width: calc(60vw - ${Spacing.L}px);
    }
`;

export const indexCellStyles = css`
    width: ${Spacing.XL}px;
`;

export const mobileIndexCellStyles = css`
    grid-column: 3;
    width: unset;
    padding-bottom: ${Spacing.XXS}px;
    justify-content: end;
`;

export const locationCellStyles = css`
    grid-column: 1/3;
    padding-bottom: ${Spacing.XS}px;
`;

export const headerStyles = css`
    display: none;
`;

export const StyledTable = styled(TableComponent)<{ isFilterActive: boolean }>`
    grid-template-columns: minmax(${Spacing.XL}px, 360px) repeat(3, 1fr) ${Spacing.XL}px;

    @media (max-width: ${Breakpoints.M}) {
        grid-template-columns: minmax(${Spacing.XL}px, 264px) repeat(3, 1fr) ${Spacing.XL}px;
    }

    @media (max-width: ${Breakpoints.S}) {
        grid-template-columns: ${({ isFilterActive }) =>
            isFilterActive ? `repeat(3, 1fr)` : `calc(148px + 16px) repeat(3, minmax(48px, 1fr)) ${Spacing.XL}px`};
    }

    @media (max-width: ${Breakpoints.XS}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const CheckpointIconStyled = styled(CheckpointIcon).attrs<{ color?: Color }>((props) => ({
    color: props.color ?? "primary",
}))`
    path {
        fill: ${({ color }) => Theme[color]};
    }
`;
