import { combineReducers, configureStore, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { apiMiddleware } from "redux-api-middleware";

import { apiAccessMiddleware, apiAuthMiddleware, apiBodyMiddleware, apiErrorMiddleware, apiRefreshMiddleware, trackingMiddleware } from "./middleware";
import AccessReducer from "./store/access/AccessReducer";
import AppReducer from "./store/app/AppReducer";
import { authorizationApi } from "./store/authorisation/authorizationApi";
import { clientSwitcherSlice } from "./store/clientSwitcher/clientSwitcherSlice";
import FeatureFlagsReducer from "./store/featureFlags/FeatureFlagsReducer";
import FilterReducer from "./store/filter/FilterReducer";
import { insightsApi } from "./store/insights/insightsApi";
import InsightsReducer from "./store/insights/InsightsReducer";
import { locationsSlice } from "./store/locations/locationsSlice";
import { organizationApi } from "./store/organization/organizationApi";
import ReportCategoriesReducer from "./store/reportCategories/ReportCategoriesReducer";
import { commentApi } from "./store/reportComments/commentApi";
import { commentSlice } from "./store/reportComments/commentSlice";
import { exportReportsToExcelSlice } from "./store/reports/exportReportsToExcelSlice";
import { reportsApi } from "./store/reports/reportsApi";
import { reportsSlice } from "./store/reports/reportsSlice";
import { scheduleApi } from "./store/schedule/scheduleApi";
import { scheduleSlice } from "./store/schedule/scheduleSlice";
import { siteObjectsSlice } from "./store/siteObjects/siteObjectsSlice";
import SitesReducer from "./store/sites/SitesReducer";
import { tourSessionsApi } from "./store/tourSessions/tourSessionsApi";
import { tourSessionsSlice } from "./store/tourSessions/tourSessionsSlice";
import { userApi } from "./store/user/userApi";
import { userSlice } from "./store/user/userSlice";
import { usersSlice } from "./store/users/usersSlice";

const reducers = {
    access: AccessReducer,
    app: AppReducer,
    filter: FilterReducer,
    featureFlags: FeatureFlagsReducer,
    insights: InsightsReducer,
    locations: locationsSlice.reducer,
    reports: reportsSlice.reducer,
    schedule: scheduleSlice.reducer,
    reportComments: commentSlice.reducer,
    reportCategories: ReportCategoriesReducer,
    user: userSlice.reducer,
    users: usersSlice.reducer,
    siteObjects: siteObjectsSlice.reducer,
    clientSwitcher: clientSwitcherSlice.reducer,
    sites: SitesReducer,
    exportReportsSlice: exportReportsToExcelSlice.reducer,
    tourSessions: tourSessionsSlice.reducer,
};

const apiReducers = {
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [insightsApi.reducerPath]: insightsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [authorizationApi.reducerPath]: authorizationApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [tourSessionsApi.reducerPath]: tourSessionsApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
};

const reducer = {
    ...reducers,
    ...apiReducers,
};

const combinedReducer = combineReducers(reducer);

export type IState = StateFromReducersMapObject<typeof reducer>;

export const rootReducer = (state: IState, action) => {
    if (action.type === clientSwitcherSlice.actions.resetClients.type) {
        const { access, app, featureFlags, user } = state;
        const newState = combinedReducer(undefined, action);
        return { ...newState, access, user, app, featureFlags };
    }
    return combinedReducer(state, action);
};

const middleware = [
    scheduleApi.middleware,
    reportsApi.middleware,
    insightsApi.middleware,
    authorizationApi.middleware,
    organizationApi.middleware,
    userApi.middleware,
    tourSessionsApi.middleware,
    commentApi.middleware,
    apiBodyMiddleware,
    apiAccessMiddleware,
    apiAuthMiddleware,
    apiRefreshMiddleware,
    apiErrorMiddleware,
    apiMiddleware,
    trackingMiddleware,
];

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ serializableCheck: false }).concat(...middleware);
    },
});
