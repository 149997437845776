import { Palette } from "@secuis/ccp-react-components";

import { CalloutResponseTimeWidget, CalloutResponseTimeWidgetStatic } from "./CalloutResponseTimeWidget";
import { CalloutsPerformanceWidget, CalloutsPerformanceWidgetStatic } from "./CalloutsPerformanceWidget";
import { DeviantDayWidget, DeviantDayWidgetStatic } from "./DeviantDayWidget";
import { DeviantHourWidget, DeviantHourWidgetStatic } from "./DeviantHourWidget";
import { IncidentCategoriesWidget, IncidentCategoriesWidgetStatic } from "./IncidentCategoriesWidget";
import { IncidentDistributionWidget, IncidentDistributionWidgetStatic } from "./IncidentDistributionWidget";
import { PerformanceIndicatorsWidget, PerformanceIndicatorsWidgetStatic } from "./PerformanceIdicatorsWidget";
import { ReportsCountWidget, ReportsCountWidgetStatic } from "./ReportsCountWidget";
import { SummaryWidgetId } from "./shared/types";
import { SiteEventsWidget, SiteEventsWidgetStatic } from "./SiteEventsWidget";
import { SiteReportsWidget, SiteReportsWidgetStatic } from "./SiteReportsWidget";
import { SummaryGridItem, SummaryItemPlacement, WidgetViewModeOption } from "./Summary.types";

export const SEPARATOR_COLOR = `${Palette.Navy300}4d`;

export const WIDGET_VIEW_MODE_OPTIONS: WidgetViewModeOption[] = [
    {
        labelTranslationKey: "insights.summary.viewMode.most",
        value: "most",
    },
    {
        labelTranslationKey: "insights.summary.viewMode.least",
        value: "least",
    },
];

const TOURS_INDICATORS_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.ToursPerformanceIndicators,
    Content: PerformanceIndicatorsWidget,
    Placeholder: PerformanceIndicatorsWidgetStatic,
    width: 3,
    responsiveSizing: {
        lg: { height: 1 },
        xs: { height: 1 },
        xxs: { height: 2 },
    },
};
const REPORTS_COUNT_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.ReportsCount,
    width: 1,
    height: 3,
    Content: ReportsCountWidget,
    Placeholder: ReportsCountWidgetStatic,
};
const SITE_REPORTS_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.SiteReports,
    width: 1,
    height: 3,
    Content: SiteReportsWidget,
    Placeholder: SiteReportsWidgetStatic,
};
const SITE_EVENTS_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.SiteEvents,
    width: 1,
    height: 3,
    Content: SiteEventsWidget,
    Placeholder: SiteEventsWidgetStatic,
};
const EVENT_CATEGORIES_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.EventCategories,
    width: 1,
    height: 3,
    Content: IncidentCategoriesWidget,
    Placeholder: IncidentCategoriesWidgetStatic,
};
const EVENT_DISTRIBUTION_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.EventDistribution,
    width: 1,
    height: 3,
    Content: IncidentDistributionWidget,
    Placeholder: IncidentDistributionWidgetStatic,
};
export const DEVIANT_DAY_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.DeviantDay,
    width: 1,
    height: 3,
    Content: DeviantDayWidget,
    Placeholder: DeviantDayWidgetStatic,
};
export const DEVIANT_HOUR_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.DeviantHour,
    width: 2,
    height: 3,
    Content: DeviantHourWidget,
    Placeholder: DeviantHourWidgetStatic,
};
const CALLOUTS_INDICATORS_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.CalloutsPerformanceIndicators,
    Content: CalloutsPerformanceWidget,
    Placeholder: CalloutsPerformanceWidgetStatic,
    width: 3,
    responsiveSizing: {
        lg: { height: 1 },
        xs: { height: 2 },
        xxs: { height: 3 },
    },
};
const CALLOUTS_RESPONSE_WIDGET: SummaryGridItem = {
    id: SummaryWidgetId.CalloutsResponse,
    Content: CalloutResponseTimeWidget,
    Placeholder: CalloutResponseTimeWidgetStatic,
    height: 1,
    responsiveSizing: {
        lg: { width: 1 },
        xs: { width: 2 },
        xxs: { width: 1 },
    },
};

export const SUMMARY_WIDGETS: SummaryGridItem[] = [
    TOURS_INDICATORS_WIDGET,
    REPORTS_COUNT_WIDGET,
    SITE_REPORTS_WIDGET,
    SITE_EVENTS_WIDGET,
    EVENT_CATEGORIES_WIDGET,
    EVENT_DISTRIBUTION_WIDGET,
    DEVIANT_DAY_WIDGET,
    DEVIANT_HOUR_WIDGET,
];

export const SUMMARY_WITH_MOBILE_WIDGETS: SummaryGridItem[] = [
    CALLOUTS_INDICATORS_WIDGET,
    CALLOUTS_RESPONSE_WIDGET,
    {
        ...TOURS_INDICATORS_WIDGET,
        width: 2,
        responsiveSizing: {
            lg: { height: 1 },
            xs: { height: 1 },
            xxs: { height: 2 },
        },
    },
    REPORTS_COUNT_WIDGET,
    SITE_REPORTS_WIDGET,
    SITE_EVENTS_WIDGET,
    EVENT_CATEGORIES_WIDGET,
    EVENT_DISTRIBUTION_WIDGET,
    DEVIANT_DAY_WIDGET,
    DEVIANT_HOUR_WIDGET,
];

export const OLD_WIDGETS_IDS = SUMMARY_WIDGETS.map(({ id }) => id);

export const SUMMARY_WIDGETS_PLACEMENT: SummaryItemPlacement[] = [
    { id: SummaryWidgetId.ToursPerformanceIndicators, x: 0, y: 0 },
    { id: SummaryWidgetId.ReportsCount, x: 0, y: 1 },
    { id: SummaryWidgetId.SiteReports, x: 1, y: 1 },
    { id: SummaryWidgetId.SiteEvents, x: 2, y: 1 },
    { id: SummaryWidgetId.EventCategories, x: 0, y: 4 },
    { id: SummaryWidgetId.EventDistribution, x: 1, y: 4 },
    { id: SummaryWidgetId.DeviantDay, x: 2, y: 4 },
    { id: SummaryWidgetId.DeviantHour, x: 0, y: 7 },
];

export const WIDGETS_WITH_CALLOUTS_PLACEMENT: SummaryItemPlacement[] = [
    { id: SummaryWidgetId.CalloutsPerformanceIndicators, x: 0, y: 0 },
    { id: SummaryWidgetId.CalloutsResponse, x: 0, y: 1 },
    { id: SummaryWidgetId.ToursPerformanceIndicators, x: 1, y: 1 },
    { id: SummaryWidgetId.ReportsCount, x: 0, y: 2 },
    { id: SummaryWidgetId.SiteReports, x: 1, y: 2 },
    { id: SummaryWidgetId.SiteEvents, x: 2, y: 2 },
    { id: SummaryWidgetId.EventCategories, x: 0, y: 5 },
    { id: SummaryWidgetId.EventDistribution, x: 1, y: 5 },
    { id: SummaryWidgetId.DeviantDay, x: 2, y: 5 },
    { id: SummaryWidgetId.DeviantHour, x: 0, y: 8 },
];
