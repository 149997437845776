import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";

import { featureFlags } from "../data/featureFlags";
import { checkIfSiteHasMobileServices } from "../helpers";
import { ISiteObject } from "../models/SiteObjectModel";
import { TrackedSiteTagsLowerCase, UserType } from "../models/tracking/tracking.model";
import ClientSwitcherSelectors from "../store/clientSwitcher/ClientSwitcherSelectors";
import LocationsSelectors from "../store/locations/LocationsSelectors";
import TrackingActions from "../store/tracking/TrackingActions";
import UserSelectors from "../store/user/UserSelectors";
import { useFeatureFlag } from "./featureFlags";

export const useTracking = () => {
    const dispatch = useDispatch();
    const [trackingInitialized, setTrackingInitialized] = useState(false);
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const isClientUser = useSelector(ClientSwitcherSelectors.getIsClientUser);
    const { siteObjects } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const { info: user } = useSelector(UserSelectors.getUserInfo);
    const siteTagsTrackingEnabled = useFeatureFlag(featureFlags.siteTagsTracking);

    const userType = useMemo(() => {
        if (user.isEmployee) {
            return UserType.Internal;
        }
        if (isClientUser) {
            return UserType.Global;
        }
        if (siteObjects.length > 1) {
            return UserType.MultiSite;
        }
        if (siteObjects.length === 1) {
            return UserType.SingleSite;
        }
        return null;
    }, [user, isClientUser, siteObjects]);

    const initializeTracking = useCallback(() => {
        if (trackingInitialized) {
            return;
        }

        dispatch(TrackingActions.initializeTracking(selectedClient, userType));
        setTrackingInitialized(true);
        const sites = siteObjects.reduce(
            (
                result: Array<{
                    countryCode: string;
                    siteIds: string[];
                    mobileService: boolean;
                    siteTags: string[];
                }>,
                site: ISiteObject,
            ) => {
                const country = site.countryCode;
                const isMobileGuardingSite = checkIfSiteHasMobileServices(site);
                const trackedSiteTags = getSortedTrackedSiteTags(site.siteTags);
                const existingEntry = result.find(
                    (item) =>
                        item.countryCode === country &&
                        item.mobileService === isMobileGuardingSite &&
                        (!siteTagsTrackingEnabled || (item.siteTags ?? []).join(",") === trackedSiteTags.join(",")),
                );

                if (existingEntry) {
                    existingEntry.siteIds.push(site.id);
                } else {
                    const groupedSites = {
                        countryCode: country,
                        siteIds: [site.id],
                        mobileService: isMobileGuardingSite,
                        siteTags: siteTagsTrackingEnabled ? trackedSiteTags : null,
                    };
                    result.push(groupedSites);
                }
                return result;
            },
            [],
        );

        TrackingActions.sendReachAndAdoption(dispatch, user.id, selectedClient.id, selectedClient.name, userType, sites);
    }, [dispatch, user, selectedClient, siteObjects, trackingInitialized, userType, siteTagsTrackingEnabled]);

    return { initializeTracking, trackingInitialized };
};

const getSortedTrackedSiteTags = (siteTags: string[]) => {
    return [...(siteTags || []).filter((tag) => TrackedSiteTagsLowerCase.includes(tag.toLowerCase()))].sort((a, b) => a.localeCompare(b));
};

export const useTrackEvent = () => {
    const dispatch = useDispatch();

    return useCallback(
        (event: MixpanelEvent, properties?: unknown) => {
            dispatch(TrackingActions.trackEvent(event, properties));
        },
        [dispatch],
    );
};
