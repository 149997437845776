import { createSelector } from "reselect";
import { isSameDay } from "src/helpers/date";

import { UNGROUPED_ITEMS_VALUE } from "../../components/Reports/ReportsFilter";
import { countriesByRegions, regionsByCountry } from "../../data/regions";
import { IState } from "../../Store";
import { defaultFilterState } from "./InsightsReducer";

const mapFilterState = ({ insights: { filterState } }: IState) => filterState;
const mapDamageReports = ({ insights: { damageReportsState } }: IState) => damageReportsState;
const mapIncidentsLocationsState = ({ insights: { incidentsLocationsState } }: IState) => incidentsLocationsState;
const mapDatabasesState = ({ insights: { databasesState } }: IState) => databasesState;
const mapCriticalCardsState = ({ insights: { criticalCardsState } }: IState) => criticalCardsState;
const mapGroups = ({ locations: { groups } }: IState) => groups;
const mapSelectedTabState = ({ insights: { selectedTab } }: IState) => selectedTab;
const mapSummaryState = ({ insights: { summaryState } }: IState) => summaryState;
const mapIncidentMatrixState = ({ insights: { incidentMatrixState } }: IState) => incidentMatrixState;

const getSelectedRegions = createSelector([mapFilterState], (filterState) => filterState.selectedRegions);
const getSelectedGroups = createSelector([mapFilterState], (filterState) => filterState.selectedGroups);
const getSelectedStartDate = createSelector([mapFilterState], (filterState) => filterState.selectedStartDate);
const getSelectedEndDate = createSelector([mapFilterState], (filterState) => filterState.selectedEndDate);
const getSelectedTab = createSelector([mapSelectedTabState], (selectedTab) => selectedTab);
const getClientReportsStatus = createSelector([mapSummaryState], (summaryState) => summaryState.hasData);
const getIncidentMatrixCategory = createSelector([mapIncidentMatrixState], (incidentMatrixState) => incidentMatrixState.category);
const getIncidentMatrixLevel = createSelector([mapIncidentMatrixState], (incidentMatrixState) => incidentMatrixState.level);
const getIncidentMatrixIsExpanded = createSelector([mapIncidentMatrixState], (incidentMatrixState) => incidentMatrixState.isExpanded);
const getAllFiltersToSave = createSelector([mapFilterState], (filterState) => {
    const isDefaultDateRange =
        isSameDay(filterState.selectedStartDate, defaultFilterState.selectedStartDate) &&
        isSameDay(filterState.selectedEndDate, defaultFilterState.selectedEndDate);
    return {
        selectedLocations: filterState.selectedRegions,
        selectedGroups: filterState.selectedGroups,
        selectedStartDate: isDefaultDateRange ? null : filterState.selectedStartDate,
        selectedEndDate: isDefaultDateRange ? null : filterState.selectedEndDate,
    };
});
const getDamageReports = createSelector([mapDamageReports], (damageReportsState) => damageReportsState);
const getIncidentsLocations = createSelector([mapIncidentsLocationsState], (incidentsLocations) => incidentsLocations);
const getDatabasesState = (id: string) => createSelector([mapDatabasesState], (databasesState) => databasesState[id]);
const getAllDatabasesState = createSelector([mapDatabasesState], (databasesState) => databasesState);
const getCriticalCards = createSelector([mapCriticalCardsState], (cardsState) => cardsState);
const getCriticalCardsPage = createSelector([mapCriticalCardsState], ({ page }) => page);

const getSelectedFilterLocations = createSelector([mapFilterState, mapGroups], (filterState, groups) => {
    const allCountries = Object.keys(regionsByCountry);
    const allRegions = countriesByRegions.map((r) => r.name);
    const locations = filterState.selectedRegions.filter(
        (region) =>
            !(region === UNGROUPED_ITEMS_VALUE || groups.some((group) => group.id === region)) || allCountries.includes(region) || allRegions.includes(region),
    );
    const locationsSet = new Set(locations);
    return [...locationsSet];
});

export default {
    getSelectedRegions,
    getSelectedGroups,
    getSelectedStartDate,
    getSelectedEndDate,
    getAllFiltersToSave,
    getDamageReports,
    getIncidentsLocations,
    getDatabasesState,
    getAllDatabasesState,
    getCriticalCards,
    getCriticalCardsPage,
    getSelectedFilterLocations,
    getSelectedTab,
    getClientReportsStatus,
    getIncidentMatrixCategory,
    getIncidentMatrixLevel,
    getIncidentMatrixIsExpanded,
};
