export enum TaskReportFieldsOld {
    taskDescription = "taskDescription",
}

export enum TaskReportFields {
    Description = "CAT_302",
}

export const oldFieldFallbackMap = {
    [TaskReportFieldsOld.taskDescription]: TaskReportFields.Description,
};
