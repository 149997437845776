import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDataNotifier } from "src/helpers/dataStatus";

import { useReducedIndicatorValues, useSummaryPeriod } from "../shared/hooks";

export const usePerformanceIndicatorsWidget = () => {
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { values: currentValues, isLoading: isLoadingCurrent } = useReducedIndicatorValues(currentPeriod);
    const { values: previousValues, isLoading: isLoadingPrevious } = useReducedIndicatorValues(previousPeriod);
    const { notifyData, notifyLoading } = useDataNotifier();
    const isLoading = useMemo(() => isLoadingCurrent || isLoadingPrevious, [isLoadingCurrent, isLoadingPrevious]);

    useEffect(() => {
        notifyLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const hasData = !!(currentValues.completedTours || previousValues.completedTours || currentValues.uncompletedTours || previousValues.uncompletedTours);
        notifyData(hasData);
    }, [currentValues, previousValues]);

    const indicators = [
        {
            title: t("insights.summary.performanceIndicators.completedTours"),
            value: currentValues.completedTours,
            prevValue: previousValues.completedTours,
        },
        {
            title: t("insights.summary.performanceIndicators.uncompletedTours"),
            value: currentValues.uncompletedTours,
            prevValue: previousValues.uncompletedTours,
        },
    ];

    return { indicators, isLoading };
};
