import { getFieldNameByCatalogId, getFieldValueByCatalogId, hasNrsField } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { oldFieldFallbackMap, TaskReportFields } from "../../../../models/reportTemplates/Task";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { conditionalRender, getUnknownFieldsComponentsNRS } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getTaskReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => {
    const unknownFields = getUnknownFieldsComponentsNRS({
        report,
        sharingId: sharedReportId,
        excludedCatalogIds: Object.values(TaskReportFields),
        oldFieldFallbackMap,
        images,
        forPdf: true,
    });
    // Check isRequired to show NRS fields and reqired fields and check fieldValue
    const shouldRenderUnknownFields = unknownFields.some((field) => field.props?.isRequired || field.props?.fieldValue);
    const shouldRenderDescription =
        getFieldValueByCatalogId(TaskReportFields.Description, report, oldFieldFallbackMap) ||
        hasNrsField(TaskReportFields.Description, report) ||
        report.images.length > 0;

    return [
        ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
        conditionalRender(shouldRenderDescription, () => ({
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: getFieldNameByCatalogId(TaskReportFields.Description, report) || "reportdetails.detailedInfo.taskReportDescription",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByCatalogId(TaskReportFields.Description, report, oldFieldFallbackMap),
                        isRequired: hasNrsField(TaskReportFields.Description, report),
                        shouldAutoTranslate: true,
                        images,
                    },
                },
            ],
        })),
        conditionalRender(shouldRenderUnknownFields, () => {
            return {
                component: SummarySectionPdf,
                props: {
                    label: "reportdetails.detailedInfo.taskReportSummary",
                },
                children: unknownFields,
            };
        }),
    ];
};
