import { Breakpoints, LineChartDataset, useHasMaxWidth } from "@secuis/ccp-react-components";
import { sumBy } from "lodash";
import { useEffect, useMemo } from "react";
import { useDataNotifier } from "src/helpers/dataStatus";

import { featureFlags } from "../../../../data/featureFlags";
import { useFeatureFlag } from "../../../../hooks/featureFlags";
import { UserPreference } from "../../../../models/UserModel";
import { useSqlQuery } from "../../../../sql/hooks";
import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { useGetCalloutsByHourQuery, useGetTourExceptionsHourlySummaryQuery } from "../../../../store/insights/insightsApi";
import { useHasMobileServices } from "../../../../store/siteObjects/SiteObjectsHooks";
import { useDeviantChartDataType, useSummaryPeriod } from "../shared/hooks";
import { DeviantDataType, SummaryWidgetId } from "../shared/types";
import { TOOLTIPS_MAP } from "./DeviantHourWidget.constants";
import { createWholeDayHourArray, getTopHourLabel, getTopHours } from "./DeviantHourWidget.helpers";
import { getDeviantHoursQuery, parseDeviantHours } from "./DeviantHourWidget.queries";

export const useDeviantHourWidget = () => {
    const { currentPeriod } = useSummaryPeriod();
    const isMobile = useHasMaxWidth(Breakpoints.S);
    const { siteIds } = useFilteredSites();
    const { notifyData, notifyLoading } = useDataNotifier();

    const { allowSwitch, handleDataTypeChange, dataType } = useDeviantChartDataType(UserPreference.DeviantHoursDataType, SummaryWidgetId.DeviantHour);

    const { hourEvents, isLoading: isLoadingEvents } = useEventsData(siteIds, currentPeriod.start, currentPeriod.end);
    const { hourCallouts, isLoading: isLoadingCallouts } = useCalloutsData(siteIds, currentPeriod.start, currentPeriod.end);

    const dataSource = useMemo(() => (dataType === DeviantDataType.Callouts ? hourCallouts : hourEvents), [dataType, hourCallouts, hourEvents]);

    const deviantHoursData = useMemo(() => {
        const deviantHours = getTopHours(dataSource);

        return {
            deviantHours,
            topHourLabel: getTopHourLabel(deviantHours),
            total: sumBy(dataSource, "value") ?? 0,
            hoursChartTicks: (dataSource ?? []).map(({ key }) => key),
            chartData: [
                {
                    id: "deviantHours",
                    values: dataSource ?? [],
                    highlights: deviantHours.length <= 3 ? deviantHours : [],
                },
            ] satisfies LineChartDataset[],
        };
    }, [dataSource]);

    const isLoading = useMemo(
        () => (dataType === DeviantDataType.Callouts ? isLoadingCallouts : isLoadingEvents),
        [dataType, isLoadingEvents, isLoadingCallouts],
    );

    useEffect(() => {
        notifyLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const hasData = !!deviantHoursData.total;
        notifyData(hasData);
    }, [deviantHoursData]);

    return {
        ...deviantHoursData,
        handleDataTypeChange,
        dataType,
        allowSwitch,
        isLoading: dataType === DeviantDataType.Callouts ? isLoadingCallouts : isLoadingEvents,
        isEmpty: !deviantHoursData.total,
        xAxisTicks: isMobile ? deviantHoursData.hoursChartTicks.filter((_, index) => [0, 6, 12, 18, 23].includes(index)) : deviantHoursData.hoursChartTicks,
        tooltip: TOOLTIPS_MAP[dataType],
    };
};

const useEventsData = (siteIds: string[], startDate: Date, endDate: Date) => {
    const areDeviantCalloutsEnabled = useFeatureFlag(featureFlags.deviantCallouts);
    const { queryResult: eventsPerHour, isLoading: isLoadingReports } = useSqlQuery(getDeviantHoursQuery, parseDeviantHours, {
        siteIds,
        startDate,
        endDate,
        excludeCallouts: areDeviantCalloutsEnabled,
    });
    const { exceptionsPerHour, isLoading: isLoadingExceptions } = useExceptionsPerHour(siteIds, startDate, endDate);
    const { calloutsPerHour, isLoading: isLoadingCallouts } = useCalloutsPerHour(siteIds, startDate, endDate);

    const hourEventsCount = useMemo(
        () =>
            createWholeDayHourArray(
                (hourKey) =>
                    (exceptionsPerHour[hourKey] ?? 0) +
                    (eventsPerHour?.[hourKey]?.totalEvents ?? 0) +
                    (areDeviantCalloutsEnabled ? calloutsPerHour?.[hourKey] ?? 0 : 0),
            ),
        [exceptionsPerHour, eventsPerHour, calloutsPerHour, areDeviantCalloutsEnabled],
    );

    return {
        hourEvents: hourEventsCount,
        isLoading: isLoadingReports || isLoadingExceptions || isLoadingCallouts,
    };
};

const useCalloutsData = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { calloutsPerHour, isLoading: isLoadingCallouts } = useCalloutsPerHour(siteIds, startDate, endDate);
    const hourCallouts = useMemo(() => createWholeDayHourArray((hourKey) => calloutsPerHour?.[hourKey]), [calloutsPerHour]);

    return {
        hourCallouts,
        isLoading: isLoadingCallouts,
    };
};

const useExceptionsPerHour = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { data, isLoading } = useGetTourExceptionsHourlySummaryQuery({
        siteIds,
        fromDate: startDate,
        toDate: endDate,
        groupHours: false,
    });

    const exceptionsPerHour = useMemo(
        () =>
            (data ?? []).reduce(
                (acc, { count, hours }) => {
                    const [hour] = hours.split(":");
                    acc[hour] = (acc[hour] ?? 0) + count;

                    return acc;
                },
                {} as Record<string, number>,
            ),
        [data],
    );

    return {
        exceptionsPerHour,
        isLoading,
    };
};

const useCalloutsPerHour = (siteIds: string[], startDate: Date, endDate: Date) => {
    const { hasMobileServices } = useHasMobileServices();
    const calloutsEnabled = useFeatureFlag(featureFlags.deviantCallouts) && hasMobileServices;
    const { data, isFetching } = useGetCalloutsByHourQuery(
        {
            siteIds,
            fromDate: startDate,
            toDate: endDate,
        },
        { skip: !calloutsEnabled },
    );

    const calloutsPerHour: Record<string, number> = useMemo(
        () =>
            calloutsEnabled
                ? (data ?? []).reduce((map, item) => {
                      map[item.hour.toString().padStart(2, "0")] = item.count;

                      return map;
                  }, {})
                : {},
        [data, calloutsEnabled],
    );

    return {
        calloutsPerHour,
        isLoading: isFetching,
    };
};
