import { ReportTemplateEnum } from "src/models/ReportModel";

import { parseSqliteResult } from "../../../../helpers/parseSqliteResult";
import { WorkerExecResult } from "../../../../models/DbModel";
import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";
import {
    getDateTimeFieldName,
    getParameterizedArray,
    getReportQueryTimeRangeCondition,
    getSqlStringifiedArrayFromObject,
} from "../../../../sql/scripts/queryUtilities";

export const getDeviantHoursQuery = ({
    siteIds,
    startDate,
    endDate,
    excludeCallouts,
}: {
    siteIds: string[];
    startDate: Date;
    endDate: Date;
    excludeCallouts: boolean;
}) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);
    const dateFieldName = getDateTimeFieldName();

    // NOTE: call-out reports requested in separate api call
    return {
        sql: `
            SELECT strftime('%H', ${dateFieldName}) as hour, count(id) as totalEvents
            FROM reports
            WHERE category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND ${getReportQueryTimeRangeCondition(startDate, endDate)}
                AND location_id IN (${stringifiedSites})
                ${excludeCallouts ? `AND template <> "${ReportTemplateEnum.callout}"` : ""}
            GROUP BY hour
            ORDER BY hour ASC
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseDeviantHours = (execResult: WorkerExecResult | null) => {
    return parseSqliteResult<{ totalEvents: number }>(execResult?.results?.[0], true);
};
