import { createHourLabelForDate, formatTime, set } from "src/helpers/date";
import { HourItem } from "./DeviantHourWidget.types";
import { maxBy } from "lodash";

export const formatXAxisTick = (hour: string): string => createHourLabelForDate(set(new Date(), { hours: parseInt(hour), minutes: 0 }));

export const getTopHourLabel = (deviantHours: string[]): string | undefined => {
    return deviantHours.length === 1
        ? formatTime(
              set(new Date(), {
                  hours: parseInt(deviantHours[0]),
                  minutes: 0,
              }),
          )
        : undefined;
};

export const getTopHours = (hourEvents: HourItem[]): string[] => {
    const highestEventsCount = maxBy(hourEvents, "value")?.value;
    const topHours = hourEvents.filter(({ value }) => highestEventsCount && value === highestEventsCount).map(({ key }) => key);

    return topHours ?? [];
};

export const createWholeDayHourArray = (getValueFn: (hourKey: string) => number | undefined): HourItem[] => {
    return [...new Array(24)].map((_, hour) => {
        const hourKey = hour.toString().padStart(2, "0");

        return {
            key: hourKey,
            value: getValueFn(hourKey) ?? 0,
        };
    });
};
