import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/api/baseQuery";

import { parseDateRangeRequestBody } from "../parseDateRangeRequestBody";
import { LocationTourRequestType, TourSessions, TourSessionsRequestType, TourSummaryResponse } from "./types";

export const tourSessionsApi = createApi({
    reducerPath: "tourSessionsApi",
    baseQuery: baseQuery,
    tagTypes: ["TourSessions", "LocationTourSessions"],
    endpoints: (builder) => ({
        requestLocationTourSessions: builder.query<TourSummaryResponse[], LocationTourRequestType>({
            query: ({ locationId, body }) => ({
                url: `/v2/global/insights/tour-sessions/${locationId}/filter`,
                method: "POST",
                body: parseDateRangeRequestBody(body),
            }),
            providesTags: ["LocationTourSessions"],
        }),
        requestTourSessions: builder.query<TourSessions[], TourSessionsRequestType>({
            query: ({ body }) => ({
                url: `/v2/global/insights/tour-sessions/filter`,
                method: "POST",
                body: parseDateRangeRequestBody(body),
            }),
            providesTags: ["TourSessions"],
        }),
    }),
});

export const { useLazyRequestLocationTourSessionsQuery, useLazyRequestTourSessionsQuery, useRequestTourSessionsQuery } = tourSessionsApi;
