import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "src/helpers/date";
import { useResizeObserver } from "src/hooks/CommonHooks";
import { ChildrenProps } from "src/models/ChildrenPropModel";
import styled from "styled-components";

import { Tag, TagProps } from "./SharedComponents/Tag";

export type Direction = "row" | "column";
export type ValueAlignment = "start" | "end";

export const TextValueStyled = styled(Text)<{ valueAlignment?: ValueAlignment }>`
    ${(props) => (props.valueAlignment ? `align-self: ${props.valueAlignment}` : "")};
`;

export const DefaultItemFieldWrapper = styled(Stack).attrs({
    direction: "column",
    pv: ["S", "XS", "XS"],
    ph: ["M", "S", "S"],
    mb: ["S", "XS", "XXS"],
})`
    border-radius: ${Spacing.XXS}px;
    background-color: ${Palette.Navy650};
`;

const Item = styled(Stack)`
    ${TextValueStyled} {
        ${(props) => props.direction === "row" && "text-align: end;"};
    }
    white-space: pre-line;
`;

const DateLine = styled.div`
    @media (max-width: ${Breakpoints.XS}) {
        display: flex;
        flex-direction: column;
    }
`;

const StyledDateRangeText = styled(Text)`
    color: ${Palette.Navy100};
    margin-right: ${Spacing.XS}px;
    text-align: end;

    @media (max-width: ${Breakpoints.XS}) {
        text-align: start;
    }
`;

const StyledDateText = styled(Text)`
    text-align: right;
    &:not(:last-child) {
        margin-right: ${Spacing.S}px;
    }
`;

export const DateRangeField: React.FC<{
    dateFrom?: Date | string;
    dateTo?: Date | string;
    direction?: Direction;
}> = ({ dateFrom, dateTo, direction = "row" }) => {
    const { t } = useTranslation();
    const dateValue = (date: Date | string) => {
        if (!date) {
            return t("common.noDataFound");
        }
        return typeof date === "string" ? date : formatDateTime(date);
    };
    return (
        <Stack>
            {direction === "row" && (
                <>
                    <StyledDateRangeText>{t("common.from")}</StyledDateRangeText>
                    <StyledDateText>{dateValue(dateFrom)}</StyledDateText>
                    <StyledDateRangeText>{t("common.to")}</StyledDateRangeText>
                    <StyledDateText>{dateValue(dateTo)}</StyledDateText>
                </>
            )}
            {direction === "column" && (
                <>
                    <DateLine>
                        <StyledDateRangeText>{t("common.from")}</StyledDateRangeText>
                        <StyledDateRangeText>{t("common.to")}</StyledDateRangeText>
                    </DateLine>
                    <DateLine>
                        <StyledDateText>{dateValue(dateFrom)}</StyledDateText>
                        <Text>{dateValue(dateTo)}</Text>
                    </DateLine>
                </>
            )}
        </Stack>
    );
};

type DefaultItemFieldProps = ChildrenProps & {
    label: string;
    tag?: TagProps;
    direction?: Direction;
    alignItems?: "baseline" | "center" | "flex-end" | "flex-start" | "stretch";
};

export const DefaultItemField = (props: DefaultItemFieldProps) => (
    <DefaultItemFieldWrapper data-testid="default-item-field">
        <DefaultItemRow {...props} />
    </DefaultItemFieldWrapper>
);

export const DefaultItemRow = ({ label, direction = "row", children, alignItems, tag }: DefaultItemFieldProps) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLDivElement>(null);
    const childrenRef = useRef<HTMLDivElement>(null);
    const [isLabelValueOverHalfWidth, setIsLabelValueOverHalfWidth] = useState(false);
    const [containerWidthObserver] = useResizeObserver(labelRef.current?.parentElement);

    useEffect(() => {
        const checkOverHalf = (el?: HTMLElement) => {
            if (!el) return false;

            const isOverHalfWidth = el.offsetWidth > (parentRef.current?.offsetWidth || 0) * 0.5;

            return isOverHalfWidth;
        };

        setIsLabelValueOverHalfWidth(checkOverHalf(labelRef.current) || checkOverHalf(childrenRef.current?.children[0] as HTMLElement));
    }, [label, containerWidthObserver]);

    return (
        <Item
            ref={parentRef}
            direction={isLabelValueOverHalfWidth ? "column" : direction}
            gap={direction === "column" ? "XS" : undefined}
            flex={1}
            alignItems={isLabelValueOverHalfWidth ? undefined : alignItems}
            justifyContent="space-between"
        >
            <Stack flex={1} direction="column" mr={["XL", "XL", "XS"]}>
                {tag?.text && <Tag text={tag.text} icon={tag.icon} />}
                <Text bold ref={labelRef} style={{ width: "fit-content" }}>
                    {label}
                </Text>
            </Stack>
            <Stack ref={childrenRef}>{children}</Stack>
        </Item>
    );
};

export const StackField = ({ children }: ChildrenProps) => <DefaultItemFieldWrapper data-testid="default-stack-field">{children}</DefaultItemFieldWrapper>;
