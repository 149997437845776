import { Spacing, Stack } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HorizontalLine } from "src/components/shared/HorizontalLine";
import { QaProps } from "src/models";

import { ValueTileStatic } from "../shared/components/ValueTileStatic";
import { SEPARATOR_COLOR } from "../Summary.constants";
import { GridLayoutStyled, WidgetStyled } from "./CalloutsPerformanceWidget.styles";

type Props = QaProps;

export const CalloutsPerformanceWidgetStatic = (props: Props) => {
    const { t } = useTranslation();
    const indicators = useMemo(
        () => [
            "insights.summary.calloutsPerformanceWidget.callouts",
            "insights.summary.calloutsPerformanceWidget.calloutsPolice",
            "insights.summary.calloutsPerformanceWidget.canceledCallouts",
        ],
        [],
    );

    return (
        <WidgetStyled
            {...props}
            hasHeaderSeparator={false}
            title={t("insights.summary.calloutsPerformanceWidget.title")}
            style={{ containerType: "inline-size" }}
        >
            <GridLayoutStyled>
                {indicators.map((titleKey, index) => (
                    <Stack key={titleKey} direction="column" alignItems="stretch">
                        {!!index && <HorizontalLine color={SEPARATOR_COLOR} marginTop={0} marginBottom={Spacing.M} />}
                        <ValueTileStatic icon="MobileGuarding" title={t(titleKey)} label={t("common.callouts", { count: 0 })} />
                    </Stack>
                ))}
            </GridLayoutStyled>
        </WidgetStyled>
    );
};
