import { Text, View } from "@react-pdf/renderer";
import { Spacing, SpacingExtendedType } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { AutoTranslationProvider } from "../../../helpers/autoTranslations";
import { AutoTranslatedContentFooterPdf } from "../../../helpers/autoTranslations/AutoTranslatedContentFooterPdf";
import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { styles } from "../pdfStyles";

type Props = ChildrenProps & {
    label: string;
    rightHeader?: JSX.Element;
    renderLineBreak?: boolean;
    marginTop?: SpacingExtendedType;
    fullyTranslated?: boolean;
    wrap?: boolean;
};

export const DynamicTemplateSectionPdf = ({
    label,
    children,
    rightHeader,
    renderLineBreak = true,
    marginTop = "L",
    fullyTranslated = false,
    wrap = true,
}: Props) => {
    const { t } = useTranslation();
    if (!children) {
        return null;
    }

    return (
        <AutoTranslationProvider id={label}>
            <View wrap={wrap}>
                <View style={{ ...styles.inlineFullWidth, marginTop: marginTop === "0" ? 0 : Spacing[marginTop] }}>
                    <Text style={{ ...styles.sectionTitle }}>{t(label)}</Text>
                    {rightHeader}
                </View>
                <View style={styles.involvementSection}>{children}</View>
                <AutoTranslatedContentFooterPdf partiallyTranslated={!fullyTranslated} />
                {renderLineBreak && <View style={styles.sectionLineBreak} />}
            </View>
        </AutoTranslationProvider>
    );
};
