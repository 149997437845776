import { RSAA } from "redux-api-middleware";

import { getOperatingSystem } from "../../helpers/osHelpers";
import { MixpanelEvent } from "../../models/tracking/mixpanelEvents";
import { IMixpanelPageViewAdditionalProperties, IMixpanelPageViewProperties, PageName } from "../../models/tracking/pageView";
import { UserType } from "../../models/tracking/tracking.model";
import { IAvailableClient } from "../clientSwitcher/types";
import { APP_TRACKING, TRACK_EVENT } from "./types";

interface ITrackingAction<T> {
    type: string;
    payload: { event: string; properties: T };
}

const trackEvent = (event: string, properties?: unknown): ITrackingAction<unknown> => ({
    type: TRACK_EVENT,
    payload: {
        event,
        properties,
    },
});

const initializeTracking = (
    selectedClient: IAvailableClient,
    userType: UserType,
): { type: string; payload: { selectedClient: IAvailableClient; userType: UserType } } => ({
    type: APP_TRACKING,
    payload: { selectedClient, userType },
});

const trackPageVisit = (pageName: PageName, additionalData?: IMixpanelPageViewAdditionalProperties): ITrackingAction<IMixpanelPageViewProperties> => {
    const properties: IMixpanelPageViewProperties = {
        Page: window.location.pathname,
        "Page Title": pageName,
        ...additionalData,
    };

    return {
        type: TRACK_EVENT,
        payload: {
            event: MixpanelEvent.PageView,
            properties,
        },
    };
};

const sendReachAndAdoption = (
    dispatch,
    userId: string,
    clientId: string,
    clientName: string,
    userType: UserType,
    sitesData: { countryCode: string; mobileService: boolean | null; siteIds: string[]; siteTags: string[] }[],
) => {
    const os = getOperatingSystem();
    sitesData.forEach((batch) => {
        const request = {
            userId,
            clientId,
            clientName,
            countryCode: batch.countryCode,
            siteIds: batch.siteIds,
            operatingSystem: os,
            userType,
            mobileService: batch.mobileService,
        };
        if (batch.siteTags != null) {
            request["siteTags"] = batch.siteTags;
        }
        dispatch({
            [RSAA]: {
                auth: true,
                endpoint: `/v1/global/tracking/reach`,
                method: "POST",
                body: request,
                types: ["REACH_AND_ADOPTION_REQUEST", "REACH_AND_ADOPTION_SUCCESS", "REACH_AND_ADOPTION_FAIL"],
            },
        });
    });
};

export default {
    initializeTracking,
    sendReachAndAdoption,
    trackEvent,
    trackPageVisit,
};
