import { IconType } from "@secuis/ccp-react-components";

export type SummaryPeriod = {
    start: Date;
    end: Date;
};

export type StatisticData = {
    id: string;
    title: string;
    label: string;
    icon?: IconType;
    value: number;
    previousValue?: number;
    onClick?: () => void;
};

export type ReportsCountData = {
    reportsCount: number;
    sitesCount: number;
};

export type ToursIndicators = {
    completedTours: number;
    uncompletedTours: number;
};

export enum DeviantDataType {
    Events = "events",
    Callouts = "callouts",
}

export enum SummaryWidgetId {
    ToursPerformanceIndicators = "performance-indicator",
    ReportsCount = "reports-count",
    SiteReports = "site-reports",
    SiteEvents = "site-events",
    EventCategories = "incident-categories",
    EventDistribution = "event-distribution",
    DeviantDay = "deviant-day",
    DeviantHour = "deviant-hour",
    CalloutsPerformanceIndicators = "callouts-performance-indicators",
    CalloutsResponse = "callouts-response",
}
