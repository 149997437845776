import { ButtonIcon, Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { TABLE_TOTAL_KEY } from "../../../../store/insights/InsightsModel";
import { getTrendArrow } from "../../../../store/insights/PatrolDeviations/PatrolDeviationHelpers";
import * as PatrolDeviationsSelectors from "../../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import { PatrolDeviationItem } from "../../../../store/insights/PatrolDeviations/PatrolDeviationsTypes";
import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";
import { CategoryCellStyled } from "./DeviationsTable.styles";
import { PatrolDeviationsChart } from "./PatrolDeviationsChart";

export const CategoryCell = ({ row, hasMobileLayout }) => {
    const { t } = useTranslation();

    if (row?.category === TABLE_TOTAL_KEY) {
        return (
            <CategoryCellStyled>
                <Text small bold color="neutral">
                    {t("insights.exceptionTrend.total_count")}
                </Text>
            </CategoryCellStyled>
        );
    }
    const category = row?.category === null ? "noCategory" : row.category;
    if (!row?.children) {
        return (
            <CategoryCellStyled pl={hasMobileLayout || row?.category === null ? "0" : "L"} gap="XXS">
                {row.category === null ? <Icon variant="NoCategory" /> : null}
                <Text truncate>{t(`incident.category.${category}`)}</Text>
            </CategoryCellStyled>
        );
    }
    return (
        <CategoryCellStyled gap="XXS">
            <Icon variant={REPORT_CATEGORY_ICON_MAP[row.category] || "NoCategory"} />
            <Text truncate>{t(`incident.category.${category}`)}</Text>
        </CategoryCellStyled>
    );
};

export const ChartCell = ({ cell }) => {
    const patrolDeviations = useSelector(PatrolDeviationsSelectors.getPatrolDeviations);
    const topAmount = patrolDeviations.reduce((acc, d) => {
        const value = Object.keys(d).reduce((a, val: string) => {
            if (val === "chartData") {
                const deviationItemsByDate = d[val] as { date: string; value: PatrolDeviationItem }[];
                if (!deviationItemsByDate?.length) {
                    return a;
                }
                return deviationItemsByDate.reduce((maxYValue, deviationItemByDate) => {
                    const yValue = deviationItemByDate?.value?.count ?? 0;
                    return yValue > maxYValue ? yValue : maxYValue;
                }, 0);
            }
            return a;
        }, 0);
        if (value > acc) {
            return value;
        }
        return acc;
    }, 0);

    return Array.isArray(cell) ? <PatrolDeviationsChart topAmount={topAmount} data={cell} /> : null;
};

export const Cell = ({ cell, row }) => {
    const { count, histCount } = cell || {};
    const isTotal = row?.category === TABLE_TOTAL_KEY;

    const trend = getTrendArrow(histCount, count);

    return (
        <Stack alignItems="center" gap="XXXS">
            <Text small={isTotal} bold={isTotal}>
                {count}
            </Text>
            {trend && <Icon size={isTotal ? "S" : "M"} variant={trend} />}
        </Stack>
    );
};

export const ExpandCell = ({ row, isExpanded }) => {
    if (row?.category === TABLE_TOTAL_KEY || row?.category === null) {
        return null;
    }

    if (row?.children) {
        return (
            <Stack justifyContent="space-between">
                <ButtonIcon mode="stateless" iconSize="M" icon={isExpanded ? "ArrowUp" : "ArrowDown"} />
            </Stack>
        );
    }
};
