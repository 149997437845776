import { ConfigFuncType, PatrolConfigFuncType } from "../../../models/DynamicTemplate";
import { ReportTemplateEnum } from "../../../models/ReportModel";
import { getActivityLogReportConfig } from "./activityLogReportConfig";
import { getAlarmResponseReportConfig } from "./alarmResponseReportConfig";
import { getAmazonGeneralReportConfig } from "./amazonGeneralReportConfig";
import { getAmazonZone6ReportConfig } from "./amazonZone6ReportConfig";
import { getCalloutReportConfig } from "./calloutReportConfig";
import { getIncidentReportConfig } from "./incidentReportConfig";
import { getMaintenanceReportConfig } from "./maintenanceReportConfig";
import { getPatrolTourReportConfig } from "./patrolTourReportConfig";
import { getActivityLogReportConfigPdf } from "./pdf/activityLogReportConfigPdf";
import { getAlarmResponseReportConfigPdf } from "./pdf/alarmResponseReportConfigPdf";
import { getAmazonGeneralReportConfigPdf } from "./pdf/amazonGeneralReportConfigPdf";
import { getAmazonZone6ReportConfigPdf } from "./pdf/amazonZone6ReportConfigPdf";
import { getCalloutReportConfigPdf } from "./pdf/calloutReportConfigPdf";
import { getIncidentReportConfigPdf } from "./pdf/incidentReportConfigPdf";
import { getMaintenanceReportConfigPdf } from "./pdf/maintenanceReportConfigPdf";
import { getPatrolTourReportConfigPdf } from "./pdf/patrolTourReportConfigPdf";
import { getSafetyInspectionReportConfigPdf } from "./pdf/safetyInspectionReportConfigPdf";
import { getSiteInspectionReportConfigPdf } from "./pdf/siteInspectionReportConfigPdf";
import { getTaskReportConfigPdf } from "./pdf/taskReportConfigPdf";
import { getTourExceptionReportConfigPdf } from "./pdf/tourExceptionReportConfigPdf";
import { getTourMultiExceptionReportConfigPdf } from "./pdf/tourMultiExceptionReportConfigPdf";
import { getVehicleInspectionReportConfigPdf } from "./pdf/vehicleInspectionReportConfigPdf";
import { getSafetyInspectionReportConfig } from "./safetyInspectionReportConfig";
import { getSiteInspectionReportConfig } from "./siteInspectionReportConfig";
import { getTaskReportConfig } from "./taskReportConfig";
import { getTourExceptionReportConfig } from "./tourExceptionReportConfig";
import { getTourMultiExceptionReportConfig } from "./tourMultiExceptionReportConfig";
import { getUnknownReportConfig } from "./unknownReportConfig";
import { getVehicleInspectionReportConfig } from "./vehicleInspectionReportConfig";

export const getConfig: ConfigFuncType = (props) => {
    switch (props.report.template) {
        case ReportTemplateEnum.incident:
            return getIncidentReportConfig(props);
        case ReportTemplateEnum.alarmResponse:
            return getAlarmResponseReportConfig(props);
        case ReportTemplateEnum.tourException:
            return getTourExceptionReportConfig(props);
        case ReportTemplateEnum.tourMultiException:
            return getTourMultiExceptionReportConfig(props);
        case ReportTemplateEnum.amazonGeneral:
            return getAmazonGeneralReportConfig(props);
        case ReportTemplateEnum.amazonZone6:
            return getAmazonZone6ReportConfig(props);
        case ReportTemplateEnum.maintenance:
            return getMaintenanceReportConfig(props);
        case ReportTemplateEnum.siteInspection:
            return getSiteInspectionReportConfig(props);
        case ReportTemplateEnum.safetyInspection:
            return getSafetyInspectionReportConfig(props);
        case ReportTemplateEnum.taskReport:
            return getTaskReportConfig(props);
        case ReportTemplateEnum.activityLog:
            return getActivityLogReportConfig(props);
        case ReportTemplateEnum.vehicleInspection:
            return getVehicleInspectionReportConfig(props);
        case ReportTemplateEnum.callout:
            return getCalloutReportConfig(props);
        default:
            return getUnknownReportConfig(props);
    }
};

export const getConfigPdf: ConfigFuncType = (props) => {
    switch (props.report.template) {
        case ReportTemplateEnum.incident:
            return getIncidentReportConfigPdf(props);
        case ReportTemplateEnum.alarmResponse:
            return getAlarmResponseReportConfigPdf(props);
        case ReportTemplateEnum.amazonGeneral:
            return getAmazonGeneralReportConfigPdf(props);
        case ReportTemplateEnum.amazonZone6:
            return getAmazonZone6ReportConfigPdf(props);
        case ReportTemplateEnum.tourException:
            return getTourExceptionReportConfigPdf(props);
        case ReportTemplateEnum.tourMultiException:
            return getTourMultiExceptionReportConfigPdf(props);
        case ReportTemplateEnum.maintenance:
            return getMaintenanceReportConfigPdf(props);
        case ReportTemplateEnum.siteInspection:
            return getSiteInspectionReportConfigPdf(props);
        case ReportTemplateEnum.safetyInspection:
            return getSafetyInspectionReportConfigPdf(props);
        case ReportTemplateEnum.taskReport:
            return getTaskReportConfigPdf(props);
        case ReportTemplateEnum.activityLog:
            return getActivityLogReportConfigPdf(props);
        case ReportTemplateEnum.vehicleInspection:
            return getVehicleInspectionReportConfigPdf(props);
        case ReportTemplateEnum.callout:
            return getCalloutReportConfigPdf(props);
        default:
            return [];
    }
};

export const getConfigPatrolTour: PatrolConfigFuncType = (props) => {
    return getPatrolTourReportConfig(props);
};

export const getConfigPatrolTourPdf: PatrolConfigFuncType = (props) => {
    return getPatrolTourReportConfigPdf(props);
};
